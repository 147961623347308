import {
  Button as MuiButton,
  Stack,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import {
  type ButtonColorOption,
  type ButtonVariantOption,
} from "@/components/Button";
import {
  createTheme,
  type SxProps,
  type Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { getHrefRel, getHrefTarget } from "@/utils/externalLink";
import React, { Fragment } from "react";
import { type SvgIconComponent } from "@mui/icons-material";

export interface MenuButtonProps {
  label: string;
  variant: ButtonVariantOption;
  color?: ButtonColorOption;
  disabled?: boolean;
  href?: string;
  description?: string;
  icon?: SvgIconComponent;
}

export const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const buttonSx: SxProps<Theme> = {
    width: "100%",
    height: "4rem",
    padding: " 0 .6em 0",
    boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
  };

  const theme = createTheme();
  theme.typography.h5 = {
    fontSize: "16px",
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={props?.description ?? ""}>
        <MuiButton
          variant={props.variant}
          color={props.color}
          disabled={props.disabled}
          href={props.href ?? "/"}
          sx={buttonSx}
          target={getHrefTarget(props.href)}
          rel={getHrefRel(props.href)}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack p={"0 .7rem 0 0"}>
              {props.icon != null
                ? React.createElement(props.icon, { fontSize: "large" })
                : null}
            </Stack>
            <Typography variant="h5">
              {replaceToNewLine(props.label)}
            </Typography>
          </Box>
        </MuiButton>
      </Tooltip>
    </ThemeProvider>
  );
};

const replaceToNewLine = (text: string): React.ReactNode => {
  const separatedText = text.split("\n");
  return (
    <>
      {separatedText.map((t, index) => {
        return (
          <Fragment key={index}>
            {t}
            <br />
          </Fragment>
        );
      })}
    </>
  );
};
