import { Navigate, Route, Routes } from "react-router-dom";
import { OwnerInfoPDFUpload } from "./OwnerInfoPDFUpload";
import { OwnerInfoList } from "@/features/ownerInfo/routes/OwnerInfoList";
import { OwnerInfoDetail } from "@/features/ownerInfo/routes/OwnerInfoDetail";
import React from "react";
import { useFeatureFlags } from "@/configs/featureFlag";

export const OwnerInfoRoutes: React.FC = () => {
  // ownerInfoListはtrue or false 機能が有効ならtrue
  const { ownerInfoList } = useFeatureFlags();

  return (
    <Routes>
      <Route path="" element={<Navigate to="list" />} />
      {ownerInfoList ? <Route path="list" element={<OwnerInfoList />} /> : null}
      <Route path="detail/:id" element={<OwnerInfoDetail />} />
      {ownerInfoList ? (
        <Route path="upload" element={<OwnerInfoPDFUpload />} />
      ) : null}
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};
