import { Box, Grid } from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption } from "@/components/Button";
import { CheckboxGroup, Checkbox } from "@/components/Checkbox";
import { Label } from "@/components/Label";
import { MultiSelect } from "@/components/Select";
import {
  RowSpacingWrapper,
  SizingWrapper,
  SizingWrapperStyle,
} from "@/components/Wrapper";
import { usePrefectureOptions } from "@/features/realEstateReceptionBookFeed/hooks/usePrefectureOptions";
import { useCityOptions } from "@/features/realEstateReceptionBookFeed/hooks/useCityOptions";
import { useCitySelect } from "@/features/realEstateReceptionBookFeed/hooks/useCitySelect";
import {
  type FilteringConditionProps,
  type FilterCondition,
} from "@/features/customerManagement/types";

export const CustomerListFilteringCondition: React.FC<
  FilteringConditionProps
> = ({ filterCondition, handleFiltering, setFilterCondition }) => {
  // 都道府県の選択肢を取得
  const { prefectureOptions } = usePrefectureOptions();
  const { cityParams } = useCityOptions();
  const { cityOptions } = useCitySelect(
    filterCondition.prefectures,
    cityParams
  );

  // 不動産種別のチェックボックスの状態を管理する関数を集約
  const handleCheckboxChange = (condition: keyof FilterCondition): void => {
    setFilterCondition((prev) => ({
      ...prev,
      [condition]: !prev[condition],
    }));
  };

  return (
    <>
      <Box sx={{ display: "grid", mx: 2, mb: 1, gap: 1 }}>
        <Label>地域</Label>
        <Grid container>
          <RowSpacingWrapper>
            <SizingWrapper>
              <MultiSelect
                label="都道府県"
                value={filterCondition.prefectures}
                onChange={(value: string[]) => {
                  setFilterCondition((prevState) => ({
                    ...prevState,
                    prefectures: value,
                  }));
                }}
                options={prefectureOptions}
              />
            </SizingWrapper>
            <SizingWrapper>
              <MultiSelect
                label="市区町村 *"
                value={filterCondition.cities}
                onChange={(value: string[]) => {
                  setFilterCondition((prevState) => ({
                    ...prevState,
                    cities: value,
                  }));
                }}
                options={cityOptions}
                maxSelect={10}
              />
            </SizingWrapper>
          </RowSpacingWrapper>
        </Grid>

        <CheckboxGroup label="不動産種別">
          <RowSpacingWrapper>
            <Checkbox
              label="土地"
              checked={filterCondition.realEstateTypeTochi}
              onChange={() => {
                handleCheckboxChange("realEstateTypeTochi");
              }}
            />
            <Checkbox
              label="区分建物"
              checked={filterCondition.realEstateTypeKutate}
              onChange={() => {
                handleCheckboxChange("realEstateTypeKutate");
              }}
            />
            <Checkbox
              label="建物"
              checked={filterCondition.realEstateTypeTatemono}
              onChange={() => {
                handleCheckboxChange("realEstateTypeTatemono");
              }}
            />
          </RowSpacingWrapper>
        </CheckboxGroup>
      </Box>

      <Box
        sx={{
          mr: 2,
          mb: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          label="絞り込み"
          onClick={handleFiltering}
          variant={ButtonVariantOption.Outlined}
          size={SizingWrapperStyle.SMALL}
        />
      </Box>
    </>
  );
};
