import { type GridSelectionModel } from "@mui/x-data-grid";
import { type AxiosInstance } from "axios";

interface IRequests {
  ids: GridSelectionModel; // モニタリング詳細テーブル（RealEstateMonitoringDetail）のIDの配列
}

/**
 * モニタリング登録を解除するAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 */
export const CancelMonitoringAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<void> => {
  // TODO: モニタリングを解除するAPIを実行する。

  console.log("モニタリング解除APIが実行されました。");
  console.log("ids =", requests.ids);
};
