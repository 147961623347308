import { leafletLayer, type Feature, type PaintRule } from "protomaps-leaflet";
import { FeatureGroup, useMap } from "react-leaflet";
import { useState, useEffect, useRef } from "react";
import { type GridLayer } from "leaflet";
import type Point from "@mapbox/point-geometry";

/*
ベクターデータの描画スタイルをカスタマイズするためのクラス
https://docs.protomaps.com/pmtiles/leaflet
*/
class CustomSymbolizer {
  attributeKey: string;
  colorMap: Record<number, string>;

  constructor(attributeKey: string, colorMap: Record<number, string>) {
    this.attributeKey = attributeKey;
    this.colorMap = colorMap;
  }

  draw(
    ctx: CanvasRenderingContext2D,
    geom: Point[][],
    z: number,
    feature: Feature
  ): void {
    const attributes = feature.props as Record<string, any>;
    ctx.globalAlpha = 0.7;
    const fillColor =
      this.colorMap[attributes[this.attributeKey] as number] || "rgba(0,0,0,0)";
    ctx.fillStyle = fillColor;

    ctx.beginPath();
    for (const poly of geom) {
      for (let p = 0; p < poly.length - 1; p++) {
        const pt = poly[p];
        if (p === 0) ctx.moveTo(pt.x, pt.y);
        else ctx.lineTo(pt.x, pt.y);
      }
    }
    ctx.fill();
  }
}

interface Props {
  defaultVisible: boolean;
  layerName: string;
  url: string;
  attributeKey: string;
  colorMap: Record<number, string>;
  dataLayer?: string;
  minzoom?: number;
  maxzoom?: number;
}

/*
pmtilesを利用したハザードマップのベースレイヤー

*/
export const HazardBaseLayer: React.FC<Props> = ({
  defaultVisible,
  layerName,
  url,
  attributeKey,
  colorMap,
  dataLayer = "4326_merged",
  minzoom = 14,
  maxzoom = 19,
}) => {
  const map = useMap();
  const [visible, setVisible] = useState(defaultVisible);
  const layerRef = useRef<GridLayer | null>(null);

  // レイヤーコントロールのイベントで表示・非表示を切り替える
  useEffect(() => {
    if (!map) return;

    map.on("overlayadd", (e) => {
      if (e.name === layerName) {
        setVisible(true);
      }
    });

    map.on("overlayremove", (e) => {
      if (e.name === layerName) {
        setVisible(false);
      }
    });
  }, [map, layerName]);

  useEffect(() => {
    if (layerRef.current) {
      if (visible) {
        layerRef.current.addTo(map);
      } else {
        map.removeLayer(layerRef.current);
      }
    }
  }, [visible, map]);

  useEffect(() => {
    if (!map) return;

    const layer = leafletLayer({
      url,
      paintRules: [
        {
          dataLayer,
          // @ts-expect-error: 型エラー通りに修正しても解消されないため無視する
          symbolizer: new CustomSymbolizer(attributeKey, colorMap),
          maxzoom,
          minzoom,
        },
      ],
      attribution:
        '<a href="https://nlftp.mlit.go.jp/ksj/other/agreement.html">国土数値情報</a>',
    }) as GridLayer;

    layerRef.current = layer;
    if (visible) {
      layer.addTo(map);
    }

    return () => {
      if (map && layerRef.current) {
        map.removeLayer(layerRef.current);
      }
    };
  }, [map, url, dataLayer, attributeKey, colorMap, minzoom, maxzoom]);

  return <FeatureGroup />;
};
