import { IconButton, Link } from "@mui/material";
import information from "../../../assets/information.svg";

export const ManualInfoButton: React.FC = () => {
  return (
    <IconButton
      id="basic-button"
      LinkComponent={Link}
      href={
        "https://trustart.notion.site/a11d52b037224591a30999b2a672e14b?pvs=4"
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={information} alt="logoinformation" height="24px" />
    </IconButton>
  );
};
