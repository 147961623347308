import { toast } from "react-toastify";
import { useApiClient } from "@/hooks/useApiClient";
import { type MailFormSchema } from "../routes/MailScheduleCreator";

type CreateMailRequest = MailFormSchema;

interface CreateMailResponse {
  id: number;
}

export const useCreateMailRequest = (): ((
  params: CreateMailRequest,
  onSuccess?: (id: number) => void
) => void) => {
  const { apiClient } = useApiClient();
  const data = (
    params: MailFormSchema,
    onSuccess?: (id: number) => void
  ): void => {
    apiClient
      .post<CreateMailResponse>("/mail/detail", params)
      .then((_res) => {
        toast.success("メールの作成に成功しました。");
        if (onSuccess) {
          onSuccess(_res.data.id);
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("メールの作成に失敗しました。");
      });
  };
  return data;
};
