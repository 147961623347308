import { type AxiosInstance } from "axios";
import { type MonitoringDataGridRow } from "../types";

interface IRequests {
  rows: MonitoringDataGridRow[]; // 選択されたデータ
}

/**
 * 一括で謄本取得するAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 */
export const AcquireBooksAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<void> => {
  // TODO: 謄本取得APIを実行する。

  console.log("一括謄本取得APIが実行されました。");
  console.log("rows =", requests.rows);
};
