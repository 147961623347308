import { type Control, Controller, type FieldValues } from "react-hook-form";
import { Checkbox, type CheckboxIProps } from "@/components/Checkbox/Checkbox";
import type { FieldPath } from "react-hook-form/dist/types";
import type { ReactElement } from "react";

interface IProps<T extends FieldValues>
  extends Omit<CheckboxIProps, "checked" | "onChange"> {
  control: Control<T> | undefined;
  name: FieldPath<T>;
}

/**
 * Checkboxコンポーネントのコントローラー対応版
 * コントローラーの詳細はReact-Hook-Formのドキュメントを参照
 * https://www.react-hook-form.com/api/usecontroller/controller/
 * @param props
 */
const CCheckbox = <T extends FieldValues>(props: IProps<T>): ReactElement => {
  const { control, name, label, disabled } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <Checkbox
            label={label}
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export { CCheckbox };
