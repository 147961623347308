import { type CustomerStatusDataGridRow } from "../types";
import { type AxiosInstance, HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

export const GetCustomerListAPI = async (
  apiClient: AxiosInstance,
  url: string,
  cities: string[],
  prefectures: string[],
  realEstateTypes: string[]
): Promise<CustomerStatusDataGridRow[]> => {
  const response = await apiClient
    .post<CustomerStatusDataGridRow[]>(url, {
      body: {
        cities,
        prefectures,
        realEstateTypes,
        isEmailFilter: false,
      },
    })
    .catch((error): void => {
      if (
        isAxiosError(error) &&
        error.response?.status !== HttpStatusCode.Unauthorized
      ) {
        Sentry.captureException(error);
      }
      return undefined;
    });

  if (response) {
    return response.status === HttpStatusCode.Ok ? response.data : [];
  }
  return [];
};
