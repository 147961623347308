import { ENVIRONMENTS, getEnv } from "@/types/env";
import { useFlags, type LDFlagSet } from "launchdarkly-react-client-sdk";

const env = getEnv(process.env.VITE_ENV);

const getLaunchDarklyClientId = (): string => {
  switch (env) {
    case ENVIRONMENTS.LOCAL:
      return "660e7b6035d04c0ff3ebec6b";
    case ENVIRONMENTS.DEV:
      return "660e7b6035d04c0ff3ebec6b";
    case ENVIRONMENTS.STG:
      return "66042949da208210134ba998";
    case ENVIRONMENTS.PROD:
      return "6604289eda208210134ba935";
    default:
      return "";
  }
};

const launchDarklyClientId = getLaunchDarklyClientId();

const useFeatureFlags = (): LDFlagSet => useFlags();

export { launchDarklyClientId, useFeatureFlags };
