import React from "react";
import { Route, Routes } from "react-router-dom";
import { SavedEmailList } from "./SavedEmailList";
import { ScheduledEmailList } from "./ScheduledEmailList";
import { SentEmailList } from "./SentEmailList";
import { MailScheduleCreator } from "./MailScheduleCreator";
import { MailEdit } from "./MailEdit";
import { MailDetail } from "./MailDetail";

export const Mail: React.FC = () => {
  return (
    <Routes>
      {/* メール作成画面 */}
      <Route path="createMail" element={<MailScheduleCreator />} />
      {/* 送信済みメール一覧画面 */}
      <Route path="sentEmailList" element={<SentEmailList />} />
      {/* 予約メール一覧画面 */}
      <Route path="scheduledEmailList" element={<ScheduledEmailList />} />
      {/* 保存済みメール一覧画面 */}
      <Route path="savedEmailList" element={<SavedEmailList />} />
      <Route path="edit/:id" element={<MailEdit />} />
      <Route path="detail/:id" element={<MailDetail />} />
      <Route path="*" element={<SentEmailList />} />
    </Routes>
  );
};
