import { floor } from "mathjs";
import { type AxiosResponse } from "axios";
import proj4 from "proj4";
import { LatLng } from "leaflet";

// 丸文字を定義。
// 丸文字の参考URL: https://ja.wikipedia.org/wiki/%E4%B8%B8%E5%8D%B0#%E7%AC%A6%E5%8F%B7%E4%BD%8D%E7%BD%AE
const REGEX_CIRCLES = /[⃘⃝○◌◍●◙◯⚪⚫⚬❍⬤⭘￮OＯ]/gu;

const stringBooleanToBoolean = (args: "true" | "false"): boolean => {
  return JSON.parse(args.toLowerCase()) as boolean;
};

const camelToSnake = (str: string): string => {
  return str.replace(/[A-Z]/g, (s) => "_" + s.charAt(0).toLowerCase());
};

const numberFormat = (
  num: number,
  options?: Intl.NumberFormatOptions,
  roundingMode?: "floor"
): string => {
  let value = num;
  if (options?.maximumFractionDigits != null && roundingMode === "floor") {
    value = floor(num, options.maximumFractionDigits);
  }
  return new Intl.NumberFormat("ja-JP", options).format(value);
};

// 2つのDateオブジェクトが同一日か判定する
const isSameDate = (a: Date, b: Date): boolean => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

/**
 * 半角数字とハイフンを全角文字に変換する。それ以外の文字はそのまま
 * @param value 変換したい文字列
 * @return 変換後の文字列
 */
const NYHalfWidthNumberToFillWidthNumber = (value: string): string => {
  const regexNumber = /[0-9]/g;
  const regexHyphen =
    /[-\uFF0D\uFE63\u2212\u2010\u2043\u2011\u2012\u2013\u2014\ufe58\u2015\u23AF\u23E4\u02D7\u2796\u208B\u30FC\uFF70]/g;

  // 一文字ごとに分割して配列に変換
  const stringArray = [...value];

  // 一文字ずつ実行
  const result = stringArray.map((c) => {
    // 数字であれば全角に変換
    c = c.replace(regexNumber, (s) => {
      return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
    });

    // ハイフンであれば「KATAKANA-HIRAGANA PROLONGED SOUND MARK」(utf8: e3 83 bc)に置き換える
    c = c.replace(regexHyphen, "ー");

    return c;
  });

  // 文字列に戻す
  return result.join("");
};

// 日本の祝日CSVデータ。内閣府WebサイトからCSVを取得し、フォーマット等変換せずコピペしています。
// スクリプトでCSVを分解し必要なデータを取り出しているため、そのままコピペで問題ありません。
// 2023年以前は不要なので削除しています。
// 2025年分は2024年2月に掲載されますので、2025年になる前に反映してください。
// https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html
const nationalHolidayCsv = `
2024/1/1,元日
2024/1/8,成人の日
2024/2/11,建国記念の日
2024/2/12,休日
2024/2/23,天皇誕生日
2024/3/20,春分の日
2024/4/29,昭和の日
2024/5/3,憲法記念日
2024/5/4,みどりの日
2024/5/5,こどもの日
2024/5/6,休日
2024/7/15,海の日
2024/8/11,山の日
2024/8/12,休日
2024/9/16,敬老の日
2024/9/22,秋分の日
2024/9/23,休日
2024/10/14,スポーツの日
2024/11/3,文化の日
2024/11/4,休日
2024/11/23,勤労感謝の日
`;

// テキストボックスにおいて、数値以外の文字を含む値のペーストを抑制する
// 特に-+eEの入力を防止したい
const preventNonNumericValueWhenPaste = (
  event: React.ClipboardEvent<HTMLDivElement> | undefined
): void => {
  const pasted = event?.clipboardData?.getData("text") ?? "";
  const re = /\D/; // 半角数値以外の全ての文字
  if (re.test(pasted)) {
    event?.preventDefault();
  }
};

// テキストボックスにおいてeE-+のキー入力を抑止する
const preventNonNumericValueWhenKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  if (
    event.key === "e" ||
    event.key === "E" ||
    event.key === "-" ||
    event.key === "+"
  ) {
    event.preventDefault();
  }
};

// テキストボックスにおいて数値以外のキー入力を抑止する。バックスペースを除く
const preventNonNumericValueWithoutBackspaceWhenKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  // バックスペースはOK
  if (event.key === "Backspace") {
    return;
  }
  const re = /\D/; // 半角数値以外の全ての文字
  if (re.test(event.key)) {
    event?.preventDefault();
  }
};

const getAttachedFileNameFromHeader = (
  res: AxiosResponse,
  fallBackName: string
): string => {
  let filename = fallBackName; // ヘッダーから取得できない場合のデフォルト名
  const dispositionRaw = res.headers.contentDisposition as unknown;
  if (dispositionRaw && (dispositionRaw as string).includes("attachment")) {
    const disposition = dispositionRaw as string;
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches?.[1]) {
      filename = matches[1].replace(/['"]/g, "").split("utf-8")[1];
      filename = decodeURIComponent(filename);
    }
  }
  return filename;
};

/**
 * wgs2jgs(世界測地系から日本測地系に変換)
 * 参照: https://qiita.com/takahi/items/85732f577820d8f76b3e
 * @param coordinates 世界測地系の座標
 * @return 日本測地系の座標、leafletのLatLng型
 **/
const wgs2jgs = (coordinates: LatLng): LatLng => {
  proj4.defs([
    [
      "EPSG:4301", // 東京測地系/日本測地系 SRID=4301
      "+proj=longlat +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +no_defs",
    ],
  ]);

  const result = proj4("EPSG:4326", "EPSG:4301", [
    coordinates.lng,
    coordinates.lat,
  ]);
  return new LatLng(result[1], result[0]);
};

/**
 * REGEX_CIRCLESを半角数字の0に変換する。それ以外の文字はそのまま。
 * @param {string} value 変換したい文字列
 * @returns {string} 変換後の文字列
 */
const convertCirclesToZero = (value: string): string => {
  // 一文字ごとに分割して配列に変換。
  const stringArray = [...value];

  // 一文字ずつ実行。特定の丸の文字があれば半角数字の0に変換。
  const result = stringArray.map((c) => c.replace(REGEX_CIRCLES, String(0)));

  // 文字列に戻す。
  return result.join("");
};

export {
  stringBooleanToBoolean,
  camelToSnake,
  numberFormat,
  isSameDate,
  NYHalfWidthNumberToFillWidthNumber,
  nationalHolidayCsv,
  preventNonNumericValueWhenPaste,
  preventNonNumericValueWhenKeyDown,
  preventNonNumericValueWithoutBackspaceWhenKeyDown,
  getAttachedFileNameFromHeader,
  wgs2jgs,
  convertCirclesToZero,
};
