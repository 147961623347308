import React from "react";
import { Box, Divider } from "@mui/material";
import { SideNav } from "../components/SideNav";
import { PagePaper } from "@/components/Paper";

interface MailPageTemplateProps {
  children: React.ReactNode;
}

const MailPageTemplate: React.FC<MailPageTemplateProps> = ({ children }) => {
  return (
    <PagePaper>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: 1 / 9 }}>
          <SideNav />
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Box sx={{ width: 8 / 9 }}>{children}</Box>
      </Box>
    </PagePaper>
  );
};

export default MailPageTemplate;
