import { format, parse } from "date-fns";
import { ja } from "date-fns/locale";
import { type ISelectObject } from "@/types/select";
import { camelToSnake } from "@/utils/utils";
import {
  type IFetchParams,
  type IRealEstateReceptionBookResponse,
  type IRealEstateReceptionBookDataGridRow,
  type ISortCondition,
  type IUseFeedParams,
} from "../types";
import { dateToString, dateToYearEnd } from "../utils/dateTime";

/**
 * スネークケースに変換する。
 * @param {string | undefined} value
 * @returns {string | undefined}
 */
export const convertToSnakeCase = (
  value: string | undefined
): string | undefined => {
  if (value === undefined) return undefined;
  return camelToSnake(value);
};

/**
 * selectedValuesの.valueの値を抜き出してstring配列にする
 * .additional_valuesの中に要素が1以上あるならそれらの要素も配列に追加して返す
 * @param {ISelectObject[]} selectedValues
 * @return {string[]} 駅コードが入ったstring[]
 */
export const provideStationCodes = (
  selectedValues: ISelectObject[]
): string[] => {
  const result: string[] = [];
  selectedValues.forEach((value) => {
    result.push(value.value);
    // additional_valuesがあるなら追加する
    if (value.additional_values && value.additional_values.length > 0) {
      result.push(...value.additional_values);
    }
  });
  return result;
};

/**
 *custom hookの引数の型をfetcherの引数の型に変換する関数
 * @param {IUseFeedParams} params
 * @param {number} from
 * @param {ISortCondition | undefined} sortCondition
 * @returns {IFetchParams}
 */
export const toFetchParams = (
  params: IUseFeedParams,
  from: number,
  sortCondition: ISortCondition | undefined
): IFetchParams => {
  const {
    legalAffairsBureauRequestDateStart,
    legalAffairsBureauRequestDateEnd,
    cities,
    prefectures,
    receptionReasons,
    size,
    buildDateStart,
    buildDateEnd,
    stationSelectedValues,
    realEstateReceptionBookIds,
  } = params;

  return {
    ...params,
    sortBy: convertToSnakeCase(sortCondition?.sortBy),
    order: sortCondition?.order,
    legalAffairsBureauRequestDateStart: dateToString(
      legalAffairsBureauRequestDateStart
    ),
    legalAffairsBureauRequestDateEnd: dateToString(
      legalAffairsBureauRequestDateEnd
    ),
    cities,
    prefectures,
    receptionReasons,
    buildDateStart: dateToString(buildDateStart),
    buildDateEnd: dateToString(dateToYearEnd(buildDateEnd)),
    stations: provideStationCodes(stationSelectedValues),
    // fromが大きく動かない前提のため、一度に取得する件数のみを増減させる
    // ex.) 5001件以降取得する場合は0〜10000件の取得となるが、そもそも5001件以降開くことをあまり想定しない
    size: from + size,
    fromCount: 0,
    realEstateReceptionBookIds,
  };
};

type Dictionary = Record<string, string>;
const AreaUsePurposeDictionary: Dictionary = {
  第一種低層住居専用地域: "一低",
  第二種低層住居専用地域: "二低",
  田園住居地域: "田住",
  第一種中高層住居専用地域: "一中高",
  第二種中高層住居専用地域: "二中高",
  第一種住居地域: "一住",
  第二種住居地域: "二住",
  準住居地域: "準住",
  近隣商業地域: "近商",
  商業地域: "商業",
  準工業地域: "準工",
  工業地域: "工業",
  工業専用地域: "工専",
};

/**
 * Gridに表示するデータの型に変換する関数
 * @param {IRealEstateReceptionBookResponse | undefined} data
 * @returns {IRealEstateReceptionBookDataGridRow[]}
 */
export const toGridRows = (
  data: IRealEstateReceptionBookResponse | undefined
): IRealEstateReceptionBookDataGridRow[] => {
  if (data === undefined) return [];
  return data.list.map((item) => {
    const receptionKindLabel = item.isNew ? "新" : "既";
    let areaUsePurpose = item.areaUsePurpose;
    if (areaUsePurpose) {
      const areaUsePurposeFull = areaUsePurpose;
      areaUsePurpose =
        AreaUsePurposeDictionary[areaUsePurposeFull] || areaUsePurposeFull;
    }
    const hasTrade: string = item.hasTrade ? "有" : "";

    let isTowerApartment: string | null = null;
    let isOneRoom: string | null = null;
    let isPlant: string | null = null;
    isPlant = item.plantId ? "○" : "";
    let isRenewableEnergy: string | null = null;
    isRenewableEnergy = item.equipmentId ? "○" : "";
    if (item.realEstateTypeName === "区分建物") {
      isTowerApartment = item.isTowerApartment ? "○" : "×";
      isOneRoom = item.isOneRoom ? "○" : "×";
    }

    return {
      id: item.id,
      hasTrade,
      chiban: item.chiban,
      kaokuNumber: item.kaokuNumber,
      realEstateBookType: item.realEstateBookTypeName,
      receptionReason: item.receptionReason,
      receptionReasonType: item.receptionReasonTypeName,
      realEstateType: item.realEstateTypeName,
      receptionKind: receptionKindLabel,
      prefectures: item.prefecturesName,
      prefecturesId: item.prefecturesId,
      city: item.cityName,
      address: item.address,
      outside: item.outside,
      legalAffairsBureauRequestDate: format(
        parse(item.legalAffairsBureauRequestDate, "yyyy-MM-dd", new Date()),
        "yyyy年MM月dd日",
        {
          locale: ja,
        }
      ),
      legalAffairsBureauReceptionNumber: item.legalAffairsBureauReceptionNumber,
      estimatedChiseki: item.estimatedChiseki,
      publishedPrice: item.publishedPrice,
      estimatedPrice: item.estimatedPrice,
      areaUsePurpose,
      buildingRate: item.buildingRate,
      volumeRate: item.volumeRate,
      isTowerApartment,
      isOneRoom,
      isPlant,
      isRenewableEnergy,
      propertyName: item.propertyName,
      buildDate: item.buildDate,
      station: item.station,
      walk: item.walk,
      farmlandArea: item.farmlandArea,
      farmlandCategory: item.farmlandCategory,
    };
  });
};
