import React, { useContext } from "react";
import { Label } from "@/components/Label";
import { useHeaderMenu } from "@/features/header/hooks/useHeaderMenu";
import { userDataContext } from "@/hooks/useUserData";

export const LoginUserIdLabel: React.FC = () => {
  const userData = useContext(userDataContext);
  const id = userData.data?.email;

  const { isVisible } = useHeaderMenu();

  if (!isVisible) return null;

  return (
    <Label
      sx={{
        marginBottom: 0,
        alignContent: "center",
        fontWeight: "500",
        display: { xs: "none", sm: "block" },
      }}
    >
      {id || ""}
    </Label>
  );
};
