import { type AxiosInstance, HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { type KaokuNumberSearchChiban } from "../types";

interface createKaokuNumberSearchRequestAPIResponse {
  id?: number;
  status: number;
  error?: string;
}

export const createKaokuNumberSearchRequestAPI = async (
  apiClient: AxiosInstance,
  requests: KaokuNumberSearchChiban[]
): Promise<createKaokuNumberSearchRequestAPIResponse | undefined> => {
  const url = "/kaoku-number/";

  try {
    const response =
      await apiClient.post<createKaokuNumberSearchRequestAPIResponse>(url, {
        requests,
      });

    return {
      ...response.data,
      status: response.status,
    };
  } catch (error) {
    if (isAxiosError<createKaokuNumberSearchRequestAPIResponse>(error)) {
      // Unauthorized以外のエラーが発生した場合、ステータスコードを返す
      if (error.response?.status !== HttpStatusCode.Unauthorized) {
        Sentry.captureException(error);

        return {
          status: error.response?.status ?? 500, // ステータスコードが取得できない場合は500を返す
          error: error.response?.data?.error,
        };
      }
    }

    // その他のエラーの場合はundefinedを返す
    return undefined;
  }
};
