const statusToLabel = (value: string | undefined): string => {
  switch (value) {
    case "requesting":
      return "依頼中";
    case "succeeded":
      return "成功";
    case "some_failed":
      return "失敗あり";
    case "failed":
      return "失敗";
    default:
      return "";
  }
};

const isActionEnabled = (value: string): boolean => {
  return ["succeeded", "some_failed"].includes(value);
};

export { statusToLabel, isActionEnabled };
