import { HazardBaseLayer } from "./HazardBaseLayer";

interface Props {
  defaultVisible: boolean;
}

const host = process.env.VITE_HAZARD_TILE_HOST ?? "";
const url = `${host}/20/flood_20.pmtiles`;

export const FLOOD_20_LAYER_NAME = "洪水浸水想定区域（想定最大規模）";
const colorMap: Record<number, string> = {
  1: "rgb(246,246,172)",
  2: "rgb(254,218,194)",
  3: "rgb(255,187,186)",
  4: "rgb(255,152,151)",
  5: "rgb(244,139,205)",
  6: "rgb(222,128,222)",
};
const attributeKey = "A31b_201";

/*
洪水浸水想定区域（想定最大規模）レイヤー
タイル元データ: 洪水浸水想定区域データ（1次メッシュ単位）2023年度
https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A31b-2023.html
*/
export const HazardFlood20Layer: React.FC<Props> = ({ defaultVisible }) => {
  return (
    <HazardBaseLayer
      defaultVisible={defaultVisible}
      layerName={FLOOD_20_LAYER_NAME}
      url={url}
      attributeKey={attributeKey}
      colorMap={colorMap}
    />
  );
};
