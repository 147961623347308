import { Navigate, Route, Routes } from "react-router-dom";
import { CommercialBookList } from "@/features/commercialBook/routes/CommercialBookList";
import { CompanyList } from "@/features/commercialBook/routes/CompanyList";

import React from "react";
import { CommercialBook } from "./CommercialBook";

export const CommercialBookRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<CommercialBook />} />
      <Route path="list" element={<CommercialBookList />} />
      <Route path="companylist" element={<CompanyList />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};
