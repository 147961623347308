import { getEnv } from "@/types/env";
import * as Sentry from "@sentry/react";
import type { BrowserOptions } from "@sentry/browser";
import { baseURL } from ".";

const env = getEnv(process.env.VITE_ENV);

const sentryOptions: BrowserOptions = {
  enabled: ["production", "staging", "development"].includes(env.toString()),
  dsn: "https://1df8a8951a125c09ffb21232c723e220@o4507014085476352.ingest.us.sentry.io/4507014090653696",
  environment: env.toString(),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.1,
  profilesSampleRate: 0.1,
  tracePropagationTargets: [baseURL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

function initSentry(): void {
  Sentry.init(sentryOptions);
}

export { initSentry };
