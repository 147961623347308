import { useApiClient } from "../../../hooks/useApiClient";

interface UseAddCustomer {
  handleAddCustomer: () => Promise<void>;
  handleAddOutOfTargetCustomer: () => Promise<void>;
}

export const useCustomerAdd = (selectedRowIds: string[]): UseAddCustomer => {
  const { apiClient } = useApiClient();

  const handleAddCustomer = async (): Promise<void> => {
    const params = {
      saveData: selectedRowIds,
      isOutOfTarget: false,
    };
    await apiClient.post(`/real-estate-owner-customer-management/`, params);
  };

  const handleAddOutOfTargetCustomer = async (): Promise<void> => {
    const params = {
      saveData: selectedRowIds,
      isOutOfTarget: true,
    };
    await apiClient.post(`/real-estate-owner-customer-management/`, params);
  };

  return { handleAddCustomer, handleAddOutOfTargetCustomer };
};
