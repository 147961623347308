import { z } from "zod";

export const UserConfigFormDataSchema = z.object({
  pictureRequestPastDays: z
    .number({ invalid_type_error: "経過日数を0日以上で入力してください" })
    .min(0, "経過日数は0日以上で入力してください")
    .max(1000, "経過日数は1000日以内で入力してください"),
});

// 定義したZodのスキーマをTypescriptの型に変換
export type UserConfigFormData = z.infer<typeof UserConfigFormDataSchema>;
