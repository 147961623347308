import { Alert } from "@mui/material";
import React from "react";
import { type AlertColor } from "@mui/material/Alert/Alert";

interface Props {
  severity?: AlertColor;
  message: string;
  onClose?: (event: React.SyntheticEvent) => void;
}
export const AlertBar: React.FC<Props> = ({ severity, message, onClose }) => {
  return (
    <Alert
      severity={severity ?? "warning"}
      onClose={onClose}
      sx={{ mb: "16px" }}
    >
      {message}
    </Alert>
  );
};
