import { toast } from "react-toastify";
import { useApiClient } from "@/hooks/useApiClient";
import { type UpdateMailFormSchema } from "../routes/MailEdit";

interface UpdateMailResponse {
  updated_count: number;
}

export const useUpdateMailAPI = (
  id: number
): {
  updateMail: (
    params: UpdateMailFormSchema,
    onSuccess?: (id: number) => void
  ) => void;
} => {
  const { apiClient } = useApiClient();
  const updateMail = (
    params: UpdateMailFormSchema,
    onSuccess?: (id: number) => void
  ): void => {
    apiClient
      .put<UpdateMailResponse>(`/mail/detail/${id}`, params)
      .then((_res) => {
        toast.success("メールの更新に成功しました。");
        if (onSuccess) {
          onSuccess(id);
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("メールの更新に失敗しました。");
      });
  };
  return { updateMail };
};
