import useSWR from "swr";
import { type CityListResponse } from "../types/city";
import { useCallback, useEffect, useState } from "react";
import { useApiClient } from "./useApiClient";

interface CityResponse {
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  cityList: CityListResponse;
}

// 指定したprefCodeから市区町村の一覧を取得するcustom hook
export const useCity = (
  prefCode: string,
  initialValue: string
): CityResponse => {
  const { apiClient } = useApiClient();

  // 市区町村セレクトボックス
  const [city, setCity] = useState<string>("");

  // swrに引き渡すfetcher
  const fetcher = async (url: string): Promise<CityListResponse> => {
    const { data } = await apiClient.get<CityListResponse>(url);
    return data;
  };

  const { data: cityList } = useSWR<CityListResponse>(
    prefCode === "" ? null : `/general/city?pref_code=${prefCode}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  const getCityCodeByValue = useCallback(
    (cityName: string): string => {
      if (cityList)
        return (
          cityList.list.find((city) => city.name === cityName)?.cityCode ?? ""
        );
      return "";
    },
    [cityList]
  );

  useEffect(() => {
    setCity(getCityCodeByValue(initialValue));
  }, [cityList]);

  return {
    city,
    setCity,
    cityList: cityList ?? { list: [] },
  };
};
