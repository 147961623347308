import { type DestinationTypeValues } from "../types/enums";

export interface MailDetail {
  status: MailStatus;
  title: string;
  body: string;
  destinationType: MailDestinationType;
  destinationConditions: MailDestinationCondition | null;
  clients: MailClient[] | null;
  scheduledSendAt: string | null;
}

export interface MailClient {
  customerManagementId: number;
  email: string | null;
  tel: string | null;
  legalAffairsBureauRequestDate: string | null;
  mochibunStr: string | null;
  originalShozai: string | null;
  ownerAddress: string | null;
  ownerName: string | null;
  postcode: string | null;
  realEstateType: string | null;
  receptionReason: string | null;
  responsiblePersonName: string | null;
  status: string | null;
  isOutOfTarget: boolean;
}

export interface MailDestinationCondition {
  // 登記原因
  receptionReason: string[];
  // 持分
  mochibun: string;
  // 登記申請年月
  legalAffairsBureauRequestDate: Date | null;
  // 郵便番号
  postcode: string;
  // メールアドレス
  email: string;
  // 物件情報
  originalShozai: string;
  // 不動産種別
  realEstateTypeTochi: boolean;
  realEstateTypeKutate: boolean;
  realEstateTypeTatemono: boolean;
  // ステータス
  statusNoApproach: boolean;
  statusApproach: boolean;
  statusRejected: boolean;
  statusNoResponse: boolean;
  statusComplaint: boolean;
  statusSent: boolean;
  statusReaction: boolean;
  // 含むor含まない
  includeReceptionReason: boolean;
  includeMochibun: boolean;
  includeLegalAffairsBureauRequestDate: boolean;
  includePostcode: boolean;
  includeEmail: boolean;
  includeOriginalShozai: boolean;
  includeRealEstateType: boolean;
  includeStatus: boolean;
}

type MailStatus =
  | "saved"
  | "scheduled"
  | "schedule_cancelled"
  | "sending"
  | "sent"
  | "failed";

export type MailStatusLabel =
  | "保存済み"
  | "送信取消済"
  | "送信待ち"
  | "送信中"
  | "送信済"
  | "送信失敗";

export const MailStatusMapping: Record<MailStatus, MailStatusLabel> = {
  saved: "保存済み",
  scheduled: "送信待ち",
  schedule_cancelled: "送信取消済",
  sending: "送信中",
  sent: "送信済",
  failed: "送信失敗",
};

// 逆引きマッピングの作成
export const ReverseMailStatusMapping: Record<MailStatusLabel, MailStatus> =
  Object.entries(MailStatusMapping).reduce<Record<string, MailStatus>>(
    (acc, [key, value]) => {
      acc[value] = key as MailStatus;
      return acc;
    },
    {}
  );

export type MailDestinationType =
  | DestinationTypeValues.ALL
  | DestinationTypeValues.CONDITION
  | DestinationTypeValues.CUSTOMER_SELECT;

export type MailDestinationTypeLabel =
  | "全ての顧客"
  | "条件の絞り込み"
  | "顧客リストから選択";

const MailDestinationTypeMapping: Record<
  MailDestinationType,
  MailDestinationTypeLabel
> = {
  ALL: "全ての顧客",
  CONDITION: "条件の絞り込み",
  CUSTOMER_SELECT: "顧客リストから選択",
};

// 逆引きマッピングの作成
export const ReverseMailDestinationTypeMapping: Record<
  MailDestinationTypeLabel,
  MailDestinationType
> = Object.entries(MailDestinationTypeMapping).reduce<
  Record<string, MailDestinationType>
>((acc, [key, value]) => {
  acc[value] = key as MailDestinationType;
  return acc;
}, {});

export interface DestinationConditionsResponseError {
  email?: string[];
  includeEmail?: string[];
  includeLegalAffairsBureauRequestDate?: string[];
  includeMochibun?: string[];
  includeOriginalShozai?: string[];
  includePostcode?: string[];
  includeRealEstateType?: string[];
  includeReceptionReason?: string[];
  includeStatus?: string[];
  legalAffairsBureauRequestDate?: string[];
  mochibun?: string[];
  originalShozai?: string[];
  postcode?: string[];
  realEstateTypeKutate?: string[];
  realEstateTypeTatemono?: string[];
  realEstateTypeTochi?: string[];
  receptionReason?: string[];
  statusApproach?: string[];
  statusComplaint?: string[];
  statusNoApproach?: string[];
  statusNoResponse?: string[];
  statusReaction?: string[];
  statusRejected?: string[];
  statusSent?: string[];
}
