import { useApiClient } from "@/hooks/useApiClient";
import { type ReceptionBook } from "../types";

interface ReceptionBookApi {
  getReceptionBook: (id: number) => Promise<ReceptionBook | undefined>;
}

export const useReceptionBookApi = (): ReceptionBookApi => {
  const { apiClient } = useApiClient();

  /**
   * idで指定された受付帳を1件取得する
   * @param id
   */
  const getReceptionBook = async (
    id: number
  ): Promise<ReceptionBook | undefined> => {
    try {
      const r = await apiClient.get<ReceptionBook>(`/real-estate-book/${id}/`);
      if (r.status === 200) {
        return r.data;
      } else {
        return undefined;
      }
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  return {
    getReceptionBook,
  };
};
