import useSWR from "swr";
import { useApiClient } from "../../../hooks/useApiClient";

export interface CustomerDetailResponse {
  realEstateOwnerId: number;
  realEstateOwnerCustomerManagementId: number | null;
  status: string | null;
  realEstateType: string | null;
  originalShozai: string | null;
  postcode: string | null;
  ownerAddress: string;
  ownerName: string;
  legalAffairsBureauRequestDate: string | null;
  mochibunStr: string;
  receptionReason: string | null;
  email: string | null;
  tel: string | null;
  memo: string | null;
  responsiblePersonName: string;
  isOutOfTarget: boolean;
  emailHistories: EmailHistories[];
}

interface UseCustomerDetail {
  data: CustomerDetailResponse | undefined;
  handleSubmit: (
    id: number,
    updatedData: CustomerDetailResponse
  ) => Promise<void>;
  handleDelete: (id: number, managementId: number) => Promise<void>;
}

export const useCustomerDetail = (id: number): UseCustomerDetail => {
  const { apiClient } = useApiClient();

  const fetcher = async (url: string): Promise<CustomerDetailResponse> => {
    const { data } = await apiClient.get<CustomerDetailResponse>(url);
    return data;
  };
  const { data: customerData } = useSWR<CustomerDetailResponse>(
    id ? `/real-estate-owner-customer-management/customer/${id}` : null,
    fetcher,
    {
      revalidateOnFocus: true,
      refreshInterval: 1000 * 30,
    }
  );

  const handleSubmit = async (
    id: number,
    updatedData: CustomerDetailResponse
  ): Promise<void> => {
    await apiClient.post(
      `/real-estate-owner-customer-management/customer/${id}`,
      updatedData
    );
  };

  const handleDelete = async (
    id: number,
    managementId: number
  ): Promise<void> => {
    await apiClient.delete(
      `/real-estate-owner-customer-management/customer/${id}`,
      {
        data: {
          real_estate_owner_customer_management_id: managementId,
        },
      }
    );
  };

  return { data: customerData, handleSubmit, handleDelete };
};

interface EmailHistories {
  id: number;
  mailSendId: number;
  title: string;
  scheduledSendAt: string;
}
