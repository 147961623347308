import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

export const OwnerInfoAnalyzeButton: React.FC = () => {
  return (
    <LinkButton
      variant={ButtonVariantOption.Text}
      startIcon={<PersonSearchOutlinedIcon />}
      sx={{ color: "#FFF", fontWeight: "bold" }}
      href={"/ownerinfo/list"}
      wrapperSize={SizingWrapperStyle.INHERIT}
    >
      所有者事項解析一覧
    </LinkButton>
  );
};
