import { Button as MuiButton, FormControl } from "@mui/material";
import { SizingWrapper, type ISizingWrapperProps } from "../Wrapper";
import { ButtonColorOption, type ButtonVariantOption } from "./Button";

interface IProps {
  children: React.ReactNode;
  variant: ButtonVariantOption;
  href: string;
  size?: "small" | "medium" | "large";
  color?: ButtonColorOption;
  fullWidth?: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  wrapperSize?: ISizingWrapperProps["size"];
  sx?: object;
  target?: string;
  rel?: string;
}

const LinkButton: React.FC<IProps> = (props) => {
  const {
    children,
    variant,
    color,
    fullWidth,
    size,
    disabled,
    startIcon,
    type,
    href,
    wrapperSize,
    sx,
    target,
    rel,
  } = props;

  return (
    <SizingWrapper size={wrapperSize}>
      <FormControl fullWidth>
        <MuiButton
          variant={variant}
          color={color}
          fullWidth={fullWidth}
          disabled={disabled}
          startIcon={startIcon}
          type={type}
          href={href}
          size={size}
          sx={sx}
          target={target}
          rel={rel}
        >
          {children}
        </MuiButton>
      </FormControl>
    </SizingWrapper>
  );
};

LinkButton.defaultProps = {
  color: ButtonColorOption.Primary,
  size: "medium",
  type: "button",
  fullWidth: false,
  disabled: false,
};

export { LinkButton };
