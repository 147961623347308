import { type AxiosInstance } from "axios";

import {
  type MailStatusLabel,
  type MailDetail,
  type MailDestinationCondition,
  type MailDestinationTypeLabel,
  ReverseMailStatusMapping,
  ReverseMailDestinationTypeMapping,
  type MailClient,
} from "./types";

export interface MailDetailAPIResponse {
  status: MailStatusLabel;
  title: string;
  body: string;
  destinationType: MailDestinationTypeLabel;
  destinationConditions: MailDestinationCondition | null;
  clients: MailClient[] | null;
  scheduledSendAt: string | null;
}

export const getMailDetailAPIUrl = (mailId: number): string =>
  `/mail/detail/${mailId}`;

export const getMailDetailAPIfetcher =
  (apiClient: AxiosInstance) =>
  async (url: string): Promise<MailDetail> => {
    return await apiClient
      .get<MailDetailAPIResponse>(url)
      .then((response) => {
        // 正規化
        const mail: MailDetail = {
          status: ReverseMailStatusMapping[response.data.status],
          title: response.data.title,
          body: response.data.body,
          clients: response.data.clients,
          destinationType:
            ReverseMailDestinationTypeMapping[response.data.destinationType],
          destinationConditions:
            response.data.destinationConditions != null
              ? response.data.destinationConditions
              : null,
          scheduledSendAt: response.data.scheduledSendAt,
        };
        return mail;
      })
      .catch((error) => {
        throw error;
      });
  };
