import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";

export interface MonthlyBudgetStatusListAPIResponse {
  planPrice: number;
  carryoverMoney: number;
  budget: number;
  remainingBudget: number;
  id: number | null;
  totalConsumeCredit: number | null;
  tax: number | null;
  billingDate: string | null;
}

export const MonthlyBudgetStatusListAPI =
  (): MonthlyBudgetStatusListAPIResponse[] => {
    const { apiClient } = useApiClient();

    const fetcher = async (
      url: string
    ): Promise<MonthlyBudgetStatusListAPIResponse[]> => {
      return (await apiClient.get<MonthlyBudgetStatusListAPIResponse[]>(url))
        .data;
    };
    const { data: monthlyBudgetStatus } = useSWR(
      "/credit/monthly-budget-status",
      fetcher,
      {
        revalidateOnFocus: false,
      }
    );

    return monthlyBudgetStatus ?? [];
  };
