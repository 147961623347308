import { SectionTitle } from "@/components/Title";
import { SizingWrapper } from "@/components/Wrapper";
import {
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import type { SearchSectionProps } from "../types";
import { CheckboxGroup } from "@/components/Checkbox";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Label } from "@/components/Label";
import {
  preventNonNumericValueWhenKeyDown,
  preventNonNumericValueWhenPaste,
} from "@/utils/utils";

export const searchConditionSchema = z
  .object({
    prefectures: z
      .array(z.string())
      .min(1, "少なくとも1つの都道府県を選択してください"),
    location: z.string().min(1, "所在を入力してください"),
    pictureTypeZenbujiko: z.boolean(),
    pictureTypeShoyushajiko: z.boolean(),
    isEmptyHouse: z.boolean(),
    ownerName: z.string().optional(),
    ownerAddress: z.string().optional(),
    mochibunFrom: z
      .string()
      .refine(
        (val) => val.length === 0 || Number(val) >= 2,
        "2以上を入力してください"
      ),
    mochibunTo: z
      .string()
      .refine(
        (val) => val.length === 0 || Number(val) >= 2,
        "2以上を入力してください"
      ),
  })
  .refine(
    (args) => {
      const { mochibunFrom, mochibunTo } = args;
      if (mochibunFrom.length !== 0 && mochibunTo.length !== 0) {
        return parseInt(mochibunFrom) <= parseInt(mochibunTo);
      }
      return true;
    },
    {
      message: "「以上」は「以下」より小さくしてください",
      path: ["mochibunTo"],
    }
  );

export type SearchConditionFormSchema = z.infer<typeof searchConditionSchema>;

export const SearchSection: React.FC<SearchSectionProps> = ({
  handleSearch,
  prefectures,
}) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SearchConditionFormSchema>({
    mode: "all",
    defaultValues: {
      prefectures: [],
      location: "",
      pictureTypeShoyushajiko: false,
      pictureTypeZenbujiko: false,
      isEmptyHouse: false,
      ownerName: "",
      ownerAddress: "",
      mochibunFrom: "",
      mochibunTo: "",
    },
    resolver: zodResolver(searchConditionSchema),
  });

  const [showLimitSentence, setShowLimitSentence] = useState<boolean>(false);
  const [isEmptyHouse, setIsEmptyHouse] = useState<boolean>(false);
  const [mochibunFrom, setMochibunFrom] = useState<string>("");
  const [mochibunTo, setMochibunTo] = useState<string>("");

  const onSubmit = (data: SearchConditionFormSchema): void => {
    handleSearch(data);
  };

  useEffect(() => {
    // 空き家、持ち分以上以下に入力があったら、500件までしか取得しない文章を表示。
    if (isEmptyHouse || mochibunFrom.length > 0 || mochibunTo.length > 0) {
      setShowLimitSentence(true);
    } else {
      setShowLimitSentence(false);
    }
  }, [isEmptyHouse, mochibunFrom, mochibunTo]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SectionTitle>検索</SectionTitle>
      </Box>

      <Box sx={{ mx: 2, mb: 2 }}>
        <Divider />
      </Box>

      <form
        onSubmit={
          handleSubmit(onSubmit) as React.FormEventHandler<HTMLFormElement>
        }
      >
        {/* 都道府県 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <SizingWrapper>
            <Controller
              name="prefectures"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <FormControl fullWidth>
                    <InputLabel
                      id="prefecture-multiple-checkbox-label"
                      required
                      error={!!errors.prefectures}
                    >
                      都道府県
                    </InputLabel>
                    <Select
                      {...field}
                      id="prefecture-multiple-checkbox"
                      error={!!errors.prefectures}
                      input={
                        <OutlinedInput
                          label="都道府県"
                          id="prefecture-multiple-chip"
                        />
                      }
                      labelId="prefecture-multiple-checkbox-label"
                      multiple
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => {
                            const selectPrefecture = prefectures.find(
                              (prefecture) => prefecture.prefCode === value
                            );

                            return (
                              <Chip
                                key={selectPrefecture?.prefCode}
                                label={selectPrefecture?.name}
                                sx={{ cursor: "pointer" }}
                              />
                            );
                          })}
                        </Box>
                      )}
                      endAdornment={
                        field.value.length > 0 ? (
                          <IconButton
                            sx={{
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              reset({
                                prefectures: [],
                                location: getValues("location"),
                                pictureTypeShoyushajiko: getValues(
                                  "pictureTypeShoyushajiko"
                                ),
                                pictureTypeZenbujiko: getValues(
                                  "pictureTypeZenbujiko"
                                ),
                                isEmptyHouse: getValues("isEmptyHouse"),
                                ownerName: getValues("ownerName"),
                                ownerAddress: getValues("ownerAddress"),
                                mochibunFrom: getValues("mochibunFrom"),
                                mochibunTo: getValues("mochibunTo"),
                              });
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null
                      }
                    >
                      {prefectures.map((prefecture) => (
                        <MenuItem
                          key={prefecture.prefCode}
                          value={prefecture.prefCode}
                        >
                          <Checkbox
                            checked={field.value.includes(prefecture.prefCode)}
                          />
                          <ListItemText primary={prefecture.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {errors?.prefectures?.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
            />
          </SizingWrapper>
        </Box>

        {/* 所在 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="location"
                    label="所在"
                    type="text"
                    required
                    error={!!errors.location}
                    helperText={errors.location ? errors.location.message : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {/* 所有者名・所有者住所 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="ownerName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="ownerName"
                    label="所有者名"
                    type="text"
                    error={!!errors.ownerName}
                    helperText={
                      errors.ownerName ? errors.ownerName.message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="ownerAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="ownerAddress"
                    label="所有者住所"
                    type="text"
                    error={!!errors.ownerAddress}
                    helperText={
                      errors.ownerAddress ? errors.ownerAddress.message : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {/* 事項種別 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <CheckboxGroup label="事項種別">
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Controller
                    name="pictureTypeZenbujiko"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} name="pictureTypeZenbujiko" />
                    )}
                  />
                }
                label="全部事項"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="pictureTypeShoyushajiko"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} name="pictureTypeShoyushajiko" />
                    )}
                  />
                }
                label="所有者事項"
              />
            </Box>
          </CheckboxGroup>
        </Box>

        {/* 空き家 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <CheckboxGroup label="空き家">
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Controller
                    name="isEmptyHouse"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        name="isEmptyHouse"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          field.onChange(event.target.checked);
                          setIsEmptyHouse(event.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label="空き家のみ取得"
              />
            </Box>
          </CheckboxGroup>
        </Box>

        {/* 共有人数 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <Label sx={{ mb: 2 }}>共有人数</Label>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Controller
                name="mochibunFrom"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="mochibunFrom"
                    label="以上"
                    type="number"
                    error={!!errors.mochibunFrom}
                    helperText={
                      errors.mochibunFrom ? errors.mochibunFrom.message : ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(event);
                      setMochibunFrom(event.target.value);
                    }}
                    onKeyDown={preventNonNumericValueWhenKeyDown}
                    onPaste={preventNonNumericValueWhenPaste}
                    inputProps={{ min: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5" sx={{ mt: 1 }}>
                ~
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="mochibunTo"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="mochibunTo"
                    label="以下"
                    type="number"
                    error={!!errors.mochibunTo}
                    helperText={
                      errors.mochibunTo ? errors.mochibunTo.message : ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(event);
                      setMochibunTo(event.target.value);
                    }}
                    onKeyDown={preventNonNumericValueWhenKeyDown}
                    onPaste={preventNonNumericValueWhenPaste}
                    inputProps={{ min: 2 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {/* 空き家または持ち分が入力された場合に表示する文言 */}
        {showLimitSentence ? (
          <Box sx={{ mx: 2, mb: 2 }}>
            <AlertTitle
              sx={{ fontWeight: "bold", color: "red", fontSize: "0.85rem" }}
            >
              ・空き家または共有人数を入力した場合は最大で500件検索します。
            </AlertTitle>
          </Box>
        ) : null}

        {/* ボタン */}
        <Box
          sx={{
            mr: 2,
            mb: 3,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit" variant="outlined">
            検索
          </Button>
        </Box>
      </form>
    </>
  );
};
