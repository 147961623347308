import { Stack, Typography } from "@mui/material";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { ButtonVariantOption, SubmitButton } from "@/components/Button";
import { CustomModal } from "@/components/Modal";
import { PageTitle } from "@/components/Title";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { type MonitoringDataGridRow } from "../types";
import { AcquireBooksDataGrid } from "./AcquireBooksDataGrid";

interface IProps {
  handleAcquireBooksAPI: () => Promise<void>;
  handleClose: () => void;
  openAcquireBooks: boolean;
  rows: MonitoringDataGridRow[];
  selectedIds: GridSelectionModel;
  setAcquireBooksRows: React.Dispatch<
    React.SetStateAction<MonitoringDataGridRow[]>
  >;
}

/**
 * 一括操作で「謄本取得する」ボタンクリック時に表示するモーダル
 * @param {IProps} props
 * @returns
 */
export const AcquireBooksModal: React.FC<IProps> = (props: IProps) => {
  const {
    handleAcquireBooksAPI,
    handleClose,
    openAcquireBooks,
    rows,
    selectedIds,
    setAcquireBooksRows,
  } = props;

  return (
    <CustomModal
      isOpen={openAcquireBooks}
      handleClose={() => {
        handleClose();
      }}
      ariaLabelledby="acquire-books-modal"
      ariaDescribedby="acquire-books-modal-description"
      height={"80%"}
      width={"80%"}
    >
      <Stack direction={"column"} spacing={3}>
        {/* タイトル */}
        <PageTitle>確認画面</PageTitle>

        {/* 説明 */}
        <Typography>
          以下のデータの謄本を一括取得します。よろしいですか。
        </Typography>

        {/* データ表示 */}
        <AcquireBooksDataGrid
          ids={selectedIds}
          rows={rows}
          setAcquireBooksRows={setAcquireBooksRows}
        />

        {/* ボタン群 */}
        <Stack direction={"row"} justifyContent={"center"}>
          <SubmitButton
            label="一括取得する"
            onClick={() => {
              (async () => {
                await handleAcquireBooksAPI();

                handleClose();
              })();
            }}
            size={SizingWrapperStyle.SMALL}
            timeout={30000}
            variant={ButtonVariantOption.Contained}
          />
        </Stack>
      </Stack>
    </CustomModal>
  );
};
