/**
 * initMocks
 * mockのセットアップを行う
 * developmentモード以外の場合は何もしない
 *
 * mock機能の開始前にHTTPリクエストを送信してしまいエラーになる問題に対処済み
 */
export const initMocks = async (): Promise<
  ServiceWorkerRegistration | undefined
> => {
  if (process.env.NODE_ENV === "development") {
    if (process.env.VITE_ENABLE_MOCK_SERVER === "true") {
      const { worker } = await import("./browser");
      return await worker.start();
    }
  }

  return undefined;
};
