import { createContext } from "react";
import * as React from "react";
import useSWR from "swr";
import axios from "axios";

/**
 * 最新バージョンがあるかどうかを確認する確認先URL
 */
const FETCH_URL_TO_CHECK_NEW_VERSION = "/assets/version.json";

/**
 * 最新バージョンを確認する間隔
 */
const CHECK_INTERVAL_IN_SECONDS = 60 * 10;

interface Props {
  children: React.ReactNode;
}

/**
 * 更新の有無を判定する関数
 * lastBuildTimeの値が新しくなっているかどうかで更新の有無を判定する
 * @param currentVersion 現在のバージョン情報
 * @param targetVersion 比較対象のバージョン情報
 */
const checkNewVersion = (
  currentVersion: FrontendAppVersionInfo,
  targetVersion: FrontendAppVersionInfo
): boolean => {
  return currentVersion.lastBuildTime < targetVersion.lastBuildTime;
};

/**
 * SWRに渡すためのfetcher関数
 * @param url
 */
const fetcher = async (url: string): Promise<FrontendAppVersionInfo> => {
  const res = await axios.get<FrontendAppVersionInfo>(url, {
    headers: {
      "Cache-Control": "no-cache",
    },
  });
  return res.data;
};

/**
 * Reactのコンテキストの仕組みを用いて新バージョンの有無やバージョン番号の情報を配下のコンポーネントに渡す
 */
export const NewVersionAvailableContext = createContext<
  [boolean, FrontendAppVersionInfo | undefined]
>([false, undefined]);

/**
 * フロントエンドの最新バージョンを定期的にチェックするコンポーネント
 * Reactのコンテキストの仕組みを用いて配下のコンポーネントに最新バージョンがあるかどうかを知らせている
 * 各コンポーネントは`const newVersion = useContext(NewVersionAvailableContext);`で最新バージョンがあるかどうかを知ることができる
 * データの形式はタプルとなっている [isNewVersionAvailable, data]
 * isNewVersionAvailableがbooleanで最新があるかどうかを示すフラグ
 * dataが最新のFrontendAppVersionInfo型のデータ
 * @param children
 */
export const UpdateChecker: React.FC<Props> = ({ children }) => {
  let isNewVersionAvailable = false;
  const { data } = useSWR<FrontendAppVersionInfo>(
    FETCH_URL_TO_CHECK_NEW_VERSION,
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refreshInterval: 1000 * CHECK_INTERVAL_IN_SECONDS,
    }
  );

  if (data) {
    isNewVersionAvailable = checkNewVersion(_FRONTEND_APP_VERSION, data);
  }

  return (
    <NewVersionAvailableContext.Provider value={[isNewVersionAvailable, data]}>
      {children}
    </NewVersionAvailableContext.Provider>
  );
};
