import {
  FormControl,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
} from "@mui/material";
import { SizingWrapper, type ISizingWrapperProps } from "../Wrapper";

type OmitKeys =
  | "id"
  | "type"
  | "label"
  | "value"
  | "endAdornment"
  | "size"
  | "onChange";

interface IProps
  extends ISizingWrapperProps,
    Omit<OutlinedInputProps, OmitKeys> {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const TextFieldTell: React.FC<IProps> = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    size,
    required = true,
    ...otherProps
  } = props;

  return (
    <SizingWrapper size={size}>
      <FormControl fullWidth required={required}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <OutlinedInput
          id={id}
          type="tel"
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
          label={label}
          {...otherProps}
        />
      </FormControl>
    </SizingWrapper>
  );
};

export { TextFieldTell };
