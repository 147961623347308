import { Box, FormGroup, MenuItem, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { CheckboxForAcquireBook } from "../components/Checkbox";
import { type BookTypeEng, type BookTypeJpn } from "../types/acquirebook";

// 都道府県リスト
const prefectures: Array<{ id: number; val: string }> = [
  { id: 1, val: "北海道" },
  { id: 2, val: "青森県" },
  { id: 3, val: "岩手県" },
  { id: 4, val: "宮城県" },
  { id: 5, val: "秋田県" },
  { id: 6, val: "山形県" },
  { id: 7, val: "福島県" },
  { id: 8, val: "茨城県" },
  { id: 9, val: "栃木県" },
  { id: 10, val: "群馬県" },
  { id: 11, val: "埼玉県" },
  { id: 12, val: "千葉県" },
  { id: 13, val: "東京都" },
  { id: 14, val: "神奈川県" },
  { id: 15, val: "新潟県" },
  { id: 16, val: "富山県" },
  { id: 17, val: "石川県" },
  { id: 18, val: "福井県" },
  { id: 19, val: "山梨県" },
  { id: 20, val: "長野県" },
  { id: 21, val: "岐阜県" },
  { id: 22, val: "静岡県" },
  { id: 23, val: "愛知県" },
  { id: 24, val: "三重県" },
  { id: 25, val: "滋賀県" },
  { id: 26, val: "京都府" },
  { id: 27, val: "大阪府" },
  { id: 28, val: "兵庫県" },
  { id: 29, val: "奈良県" },
  { id: 30, val: "和歌山県" },
  { id: 31, val: "鳥取県" },
  { id: 32, val: "島根県" },
  { id: 33, val: "岡山県" },
  { id: 34, val: "広島県" },
  { id: 35, val: "山口県" },
  { id: 36, val: "徳島県" },
  { id: 37, val: "香川県" },
  { id: 38, val: "愛媛県" },
  { id: 39, val: "高知県" },
  { id: 40, val: "福岡県" },
  { id: 41, val: "佐賀県" },
  { id: 42, val: "長崎県" },
  { id: 43, val: "熊本県" },
  { id: 44, val: "大分県" },
  { id: 45, val: "宮崎県" },
  { id: 46, val: "鹿児島県" },
  { id: 47, val: "沖縄県" },
];

const getPrefectureIdByValue = (value: string): number | undefined => {
  return prefectures.find((pref) => pref.val === value)?.id;
};

// IDから県名を返す、失敗した場合はundefined
const getPrefectureNameById = (id: number): string | undefined => {
  return prefectures.find((pref) => pref.id === id)?.val;
};

// 不動産種別の文字列の英語版/日本語版を変換するテーブル
const bookTypeTableToJpn: Record<BookTypeEng, BookTypeJpn> = {
  LAND: "土地" as BookTypeJpn,
  BUILDING: "建物" as BookTypeJpn,
  CONDOMINIUM: "区分建物" as BookTypeJpn,
  ONE_BUILDING: "一棟" as BookTypeJpn,
};

const convertBookTypeToJpn = (type: BookTypeEng): BookTypeJpn => {
  return bookTypeTableToJpn[type];
};
const convertBookTypeToEng = (type: BookTypeJpn): BookTypeEng => {
  for (const prop in bookTypeTableToJpn) {
    if (bookTypeTableToJpn[prop as BookTypeEng] === type)
      return prop as BookTypeEng;
  }
  // 引数が正しければ、ここに来ることはないけど、書かないとエラーになるので書く
  return "LAND" as BookTypeEng;
};

// 不動産種別の選択値と、その時のチェックボックスの有効/無効の状態を示すテーブル
// falseのときは"disabled = false"なので有効となる
const OptionsEnableDisableTable: Record<string, Record<string, boolean>> = {
  LAND: {
    syoyusya: false,
    zennbu: false,
    sintaku: false,
    kyodo: false,
    genzai: false,
    tiseki: false,
    densi: false,
    tieki: false,
    tatemono: true,
  },
  BUILDING: {
    syoyusya: false,
    zennbu: false,
    sintaku: false,
    kyodo: false,
    genzai: false,
    tiseki: true,
    densi: true,
    tieki: true,
    tatemono: false,
  },
  CONDOMINIUM: {
    syoyusya: false,
    zennbu: false,
    sintaku: false,
    kyodo: false,
    genzai: false,
    tiseki: true,
    densi: true,
    tieki: true,
    tatemono: false,
  },
};

// 都道府県セレクトボックスで表示するメニューを生成する
const getPrefectureMenuItems = (): React.ReactNode => {
  return prefectures.map((pref) => (
    <MenuItem key={pref.id} value={pref.id}>
      {pref.val}
    </MenuItem>
  ));
};

const zenbuWithTooltip = (
  <>
    全部事項
    <Tooltip
      title="信託/共同担保目録をそれぞれ含めるか選択、現在事項は履歴情報を含めない場合に選択してください"
      placement="right"
    >
      <HelpIcon />
    </Tooltip>
  </>
);

interface IPropsCheckboxesToSelectBookOptions {
  // チェックボックスの状態を保持するstate
  requestOptions: Record<string, Record<string, boolean>>;
  setRequestOptions: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, boolean>>>
  >;
  // チェックボックスの有効無効を判定するための不動産種別
  realEstateTypeEng: string;
  onChange?: (
    event: boolean,
    key: string,
    requestOptions: Record<string, Record<string, boolean>>
  ) => void;
}

// 登記取得モーダルや、単体登記取得画面で使用するもので、登記種別を選択するセレクトボックスを生成するReactComponent
const CheckboxesToSelectBookOptions: React.FC<
  IPropsCheckboxesToSelectBookOptions
> = (props) => {
  const { requestOptions, setRequestOptions, realEstateTypeEng, onChange } =
    props;

  // 不動産種別が変更された時に実行する
  useEffect(() => {
    // realEstateTypeの値がOptionsEnableDisableTableにあるkey["土地", "建物", "区分建物"]に当てはまるときだけ実行を継続する
    const isReadEstateTypeValid: boolean = Object.keys(
      OptionsEnableDisableTable
    ).reduce((acc, cur) => {
      if (acc) return true;
      return realEstateTypeEng === cur;
    }, false);
    if (!isReadEstateTypeValid) return;

    // チェックボックスをOptionsEnableDisableTableに示されている状態に合わせる
    const newRequestOptions = { ...requestOptions };
    for (const val in newRequestOptions) {
      // 指示値がtrueならチェックボックス無効とチェック解除を行う
      if (OptionsEnableDisableTable[realEstateTypeEng][val]) {
        newRequestOptions[val].disable = true;
        newRequestOptions[val].checked = false;
      } else {
        // 指示値falseなら表示する
        newRequestOptions[val].disable = false;
      }
    }
    setRequestOptions(newRequestOptions);
  }, [realEstateTypeEng]);

  const handleOptionChange = (event: boolean, key: string): void => {
    const newRequestOptions = {
      ...requestOptions,
      [key]: { checked: event, disable: requestOptions[key].disable },
    };

    if (key === "zennbu") {
      Object.assign(newRequestOptions, {
        sintaku: { checked: event, disable: requestOptions.sintaku.disable },
        kyodo: { checked: event, disable: requestOptions.kyodo.disable },
      });
    }

    setRequestOptions(newRequestOptions);
    if (onChange) {
      onChange(event, key, newRequestOptions);
    }
  };

  return (
    <Box sx={{ m: 3 }}>
      <FormGroup>
        <Stack direction="row" spacing={0} justifyContent="space-evenly">
          <Stack>
            <CheckboxForAcquireBook
              label={"所有者事項"}
              checked={requestOptions.syoyusya.checked}
              disabled={requestOptions.syoyusya.disable}
              onChange={(e) => {
                handleOptionChange(e, "syoyusya");
              }}
            />
            <CheckboxForAcquireBook
              label={zenbuWithTooltip}
              checked={requestOptions.zennbu.checked}
              disabled={requestOptions.zennbu.disable}
              onChange={(e) => {
                handleOptionChange(e, "zennbu");
              }}
            />

            <CheckboxForAcquireBook
              label={"信託目録"}
              sx={{ pl: "2em", py: 0 }}
              size={"small"}
              checked={requestOptions.sintaku.checked}
              disabled={requestOptions.sintaku.disable}
              onChange={(e) => {
                handleOptionChange(e, "sintaku");
              }}
            />
            <CheckboxForAcquireBook
              label={"共同担保目録"}
              sx={{ pl: "2em", py: 0 }}
              size={"small"}
              checked={requestOptions.kyodo.checked}
              disabled={requestOptions.kyodo.disable}
              onChange={(e) => {
                handleOptionChange(e, "kyodo");
              }}
            />
            <CheckboxForAcquireBook
              label={"現在事項"}
              sx={{ pl: "2em", py: 0 }}
              size={"small"}
              checked={requestOptions.genzai.checked}
              disabled={requestOptions.genzai.disable}
              onChange={(e) => {
                handleOptionChange(e, "genzai");
              }}
            />
          </Stack>
          <Stack>
            <CheckboxForAcquireBook
              label={"地積測量図"}
              checked={requestOptions.tiseki.checked}
              disabled={requestOptions.tiseki.disable}
              onChange={(e) => {
                handleOptionChange(e, "tiseki");
              }}
            />
            <CheckboxForAcquireBook
              label={"電子公図"}
              checked={requestOptions.densi.checked}
              disabled={requestOptions.densi.disable}
              onChange={(e) => {
                handleOptionChange(e, "densi");
              }}
            />
            <CheckboxForAcquireBook
              label={"地役権図面"}
              checked={requestOptions.tieki.checked}
              disabled={requestOptions.tieki.disable}
              onChange={(e) => {
                handleOptionChange(e, "tieki");
              }}
            />
          </Stack>
          <Stack>
            <CheckboxForAcquireBook
              label={"建物図面"}
              checked={requestOptions.tatemono.checked}
              disabled={requestOptions.tatemono.disable}
              onChange={(e) => {
                handleOptionChange(e, "tatemono");
              }}
            />
          </Stack>
        </Stack>
      </FormGroup>
    </Box>
  );
};

export {
  prefectures,
  getPrefectureMenuItems,
  getPrefectureIdByValue,
  getPrefectureNameById,
  CheckboxesToSelectBookOptions,
  convertBookTypeToJpn,
  convertBookTypeToEng,
};
