import { type AxiosInstance } from "axios";
import {
  type MonitoringDetailRow,
  type MonitoringDetailReceptionBookRow,
} from "../types";

interface IRequests {
  id: number; // モニタリング詳細テーブル（RealEstateMonitoringDetail）のID
}

interface IResponse {
  detail: MonitoringDetailRow; // モニタリング詳細の情報
  results: MonitoringDetailReceptionBookRow[]; // 受付帳の結果
}

/**
 * モニタリング詳細受付帳結果に表示するデータを取得する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 * @returns {Promise<IResponse>}
 */
export const GetMonitoringDetailReceptionBookAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<IResponse> => {
  // TODO: 受付帳のリストに表示するデータを取得するAPIを実行する。
  console.log(
    "モニタリング詳細受付帳結果画面表示データ取得APIが実行されました。"
  );
  console.log("id =", requests.id);

  return {
    detail: {
      id: 1,
      monitoringId: 1,
      realEstateType: "LAND",
      location: "東京都千代田区丸の内一丁目",
      chibanKaokuNumber: "9-1",
      latestReceptionDate: new Date(2024, 9, 19),
      soto: null,
      remarks: "東京駅",
    },
    results: [
      {
        id: 1,
        legalAffairsBureauRequestDate: new Date(2024, 8, 10),
        receptionReason: "所有権移転相続・法人合併",
        soto: 10,
      },
      {
        id: 2,
        legalAffairsBureauRequestDate: null,
        receptionReason: "相続",
        soto: 5,
      },
      {
        id: 3,
        legalAffairsBureauRequestDate: new Date(2024, 8, 21),
        receptionReason: null,
        soto: 10,
      },
      {
        id: 4,
        legalAffairsBureauRequestDate: new Date(2024, 8, 30),
        receptionReason: "一般承継",
        soto: null,
      },
    ],
  };
};
