import { type SxProps, Typography } from "@mui/material";

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}
export const Label: React.FC<Props> = ({ children, sx }) => {
  return (
    <Typography sx={{ fontWeight: "700", mb: 1, ...sx }}>{children}</Typography>
  );
};
