import { ButtonVariantOption, SubmitButton } from "@/components/Button";
import { CustomModal } from "@/components/Modal";
import { PageTitle } from "@/components/Title";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { Button, Stack, Typography } from "@mui/material";

interface IProps {
  onSubmit: () => Promise<void>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * 実行するボタン押下時の表示されるモーダル。
 * @param {IProps} props
 * @returns
 */
export const ExecuteModal: React.FC<IProps> = (props: IProps) => {
  const { onSubmit, open, setOpen } = props;

  return (
    <CustomModal
      isOpen={open}
      handleClose={() => {
        setOpen(false);
      }}
      ariaLabelledby="execute-modal"
      ariaDescribedby="execute-modal-description"
    >
      <Stack direction={"column"} spacing={3}>
        {/* タイトル */}
        <PageTitle>確認画面</PageTitle>

        {/* メッセージ */}
        <Typography>【課金が発生します】モニタリングを開始します。</Typography>

        {/* ボタン */}
        <Stack
          direction={"row"}
          spacing={2}
          marginTop={2}
          justifyContent={"center"}
        >
          <SubmitButton
            label="モニタリングする"
            onClick={(_, cancelLoading) => {
              setOpen(false);
              onSubmit()
                .then(() => {
                  cancelLoading();
                })
                .catch((reason) => {
                  cancelLoading();
                  console.error(reason);
                });
            }}
            size={SizingWrapperStyle.SMALL}
            timeout={30000}
            variant={ButtonVariantOption.Contained}
          />
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{ width: 150 }}
            variant="outlined"
          >
            キャンセル
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
