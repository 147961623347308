import type {
  CustomerStatusDataGridRow,
  DestinationConditions,
  DestinationConditionsRow,
} from "@/features/customerManagement/types";
import { AxiosError, HttpStatusCode, type AxiosInstance } from "axios";
import { format, isValid } from "date-fns";
import { toast } from "react-toastify";
import { type DestinationConditionsResponseError } from "./types";

export const GetConditionCustomerListAPI = async (
  apiClient: AxiosInstance,
  url: string,
  destinationConditions: DestinationConditions
): Promise<CustomerStatusDataGridRow[]> => {
  const date: string | null =
    destinationConditions.legalAffairsBureauRequestDate != null &&
    isValid(destinationConditions.legalAffairsBureauRequestDate)
      ? format(
          destinationConditions.legalAffairsBureauRequestDate,
          "yyyy-MM-dd"
        )
      : null;

  try {
    const response = await apiClient.post<DestinationConditionsRow[]>(url, {
      ...destinationConditions,
      legalAffairsBureauRequestDate: date,
      includeReceptionReason: destinationConditions.includeReceptionReason,
      includeMochibun: destinationConditions.includeMochibun,
      includeLegalAffairsBureauRequestDate:
        destinationConditions.includeLegalAffairsBureauRequestDate,
      includePostcode: destinationConditions.includePostcode,
      includeEmail: destinationConditions.includeEmail,
      includeOriginalShozai: destinationConditions.includeOriginalShozai,
      includeRealEstateType: destinationConditions.includeRealEstateType,
      includeStatus: destinationConditions.includeStatus,
    });

    const customers: CustomerStatusDataGridRow[] = [];
    response.data.map((element) => {
      return customers.push({
        id: element.realEstateOwnerId,
        realEstateOwnerCustomerManagementId: element.id,
        status: element.status,
        realEstateType: element.realEstateType,
        originalShozai: element.originalShozai,
        email: element.email,
        tel: element.tel,
        postcode: element.postcode,
        ownerAddress: element.ownerAddress,
        ownerName: element.ownerName,
        legalAffairsBureauRequestDate: element.legalAffairsBureauRequestDate,
        mochibunStr: element.mochibunStr != null ? element.mochibunStr : "",
        receptionReason: element.receptionReason,
        responsiblePersonName:
          element.responsiblePersonName != null
            ? element.responsiblePersonName
            : "",
        isOutOfTarget: element.isOutOfTarget,
      });
    });

    return customers;
  } catch (error) {
    if (error instanceof AxiosError && error.response != null) {
      if (error.response.status === HttpStatusCode.BadRequest) {
        if (isDestinationConditionsErrorType(error.response.data)) {
          const data = error.response.data;
          if (data.receptionReason != null)
            toast.error(`登記原因:${data.receptionReason[0]}`, {
              autoClose: false,
            });
          if (data.mochibun != null)
            toast.error(`持分:${data.mochibun[0]}`, {
              autoClose: false,
            });
          if (data.legalAffairsBureauRequestDate != null)
            toast.error(
              `登記申請年月:${data.legalAffairsBureauRequestDate[0]}`,
              { autoClose: false }
            );
          if (data.postcode != null)
            toast.error(`郵便番号:${data.postcode[0]}`, {
              autoClose: false,
            });
          if (data.email != null)
            toast.error(`メールアドレス:${data.email[0]}`, {
              autoClose: false,
            });
          if (data.originalShozai != null)
            toast.error(`物件情報:${data.originalShozai[0]}`, {
              autoClose: false,
            });
          if (data.realEstateTypeTochi != null)
            toast.error(`不動産種別の土地:${data.realEstateTypeTochi[0]}`, {
              autoClose: false,
            });
          if (data.realEstateTypeKutate != null)
            toast.error(
              `不動産種別の区分建物:${data.realEstateTypeKutate[0]}`,
              { autoClose: false }
            );
          if (data.realEstateTypeTatemono != null)
            toast.error(`不動産種別の建物:${data.realEstateTypeTatemono[0]}`, {
              autoClose: false,
            });
          if (data.statusNoApproach != null)
            toast.error(`ステータスの未営業:${data.statusNoApproach[0]}`, {
              autoClose: false,
            });
          if (data.statusApproach != null)
            toast.error(`ステータスの営業中:${data.statusApproach[0]}`, {
              autoClose: false,
            });
          if (data.statusRejected != null)
            toast.error(`ステータスのお断り:${data.statusRejected[0]}`, {
              autoClose: false,
            });
          if (data.statusNoResponse != null)
            toast.error(`ステータスのレスなし:${data.statusNoResponse[0]}`, {
              autoClose: false,
            });
          if (data.statusComplaint != null)
            toast.error(
              `ステータスのクレームありNG:${data.statusComplaint[0]}`,
              { autoClose: false }
            );
          if (data.statusSent != null)
            toast.error(`ステータスの送信済み:${data.statusSent[0]}`, {
              autoClose: false,
            });
          if (data.statusReaction != null)
            toast.error(`ステータスの反響あり:${data.statusReaction[0]}`, {
              autoClose: false,
            });
        } else {
          toast.error("入力が不正です。", { autoClose: false });
        }
      } else {
        toast.error(
          "エラーが発生しました。再度同じ操作を行い、再発する場合はお問い合わせください。",
          { autoClose: false }
        );
      }
    } else {
      toast.error(
        "エラーが発生しました。再度同じ操作を行い、再発する場合はお問い合わせください。",
        { autoClose: false }
      );
    }

    return [];
  }
};

function isDestinationConditionsErrorType(
  params: unknown
): params is DestinationConditionsResponseError {
  return params !== undefined;
}
