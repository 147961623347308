const ENVIRONMENTS = {
  LOCAL: "local",
  DEV: "development",
  STG: "staging",
  PROD: "production",
} as const;

type Env = typeof ENVIRONMENTS[keyof typeof ENVIRONMENTS];

const envValues = Object.values(ENVIRONMENTS) as Env[];

function getEnv(value: string | undefined): Env {
  if (value === undefined) {
    return ENVIRONMENTS.LOCAL;
  }

  if (!envValues.includes(value as Env)) {
    return ENVIRONMENTS.LOCAL;
  }

  return value as Env;
}

export type { Env };

export { ENVIRONMENTS, getEnv };
