import { Navigate, Route, Routes, Link, useLocation } from "react-router-dom";
import { MailTemplate } from "@/features/emailManagement/routes/MailTemplate";
import React, { useMemo } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { Mail } from "./Mail";

export const EmailManagementRoutes: React.FC = () => {
  const { pathname } = useLocation();
  const currentTab = useMemo(() => {
    if (pathname.includes("mailTemplate")) {
      return "mailTemplate";
    } else {
      return "mail";
    }
  }, [pathname]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={currentTab}>
          <Tab
            label="メールボックス"
            component={Link}
            value="mail"
            to="mail/savedEmailList"
          />
          <Tab
            label="テンプレート"
            component={Link}
            value="mailTemplate"
            to="mailTemplate/list"
          />
        </Tabs>
      </Box>
      <Routes>
        <Route path="mailTemplate/*" element={<MailTemplate />} />
        <Route path="mail/*" element={<Mail />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </>
  );
};
