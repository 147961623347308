import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  Button as MuiButton,
  Stack,
  Link,
  type SelectChangeEvent,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Button,
  ButtonVariantOption,
  LinkButton,
} from "../../components/Button";
import { Divider } from "@/components/Divider";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle, SectionTitle } from "@/components/Title";
import { FeedGrid } from "./components/FeedGrid";
import { ReceptionBookImportStatus } from "./components/ReceptionBookImportStatus";
import { FileUploadButton } from "./components/FileUploadButton";
import {
  type ISingleBookAcquireDialogData,
  type IRealEstateReceptionBookFeedPresenterProps,
  type IMultipleBookAcquireData,
} from "./types";
import { useLocation, useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import {
  type AcquireMultipleBookHandoffData,
  type BookTypeEng,
} from "@/types/acquirebook";
import { useApiClient } from "@/hooks/useApiClient";
import { toast } from "react-toastify";
import {
  preventNonNumericValueWhenKeyDown,
  preventNonNumericValueWhenPaste,
} from "@/utils/utils";
import { SearchCondition } from "./components/SearchCondition";
import Tooltip from "@mui/material/Tooltip";
import { type NavigateOptions } from "react-router/dist/lib/context";
import { useFeatureFlags } from "@/configs/featureFlag";
import type { HandOffMessage } from "@/types/handOffMessage";
import { useGeoSpaceApi } from "@/features/map/hooks/useGeospaceSearch";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { HttpStatusCode, isAxiosError } from "axios";
import { AcquireBookFormUI } from "@/components/AcquireBook/AcquireBookFormUI";

const RealEstateReceptionBookFeedPresenter: React.FC<
  IRealEstateReceptionBookFeedPresenterProps
> = (props) => {
  const {
    gridApiRef,
    handleSearch,
    setSotoRange,
    setEstimateAcreageRange,
    setEstimateLandPriceRange,
    setApproximateEstimatePriceRange,
    setResidenceOptions,
    setCommercialOptions,
    setIndustryOptions,
    setYosekiRatioRange,
    setKenpeiRatioRange,
    setHasTrade,
    setFileToCsvUpload,
    setBuildingAgeRange,
    setFarmlandAreaRange,
    filterCondition,
    importStatuses,
    sotoRange,
    estimateAcreageRange,
    estimateLandPriceRange,
    approximateEstimatePriceRange,
    residenceOptions,
    commercialOptions,
    industryOptions,
    yosekiRatioRange,
    kenpeiRatioRange,
    hasTrade,
    fileToCsvUpload,
    buildingAgeRange,
    farmlandAreaRange,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { apiClient } = useApiClient();
  const { ownerInfoList } = useFeatureFlags();

  const { geospaceGetMapServiceContractAppId } = useGeoSpaceApi();

  // URLに#csvdialogが付与されている場合はCSVインポートのダイアログを最初から開く
  const csvUploadDialogStatusInitialValue = location.hash === "#csvdialog";

  // CSVアップロードダイアログ表示/非表示を決定するフラグ
  const [csvUploadDialogStatus, setCsvUploadDialogStatus] = useState<boolean>(
    csvUploadDialogStatusInitialValue
  );

  // CSVアップロードダイアログの確認ボタンの有効/無効を決定するフラグ
  const [csvUploadConfirmButtonDisabled, setCsvUploadConfirmButtonDisabled] =
    useState<boolean>(true);

  // 登記/図面取得ダイアログ表示/非表示を決定するフラグ
  const [singleBookAcquireDialogStatus, setSingleBookAcquireDialogStatus] =
    useState<boolean>(false);

  // 登記/図面取得ダイアログで使用するデータをまとめて格納するState
  const [singleBookAcquireDialogData, setSingleBookAcquireDialogData] =
    useState<ISingleBookAcquireDialogData>({
      prefecturesId: 0,
      prefectures: "",
      address: "",
      chiban: "",
      kaokuNumber: "",
      realEstateTypeEng: "",
    });

  // feedの一覧から登記複数取得を行うためのState
  const [multipleBookAcquireData, setMultipleBookAcquireData] =
    useState<IMultipleBookAcquireData>({
      buttonString: "不動産登記/図面\n取得（個別）",
      buttonDisabled: false,
      tooltipMessage: "",
      selectedItem: [],
    });

  const handleCsvUploadDialogClose = (): void => {
    setCsvUploadDialogStatus(false);
    navigate("/feed", { replace: true }); // urlから#csvdialogを取り除き、履歴には残さない
  };

  // /api/picture-request/csvを呼び出した結果レスポンスのフォーマットを変換する
  // これはあとで変更する可能性大
  // acquireMultipleBooks/components/FeedGrid.tsxで処理すべきかも。要検討
  const convertCsvParseResult = (
    data: [Record<string, string>]
  ): AcquireMultipleBookHandoffData[] => {
    if (data.length > 0) {
      return data.map((value: Record<string, string>, index: number) => {
        return {
          id: index + 1,
          bookId: Number(value.id),
          bookType: value.realEstateType as BookTypeEng,
          prefCode: value.prefCode,
          prefName: value.prefName,
          locationFull: value.locationFull,
          chibanKaokuNumber: value.chibanKaokuNumber,
          kyoutan: false,
          sintaku: false,
          genzai: false,
          ownerInfo: false,
          electricDrawings: false,
          tisekiDrawings: false,
          tiekiDrawings: false,
          buildingDrawings: false,
        };
      });
    }
    return [];
  };

  // CSVアップロードの確認ボタンが押された時の処理
  const handleCsvUploadButtonClick = (): void => {
    if (fileToCsvUpload) {
      let row: AcquireMultipleBookHandoffData[] = [];
      const formData = new FormData();
      formData.append("file", fileToCsvUpload);
      apiClient
        .post("/picture-request/csv", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // 受信したレスポンスを次のページへの形式へ変換する
          row = convertCsvParseResult(res.data as [Record<string, string>]);

          // 確認ページへ遷移する
          navigate("/acquiremultiplebooks", { state: row });
        })
        .catch((e) => {
          console.error(e);
          if (
            isAxiosError(e) &&
            e.response?.status === HttpStatusCode.BadRequest
          ) {
            toast.error(
              <div>
                CSVファイルのアップロードに失敗しました。
                <br />
                ファイル内容をご確認ください。
              </div>
            );
          } else {
            toast.error("CSVファイルのアップロードに失敗しました");
          }
        });
    }
  };

  const handleSingleBookAcquireDialogClose = (): void => {
    setSingleBookAcquireDialogStatus(false);
  };

  // セレクトボックスが変更された時の処理
  const handleSelectChange = (
    key: string,
    event: SelectChangeEvent<number | null>,
    handleTarget: string
  ): void => {
    const value = Number(event.target.value);

    // valueが-1の場合は「指定なし」を選択したということなのでnullに変換して代入する
    if (handleTarget === "yosekiRatio") {
      const newRange = { ...yosekiRatioRange };
      newRange[key] = value === -1 ? null : value;
      setYosekiRatioRange(newRange);
    } else if (handleTarget === "kenpeiRatio") {
      const newRange = { ...kenpeiRatioRange };
      newRange[key] = value === -1 ? null : value;
      setKenpeiRatioRange(newRange);
    }
  };

  // テキストフィールドが変更されたときの処理
  const handleInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleTarget: string
  ): void => {
    // 入力された値を取得
    const value: string = event.target.value;

    if (handleTarget === "soto") {
      const newRange = { ...sotoRange };
      newRange[key] = toNumber(value);
      setSotoRange(newRange);
    } else if (handleTarget === "estimateAcreage") {
      const newRange = { ...estimateAcreageRange };
      newRange[key] = toNumber(value);
      setEstimateAcreageRange(newRange);
    } else if (handleTarget === "estimateLandPrice") {
      const newRange = { ...estimateLandPriceRange };
      newRange[key] = toNumber(value);
      setEstimateLandPriceRange(newRange);
    } else if (handleTarget === "approximateEstimatePrice") {
      const newRange = { ...approximateEstimatePriceRange };
      newRange[key] = toNumber(value);
      setApproximateEstimatePriceRange(newRange);
    } else if (handleTarget === "buildingAge") {
      const newRange = { ...buildingAgeRange };
      const numberVal = toNumber(value);
      // 1未満の入力値は許可しない。
      if (numberVal != null && numberVal < 1) {
        event.target.value = "";
        return;
      }
      newRange[key] = numberVal;
      setBuildingAgeRange(newRange);
    } else if (handleTarget === "farmlandArea") {
      const newRange = { ...farmlandAreaRange };
      const numberVal = toNumber(value);
      if (numberVal != null && (numberVal < 1 || numberVal > 2147483647)) {
        event.target.value = "";
        return;
      }
      newRange[key] = numberVal;
      setFarmlandAreaRange(newRange);
    }
  };

  function toNumber(value: string): number | null {
    if (value === "") {
      return null;
    } else {
      return Number(value);
    }
  }

  // 住居系のチェックボックスの状態を管理するステート
  const [residenceAllChecked, setResidenceAllChecked] = React.useState(false);
  // 商業系のチェックボックスの状態を管理するステート
  const [commercialAllChecked, setCommercialAllChecked] = React.useState(false);
  // 工業系のチェックボックスの状態を管理するステート
  const [industryAllChecked, setIndustryAllChecked] = React.useState(false);
  // 地番地図を表示する権限があるかどうかを判定するステート
  const [hasMapViewingPermission, setHasMapViewingPermission] =
    React.useState(false);

  // 住居系のチェックボックスの一括処理
  const handleResidenceChangeAll = (event: boolean): void => {
    // チェックボックスの状態を反転させる
    setResidenceAllChecked(!residenceAllChecked);
    // 選択肢のチェックボックスの状態を全て同じにする
    setResidenceOptions({
      ittei: event,
      icchuko: event,
      ichiju: event,
      nitei: event,
      nichuko: event,
      niju: event,
      denju: event,
      junju: event,
    });
  };

  // 商業系のチェックボックスの一括処理
  const handleCommercialChangeAll = (event: boolean): void => {
    // チェックボックスの状態を反転させる
    setCommercialAllChecked(!commercialAllChecked);
    // 選択肢のチェックボックスの状態を全て同じにする
    setCommercialOptions({
      kinsyo: event,
      syogyo: event,
    });
  };

  // 工業系のチェックボックスの一括処理
  const handleIndustryChangeAll = (event: boolean): void => {
    // チェックボックスの状態を反転させる
    setIndustryAllChecked(!industryAllChecked);
    // 選択肢のチェックボックスの状態を全て同じにする
    setIndustryOptions({
      junko: event,
      kogyo: event,
      kosen: event,
    });
  };

  // 選択肢のチェックボックスが変更されたときの処理
  const handleOptionChange = (
    event: boolean,
    handleTarget: string,
    key: string
  ): void => {
    // 選択肢のチェックボックスが全てチェックされているかどうかを判定する
    // const isAllChecked = Object.values(options).every((value) => value);

    if (handleTarget === "residence") {
      setResidenceOptions({
        ...residenceOptions,
        [key]: event,
      });
      // 全選択がうまく機能しないので保留
      // setResidenceAllChecked(isAllChecked);
    } else if (handleTarget === "commercial") {
      setCommercialOptions({
        ...commercialOptions,
        [key]: event,
      });
      // 全選択がうまく機能しないので保留
      // setCommercialAllChecked(isAllChecked);
    } else if (handleTarget === "industry") {
      setIndustryOptions({
        ...industryOptions,
        [key]: event,
      });
      // 全選択がうまく機能しないので保留
      // setIndustryAllChecked(isAllChecked);
    }
  };

  // 地図画面を別タブで開くため、ローカルストレージに地図に反映するためのデータを保存する
  const handleMapReflectClick = (): void => {
    const handOffMessage: HandOffMessage = {
      command: "MAP_DRAW_RECEPTION_BOOKS",
      argument: multipleBookAcquireData.selectedItem,
    };
    const navigateOptions: NavigateOptions = {
      state: { handOffMessage },
    };
    const stateKey = `mapState_${Date.now()}`;
    localStorage.setItem(stateKey, JSON.stringify(navigateOptions.state));
    window.open(`/map?stateKey=${stateKey}`, "_blank", "noopener,noreferrer");
  };

  // 登記/図面取得ボタンが押された時の処理
  const handleAcquireMultipleBooksClick = (): void => {
    if (multipleBookAcquireData.selectedItem.length > 0) {
      // feedのテーブルが1件以上選択されている場合複数登記取得画面へ遷移
      navigate("/acquiremultiplebooks", {
        state: multipleBookAcquireData.selectedItem,
      });
    } else {
      // feedのテーブルが選択されていない場合単体登記取得画面へ遷移
      navigate("/acquirebook", { state: {} });
    }
  };

  // ログイン直後に遷移する画面のためlocalstorageが読み取れないケースがあり
  // 地番地図のAPP IDを直接取得しているが、他画面でAPP IDを取得する際はuseMapServicePermissionを使う
  useEffect(() => {
    (async () => {
      // 地番地図のAPP IDを取得
      const appId = await geospaceGetMapServiceContractAppId();
      // APP IDが取得できた場合は地番地図を表示する権限ありと判断する
      setHasMapViewingPermission(!!appId);
    })();
  }, []);

  return (
    <>
      {/* 登記1件取得のダイアログ */}
      <Dialog
        open={singleBookAcquireDialogStatus}
        onClose={handleSingleBookAcquireDialogClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"登記および図面選択"}
        </DialogTitle>
        <DialogContent>
          <AcquireBookFormUI
            mode={"modal"}
            singleBookAcquireDialogData={singleBookAcquireDialogData}
          />
        </DialogContent>
      </Dialog>

      {/* CSVインポートのダイアログ */}
      <Dialog
        open={csvUploadDialogStatus}
        onClose={handleCsvUploadDialogClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"不動産登記/図面取得・CSVアップロード"}
        </DialogTitle>
        <DialogContent>
          <Link href={"assets/sample_登記図面請求アップロードファイル.csv"}>
            見本CSVをダウンロード
          </Link>
          <Stack alignItems="center">
            <Box>
              <Typography variant="h6">CSVをアップロードする</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <FileUploadButton
                variant={ButtonVariantOption.Contained}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // ファイルが選択されているときのみ確認画面へボタンを有効にする
                  const files = event.target.files ?? [];
                  if (files.length > 0) {
                    setFileToCsvUpload(files[0]);
                    setCsvUploadConfirmButtonDisabled(false);
                  } else {
                    if (fileToCsvUpload === null) {
                      setCsvUploadConfirmButtonDisabled(true);
                    }
                  }
                }}
                accept={".csv"}
                label={"アップロード"}
              />
              <Box sx={{ my: "auto", ml: 4 }}>
                <Typography>{fileToCsvUpload?.name}</Typography>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <Box sx={{ mx: "auto", my: 4 }}>
          <Button
            variant={ButtonVariantOption.Contained}
            onClick={handleCsvUploadButtonClick}
            disabled={csvUploadConfirmButtonDisabled}
            label={"確認画面へ"}
          />
        </Box>
      </Dialog>

      <PagePaper>
        <Accordion defaultExpanded sx={{ mb: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">お知らせ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReceptionBookImportStatus importStatuses={importStatuses} />
          </AccordionDetails>
        </Accordion>

        <Box sx={{ display: "flex", mb: 3 }}>
          <MenuBookIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産登記受付帳検索</PageTitle>
        </Box>

        {/* 検索条件 */}
        <SearchCondition
          handleInputChange={handleInputChange}
          preventNonNumericValueWhenKeyDown={preventNonNumericValueWhenKeyDown}
          preventNonNumericValueWhenPaste={preventNonNumericValueWhenPaste}
          residenceAllChecked={residenceAllChecked}
          handleResidenceChangeAll={handleResidenceChangeAll}
          handleOptionChange={handleOptionChange}
          commercialAllChecked={commercialAllChecked}
          handleCommercialChangeAll={handleCommercialChangeAll}
          industryAllChecked={industryAllChecked}
          handleIndustryChangeAll={handleIndustryChangeAll}
          handleSelectChange={handleSelectChange}
          {...props}
        />

        <Box sx={{ mt: 3 }} />

        {/* 不動産登記受付帳一覧テーブル */}
        <SectionPaper>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <SectionTitle>不動産登記受付帳一覧</SectionTitle>
            <Stack mx={2} my={2} direction="row" spacing={1}>
              {hasMapViewingPermission && (
                <Tooltip
                  title={
                    <>
                      地図に表示できるのは地番を持った受付帳データです
                      <br />
                      家屋番号の受付帳データは地図に表示されません
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <MuiButton
                    onClick={handleMapReflectClick}
                    variant="contained"
                    sx={{ fontWeight: "bold" }}
                  >
                    地図に反映する
                  </MuiButton>
                </Tooltip>
              )}

              {ownerInfoList ? (
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/ownerinfo/upload"
                  sx={{ "text-align": "center", fontWeight: "bold" }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  所有者事項
                  <br />
                  PDFアップロード
                </LinkButton>
              ) : null}
              <Tooltip
                title={multipleBookAcquireData.tooltipMessage}
                placement="top"
                arrow
              >
                <div style={{ display: "inherit" }}>
                  <MuiButton
                    onClick={handleAcquireMultipleBooksClick}
                    variant="contained"
                    disabled={multipleBookAcquireData.buttonDisabled}
                    sx={{ whiteSpace: "pre-wrap", fontWeight: "bold" }}
                  >
                    {multipleBookAcquireData.buttonString}
                  </MuiButton>
                </div>
              </Tooltip>
              <MuiButton
                onClick={() => {
                  setCsvUploadDialogStatus(true);
                }}
                variant="contained"
                sx={{ fontWeight: "bold" }}
              >
                不動産登記/図面
                <br />
                取得（一括）
              </MuiButton>
            </Stack>
          </Stack>

          <Divider />

          <FeedGrid
            apiRef={gridApiRef}
            filterCondition={filterCondition}
            toolbarProps={{
              hasTradeState: { hasTrade, setHasTrade },
              handleSearch,
            }}
            setSingleBookAcquireDialogStatus={setSingleBookAcquireDialogStatus}
            setSingleBookAcquireDialogData={setSingleBookAcquireDialogData}
            setMultipleBookAcquireData={setMultipleBookAcquireData}
          />

          <Divider />

          <Box sx={{ mb: 6 }} />
        </SectionPaper>
      </PagePaper>
    </>
  );
};

export { RealEstateReceptionBookFeedPresenter };
