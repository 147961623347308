import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";

export interface NewsSummary {
  id: number;
  title: string;
  category: string;
  publishedAt: string;
  pinned: boolean;
}

export interface NewsSummaryResponse {
  count: number;
  news: NewsSummary[];
}

export interface NewsDetailResponse {
  id: number;
  title: string;
  category: string;
  publishedAt: string;
  tags: string[];
  detail: string;
  updatedAt: string;
}

export const NewsSummaryAPI = (year: number): NewsSummaryResponse => {
  const { apiClient } = useApiClient();
  const params = {
    year,
  };
  const url = "/news/";

  const fetcher = async (): Promise<NewsSummaryResponse> => {
    return (await apiClient.get<NewsSummaryResponse>(url, { params })).data;
  };
  const { data: newsSummaries } = useSWR({ url, params }, fetcher, {
    revalidateOnFocus: false,
  });

  return newsSummaries ?? { count: 0, news: [] };
};

export const NewsDetailAPI = (id: string): NewsDetailResponse[] => {
  const { apiClient } = useApiClient();
  const params = {
    id,
  };
  const url = "/news";

  const fetcher = async (): Promise<NewsDetailResponse[]> => {
    return (await apiClient.get<NewsDetailResponse[]>(`${url}/${params.id}`))
      .data;
  };
  const { data: newsDetails } = useSWR({ url, params }, fetcher, {
    revalidateOnFocus: false,
  });

  return newsDetails ?? [];
};
