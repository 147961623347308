import React from "react";
import { Box, Typography } from "@mui/material";

export const Maintenance: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f4f4f4",
        textAlign: "center",
        padding: 2,
      }}
    >
      <Typography variant="h2" sx={{ marginBottom: 2, color: "#333" }}>
        🙇‍♂️
      </Typography>
      <Typography variant="h2" sx={{ marginBottom: 6, color: "#333" }}>
        しばらくお待ちください
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 4, color: "#666" }}>
        現在、R.E.DATA Plusはメンテナンス作業を行なっております。
        <br />
        作業完了し次第、再度ご利用いただけます。ご協力のほど、よろしくお願いいたします。
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} TRUSTART, Inc. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
