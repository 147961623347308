import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Button, ButtonVariantOption } from "../../../components/Button";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { useState } from "react";
import { FileUploadButton } from "@/features/realEstateReceptionBookFeed/components/FileUploadButton";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import {
  getSearchMaxDate,
  getSearchMinDate,
  getStartAndEndDate,
} from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { readString } from "react-papaparse";
import { type ParseResult } from "papaparse";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useFeatureFlags } from "@/configs/featureFlag";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { CDateRangePicker } from "@/components/DatePicker/CDateRangePicker";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { CMultiSelect } from "@/components/Select/CMultiSelect";
import { CCheckbox } from "@/components/Checkbox/CCheckbox";

/**
 * CSVファイルの読み込み処理
 * @param file
 * @return CSVパース結果オブジェクトが返される
 */
const readCSV = async (file: File): Promise<ParseResult<unknown>> =>
  await new Promise<ParseResult<string>>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("error", () => {
      reject(reader.error);
    });
    reader.addEventListener("load", () => {
      const { result } = reader;
      if (typeof result !== "string") {
        reject(reader.error);
      }
      readString<string>(result as string, {
        worker: true,
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results);
        },
        error(error: Error) {
          reject(error);
        },
      });
    });
    reader.readAsText(file);
  });

/**
 * 受けとったCSVファイルの要素の件数が指定された件数を超えているか判定する
 * @param content papaparseのCSVパース結果オブジェクト
 * @param count 最大値
 * @return 最大値を超えているならtrus、それ以外はfalse
 */
const isLineCountExceeded = (
  content: ParseResult<unknown>,
  count: number
): boolean => {
  return content.data.length > count;
};

/**
 * CSVファイルから重複行を取り除く
 * 重複取り除きルールは「不動産種別、都道府県、所在、地番または家屋番号」が全て一致している行
 * 重複があった場合、最初の要素のみ残し、後の要素は削除する
 * @param content papaparseのCSVパース結果オブジェクト
 * @return contentから重複行を取り除いたオブジェクト
 */
const distinctCSV = (content: ParseResult<unknown>): ParseResult<unknown> => {
  const newData: Array<Record<string, string>> = [];

  const getKey = (input: Record<string, string>): string => {
    return (
      input["不動産種別"] +
      input["都道府県"] +
      input["所在"] +
      input["地番または家屋番号"]
    );
  };

  content.data.forEach((value) => {
    const key = getKey(value as Record<string, string>);
    if (newData.some((value) => getKey(value) === key)) {
      return;
    }
    newData.push(value as Record<string, string>);
  });
  content.data = newData;
  return content;
};

export const MonitoringUpload: React.FC = () => {
  const { realEstateRegistrationMonitoring } = useFeatureFlags();

  // アップロード対象のファイルオブジェクト
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  // 1000件越えエラーメッセージのためのState
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  // 料金がかかる旨を通知するためのState
  const [fileNoticeMessage, setFileNoticeMessage] = useState<string>("");

  // 実行ボタン無効化用State
  const [executeButtonDisabled, setExecuteButtonDisabled] =
    useState<boolean>(true);

  // 登記要因セレクト項目
  const { receptionReasonOptions } = useReceptionReasonOptions();

  // 登録日開始、終了の初期値
  const [startDate, endDate] = getStartAndEndDate();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  // React-Hook-Form用スキーマ定義
  const UploadFormDataSchema = z.object({
    legalAffairsBureauRequestDateRange: z.tuple([z.date(), z.date()]),
    oneShot: z.boolean(),
    receptionReasons: z.array(z.string()).min(1, "登記原因を選択してください"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type UploadFormData = z.infer<typeof UploadFormDataSchema>;

  const reactHookForm = useForm<UploadFormData>({
    mode: "all",
    defaultValues: {
      legalAffairsBureauRequestDateRange: [startDate, endDate],
      oneShot: false,
      receptionReasons: [],
    },
    resolver: zodResolver(UploadFormDataSchema),
  });

  // 送信処理
  const submit = reactHookForm.handleSubmit(
    (data) => {
      console.log("valid", data);
    },
    (errors) => {
      console.error(errors);
    }
  );

  // モニタリング機能無効の場合は何も表示しない
  if (!realEstateRegistrationMonitoring) return null;

  /**
   * ファイル選択時の処理
   * - エラーメッセージ削除
   * - CSVファイルの読み込み
   * - CSVファイル1000件制限の確認
   * - CSVファイル重複項目の削除
   * - エラーの場合はメッセージ表示
   * - 成功の場合は実行ボタンの有効化
   * @param event
   * @return void
   */
  const handleFileSelected = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFileErrorMessage("");
    setFileNoticeMessage("");
    setExecuteButtonDisabled(true);
    setFileToUpload(null);

    // ファイルが選択されているときのみ確認画面へボタンを有効にする
    const files = event.target.files ?? [];
    if (files.length > 0) {
      const csv = await readCSV(files[0]);
      if (isLineCountExceeded(csv, 1000)) {
        console.error("over 1000");
        setFileErrorMessage(
          `CSVファイルの項目数は1000以下にしてください。現在の項目数: ${csv.data.length}件`
        );
        return;
      }
      const resultCsv = distinctCSV(csv);
      console.log("raw", csv.data);
      console.log("distincted", resultCsv);
      setFileNoticeMessage(
        `注意: 実行すると件数×10円の費用が毎月かかります。現在の項目数:${resultCsv.data.length}件`
      );
      setExecuteButtonDisabled(false);
      setFileToUpload(files[0]);
    }
  };

  return (
    <PagePaper>
      <form>
        {/* ページタイトル */}
        <Box sx={{ display: "flex", mb: 0 }}>
          <SavedSearchIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産データアップロード</PageTitle>
        </Box>
        <Typography sx={{ ml: 5, mb: 3 }}>
          <Link href={"/assets/R.E.DATAモニタリングサンプル.csv"}>
            見本CSVをダウンロード
          </Link>
        </Typography>
        <SectionPaper>
          <Box sx={{ m: 2 }}>
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "2px dashed",
                borderRadius: "2px",
                borderColor: "black",
                mb: 2,
              }}
            >
              <Grid container spacing={2} sx={{ my: 5 }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography sx={{ lineHeight: 2 }}>
                    アップロードボタンを押してファイルを選択してください
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FileUploadButton
                      variant={ButtonVariantOption.Contained}
                      onChange={(event) => {
                        (async (): Promise<void> => {
                          await handleFileSelected(event);
                        })();
                      }}
                      accept={".csv"}
                      label={"アップロード"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign={"center"}>
                    {fileToUpload ? `ファイル名:${fileToUpload?.name}` : ""}
                  </Typography>
                </Grid>
                {fileErrorMessage !== "" ? (
                  <Grid item xs={12}>
                    <Typography textAlign={"center"} color={"red"}>
                      {fileErrorMessage}
                    </Typography>
                  </Grid>
                ) : null}
                {fileNoticeMessage !== "" ? (
                  <Grid item xs={12}>
                    <Typography textAlign={"center"} color={"red"}>
                      {fileNoticeMessage}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Container>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={4}>
                <Box sx={{ my: 0 }}>
                  <Typography my={1}>モニタリング対象にする登記原因</Typography>
                  <CMultiSelect<UploadFormData>
                    name={"receptionReasons"}
                    control={reactHookForm.control}
                    label="登記原因"
                    options={receptionReasonOptions}
                    maxSelect={receptionReasonOptions.length}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box my={1}>
                  <CCheckbox
                    control={reactHookForm.control}
                    name={"oneShot"}
                    label="単発での実行（月次モニタリングは実施しない）"
                  />
                </Box>

                <Box sx={{ display: "flex", my: 1 }}>
                  <CDateRangePicker<UploadFormData>
                    name={"legalAffairsBureauRequestDateRange"}
                    control={reactHookForm.control}
                    startDateLabel="登録日 開始"
                    endDateLabel="登録日 終了"
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultCalendarMonth={startDate}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    label={"実行する"}
                    disabled={executeButtonDisabled}
                    onClick={() => {
                      (async () => {
                        await submit();
                      })();
                      console.log("ファイル", fileToUpload);
                    }}
                    variant={ButtonVariantOption.Contained}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link href={"/monitoring"} sx={{ display: "inline-block" }}>
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>不動産登記モニタリング画面に戻る</Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
