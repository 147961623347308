import { useApiClient } from "@/hooks/useApiClient";
import useSWRMutation, { type SWRMutationResponse } from "swr/mutation";

export interface CreatePinHistoryResponse {
  id: number;
  mapDetailHistoryResults: [
    {
      book_id: number;
      errors: string | null;
      status: string;
    }
  ];
}

/**
 * ピン情報保存API
 * https://github.com/alan2207/bulletproof-react/blob/master/src/features/comments/api/createComment.ts
 * 上記URLを参考にswrを使ったものに書き換えた
 */
export const CreatePinHistoryAPI = (): SWRMutationResponse<
  CreatePinHistoryResponse,
  never,
  number[]
> => {
  const { apiClient } = useApiClient();
  const url = "/map/map-history";

  const fetcher = async (
    url: string,
    { arg }: { arg: number[] }
  ): Promise<CreatePinHistoryResponse> => {
    return (await apiClient.post<CreatePinHistoryResponse>(url, arg)).data;
  };
  return useSWRMutation(url, fetcher, { revalidate: false });
};
