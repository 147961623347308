import React from "react";

interface ValidateItem<T> {
  state: ValidateState;
  setState: React.Dispatch<React.SetStateAction<ValidateState>>;
  validate: (data: T) => boolean;
}
interface ValidateState {
  helperTextWhenError: string;
  error: boolean;
}

interface Validator {
  checkOptions: ValidateItem<Record<string, Record<string, boolean>>>;
}

const useValidator = (): Validator => {
  const [stateCheckOptions, setStateCheckOptions] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });

  const checkOptions: ValidateItem<Record<string, Record<string, boolean>>> = {
    state: stateCheckOptions,
    setState: setStateCheckOptions,
    validate: (data: Record<string, Record<string, boolean>>): boolean => {
      // dataの中に一つでもチェックが入っているものがあればtrueとする
      const result = Object.keys(data).some((key) => data[key].checked);
      if (result) {
        setStateCheckOptions({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateCheckOptions({
          error: true,
          helperTextWhenError: "取得したい登記/図面を選択してください",
        });
        return false;
      }
    },
  };

  return {
    checkOptions,
  };
};

export { useValidator };
