import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
} from "@mui/material";
import { SizingWrapper, type ISizingWrapperProps } from "../Wrapper";

type OmitKeys = "id" | "type" | "label" | "value" | "size" | "onChange";

interface IProps
  extends ISizingWrapperProps,
    Omit<OutlinedInputProps, OmitKeys> {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const TextFieldEmail: React.FC<IProps> = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    size,
    required = true,
    ...otherProps
  } = props;

  return (
    <SizingWrapper size={size}>
      <FormControl fullWidth required={required}>
        <InputLabel htmlFor="outlined-adornment-email">{label}</InputLabel>
        <OutlinedInput
          id={id}
          type="email"
          label={label}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
          {...otherProps}
        />
      </FormControl>
    </SizingWrapper>
  );
};

export { TextFieldEmail };
