import { styled } from "@mui/material/styles";
import { CustomerManagementListDataGrid } from "../../../../components/DataGrid";
import { type GridColDef } from "@mui/x-data-grid";
import React from "react";
import {
  type MailTemplateListAPIResponse,
  useMailTemplateListRequest,
} from "../../hooks/useMailTemplateListRequest";

const StyledDataGrid = styled(CustomerManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  // マウスホバー時にカーソルをポインターに変更
  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }
`;

const makeGridColDef = (
  data: MailTemplateListAPIResponse,
  onSelectTemplate: (id: number) => void
): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: "テンプレート名",
      sortable: false,
      align: "left",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "更新日",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];
};
interface Props {
  onSelectTemplate: (id: number) => void;
}

export const MailTemplateListTable: React.FC<Props> = ({
  onSelectTemplate,
}) => {
  const { data } = useMailTemplateListRequest();
  const colDef = makeGridColDef(data, onSelectTemplate);

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={data}
      columns={colDef}
      sortModel={[
        {
          field: "updatedAt",
          sort: "desc",
        },
      ]}
      onRowClick={(params) => {
        onSelectTemplate(Number(params.id));
      }}
    />
  );
};
