import { Button, Stack, styled } from "@mui/material";
import {
  type GridSelectionModel,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { type MonitoringDataGridRow } from "../types";
import { DataGrid } from "./DataGrid";
import { type NavigateFunction } from "react-router-dom";

interface IProps {
  handleRowSelectionChange: (newSelectionModel: GridSelectionModel) => void;
  navigate: NavigateFunction;
  rows: MonitoringDataGridRow[];
  setSelectedIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>;

  setAcquireBookOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledDataGrid = styled(DataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (
  navigate: NavigateFunction,
  setSelectedIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>,
  setAcquireBookOpen: React.Dispatch<React.SetStateAction<boolean>>
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "No",
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "realEstateTypeJpn",
      headerName: "不動産種別",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "所在",
      width: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chibanKaokuNumber",
      headerName: "地番または家屋番号",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "latestReceptionDate",
      headerName: "最新登記年月日",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value != null) return format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      field: "soto",
      headerName: "外",
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remarks",
      headerName: "備考",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableExport: true,
      renderCell: ({
        row,
      }: GridRenderCellParams<string, MonitoringDataGridRow>) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              onClick={() => {
                navigate(`/monitoring/detail/${row.id}`);
              }}
              sx={{ bgcolor: "#DF9B4A" }}
              variant="contained"
            >
              受付帳結果
            </Button>
            <Button
              onClick={() => {
                setSelectedIds([row.id]);
                setAcquireBookOpen(true);
              }}
              variant="contained"
            >
              不動産登記取得
            </Button>
          </Stack>
        );
      },
    },
  ];
};

/**
 * 不動産登記モニタリングの表部分
 * @param {IProps} props
 * @returns
 */
export const MonitoringDetailDataGrid: React.FC<IProps> = (props: IProps) => {
  const {
    handleRowSelectionChange,
    navigate,
    rows,
    setSelectedIds,
    setAcquireBookOpen,
  } = props;
  const colDef = makeGridColDef(navigate, setSelectedIds, setAcquireBookOpen);

  return (
    <StyledDataGrid
      autoHeight
      checkboxSelection
      columns={colDef}
      disableColumnMenu
      disableSelectionOnClick={true}
      onSelectionModelChange={handleRowSelectionChange}
      rows={rows}
      sortModel={[
        {
          field: "latestReceptionDate",
          sort: "desc",
        },
      ]}
    />
  );
};
