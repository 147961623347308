import React from "react";
import { Container, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { MailTemplateList } from "../components/MailTemplate/List";
import { MailTemplateNew } from "../components/MailTemplate/New";
import { MailTemplateEdit } from "../components/MailTemplate/Edit";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

export const MailTemplate: React.FC = () => {
  return (
    <Grid container spacing={0} sx={{ marginTop: "10px" }}>
      <Grid item xs={1.5}>
        <Container sx={{ textAlign: "center", padding: "20px" }}>
          <LinkButton
            variant={ButtonVariantOption.Contained}
            size="large"
            href={"new"}
            wrapperSize={SizingWrapperStyle.INHERIT}
          >
            作成
          </LinkButton>
        </Container>
      </Grid>
      <Grid item xs={10.5}>
        <Container
          sx={{
            padding: "20px",
            border: "1px solid #E0E0E0",
            boxShadow:
              "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
            borderRadius: "4px",
            minHeight: "650px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
          maxWidth={false}
        >
          <Routes>
            <Route path="list" element={<MailTemplateList />} />
            <Route path="new" element={<MailTemplateNew />} />
            <Route path="edit/:id" element={<MailTemplateEdit />} />
            <Route path="*" element={<MailTemplateList />} />
          </Routes>
        </Container>
      </Grid>
    </Grid>
  );
};
