import { useApiClient } from "@/hooks/useApiClient";
import useSWRMutation, { type SWRMutationResponse } from "swr/mutation";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

type LatLngAddressTuple = [number, number, string];

/**
 * 住居表示から該当する座標、住居表示をタプルで取得する
 * 使い方：
 *       const getLatLngByAddressAPI = GetLatLngByAddressAPI();
 *       const request = {"address": address};
 *       const result = await getLatLngByAddressAPI.trigger(request);
 *       if(result) {
 *         console.log(`lat:${result[0]}, lng:${result[1]} address:${result[2]}`)
 *       }
 */
export const GetLatLngByAddressAPI = (): SWRMutationResponse<
  LatLngAddressTuple | undefined,
  never,
  Record<"address", string>
> => {
  const { apiClient } = useApiClient();
  const url = "/geocode/coordinates-by-address";

  const fetcher = async (
    url: string,
    { arg }: { arg: Record<"address", string> }
  ): Promise<LatLngAddressTuple | undefined> => {
    try {
      const result = await apiClient.get<LatLngAddressTuple>(
        `${url}?address=${arg.address}`
      );
      return result.data;
    } catch (error) {
      if (isAxiosError<LatLngAddressTuple>(error)) {
        if (error.response?.status !== HttpStatusCode.Unauthorized) {
          Sentry.captureException(error);
          return undefined;
        }
      }
      return undefined;
    }
  };
  return useSWRMutation(url, fetcher, { revalidate: false });
};
