import { useApiClient } from "@/hooks/useApiClient";
import { type AxiosError } from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { type MailDetail } from "../api/types";
import {
  getMailDetailAPIUrl,
  getMailDetailAPIfetcher,
} from "../api/GetMailDetailAPI";

export const useMailDetailAPI = (
  mailId: number | null
): {
  data: MailDetail | undefined;
  refetch: () => Promise<void>;
} => {
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  const fetcher = getMailDetailAPIfetcher(apiClient);

  const { data, error } = useSWR<MailDetail, AxiosError>(
    mailId ? getMailDetailAPIUrl(mailId) : null, // templateIdがnullの場合はリクエストを行わない
    fetcher,
    {
      revalidateOnFocus: true,
    }
  );

  const refetch = async (): Promise<void> => {
    await mutate(mailId ? getMailDetailAPIUrl(mailId) : null);
  };

  React.useEffect(() => {
    if (error?.response?.status && error.response.status >= 500) {
      toast.error("メールの取得に失敗しました。");
      navigate(`/emailManagement/mail/savedEmailList`);
    }
  }, [error, navigate]);

  return { data, refetch };
};
