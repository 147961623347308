import { styled } from "@mui/material/styles";
import { DataGrid } from "../../../components/DataGrid";
import { useGridFeed } from "../hooks/useGridFeed";
import { useGridPagination } from "../hooks/useGridPagination";
import {
  freeUserRealEstateReceptionBookDataGridColumns,
  makeRealEstateReceptionBookDataGridColumns,
} from "../utils";
import { CustomToolbar } from "./CustomToolbar";
import {
  type IFilterCondition,
  type IMultipleBookAcquireData,
  type ISingleBookAcquireDialogData,
} from "../types";
import React from "react";
import { type GridApiCommunity } from "@mui/x-data-grid/models/api/gridApiCommunity";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { type User } from "@/types/localstorage";

interface IProps {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  filterCondition: IFilterCondition;
  toolbarProps: {
    hasTradeState: {
      setHasTrade: React.Dispatch<React.SetStateAction<boolean>>;
      hasTrade: boolean;
    };
    handleSearch: () => void;
  };
  setSingleBookAcquireDialogStatus: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSingleBookAcquireDialogData: React.Dispatch<
    React.SetStateAction<ISingleBookAcquireDialogData>
  >;
  setMultipleBookAcquireData: React.Dispatch<
    React.SetStateAction<IMultipleBookAcquireData>
  >;
}

const StyledDataGrid = styled(DataGrid)`
  border: 0;

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  // ページネーションの件数表示を太字に
  .MuiTablePagination-displayedRows {
    font-weight: 700;
  }

  .MuiTablePagination-selectIcon {
    color: #000;
  }
`;

export const FeedGrid: React.FC<IProps> = (props) => {
  const {
    filterCondition,
    toolbarProps,
    setSingleBookAcquireDialogStatus,
    setSingleBookAcquireDialogData,
    setMultipleBookAcquireData,
  } = props;
  const { page, pageSize, changePage, changePageSize } = useGridPagination();
  const { rows, allCount, loading, sortByGridSortModel, selectionModelChange } =
    useGridFeed({
      page,
      pageSize,
      setMultipleBookAcquireData,
      ...filterCondition,
    });

  const [user] = useLocalStorage<User | undefined>("user", undefined);
  const rowsPerPageOptions: number[] = [10, 50, 100];

  return (
    <StyledDataGrid
      // apiRef={apiRef}
      // 方向性が決まるまでフィルタ機能は無効化
      // https://www.notion.so/trustart/1cb84cb89e4340fe8458d1f34146dc47?pvs=4#2e58a1a7e0654761bbb60c11f32237f5
      disableColumnFilter
      checkboxSelection
      keepNonExistentRowsSelected
      rows={rows}
      columns={
        !user?.isFree
          ? makeRealEstateReceptionBookDataGridColumns(
              setSingleBookAcquireDialogStatus,
              setSingleBookAcquireDialogData,
              !toolbarProps.hasTradeState.hasTrade
            )
          : freeUserRealEstateReceptionBookDataGridColumns(
              setSingleBookAcquireDialogStatus,
              setSingleBookAcquireDialogData,
              !toolbarProps.hasTradeState.hasTrade
            )
      }
      initialState={{
        columns: {
          columnVisibilityModel: {
            // Hide columns, the other columns will remain visible
            id: false, // Only CSV
            receptionReasonType: false, // Only CSV
            volumeRate: false, // Only CSV
            hasTrade: false, // 売買スクリーニング列はデフォルトで非表示
          },
        },
      }}
      rowCount={allCount}
      pageSize={pageSize}
      page={page}
      loading={loading}
      sortingMode="server"
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={changePage}
      onPageSizeChange={changePageSize}
      onSortModelChange={sortByGridSortModel}
      onSelectionModelChange={selectionModelChange}
      disableSelectionOnClick={true}
      headerHeight={100}
      getRowHeight={() => "auto" as const}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: {
          disabled: loading ? true : allCount >= 1000,
          hasTradeState: toolbarProps.hasTradeState,
          handleSearch: toolbarProps.handleSearch,
          user,
          filterCondition,
          page,
          pageSize,
          allCount,
          rowsPerPageOptions,
          changePage,
          changePageSize,
        },
      }}
    />
  );
};
