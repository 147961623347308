import { createRoot } from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { initMocks } from "./test/mocks";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { launchDarklyClientId } from "./configs/featureFlag";
import { initSentry } from "./configs/sentry";

// HACK: mockで起動時に初回のAPIがエラーになる
// top-level awaitにすることで解消するが、その場合npm run buildに失敗する
// viteのビルドオプションを変えればこれも解決するが、動作するブラウザのバージョンが限定されてしまう
// 2024/7 上記問題があったが解消済み
(async () => {
  // Mockする/しないの判断は関数内で行われるのでそのまま実行でOK
  await initMocks();
  // Mock動作の時はsentryは不要
  if (process.env.VITE_ENABLE_MOCK_SERVER !== "true") {
    initSentry();
  }

  const FeatureFlagProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
  });

  const rootElement = document.getElementById("root");
  if (rootElement == null) throw new Error("Failed to find the root element");
  const root = createRoot(rootElement);

  root.render(
    <FeatureFlagProvider>
      <BrowserRouter>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </BrowserRouter>
    </FeatureFlagProvider>
  );
})();
