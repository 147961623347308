import { useApiClient } from "../../../hooks/useApiClient";
import useSWR from "swr";
import {
  type IStationProviderWithLineObject,
  type IStationProviderWithLineResponse,
} from "../types";

interface IUseStationLineOptionsReturnType {
  stationLineOptions: IStationProviderWithLineObject[];
}

export const useStationLineOptions = (): IUseStationLineOptionsReturnType => {
  const { apiClient } = useApiClient();

  // swrに引き渡すfetcher
  const fetcher = async (
    url: string
  ): Promise<IStationProviderWithLineResponse> => {
    const { data } = await apiClient.get<IStationProviderWithLineResponse>(url);
    return data;
  };

  const { data } = useSWR<IStationProviderWithLineResponse>(
    "/option/station-line-with-provider",
    fetcher,
    { revalidateOnFocus: false }
  );

  if (data === undefined) {
    return { stationLineOptions: [] };
  }

  return {
    stationLineOptions: data.list,
  };
};
