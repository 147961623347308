import L from "leaflet";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMap } from "react-leaflet";

interface Props {
  position: L.ControlPosition;
  children?: React.ReactNode;
  container?: React.HTMLAttributes<HTMLDivElement>;
  prepend?: boolean;
}

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

/*
Leafletにカスタムコントロールを追加するためのコンポーネント
参考: https://github.com/chris-m92/react-leaflet-custom-control
 */
export const CustomControl = ({
  position,
  children,
  container,
  prepend = false,
}: Props): JSX.Element => {
  // ポータルのルート要素
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  const controlContainerRef = useRef<HTMLDivElement>(
    document.createElement("div")
  );

  // Leafletのマップオブジェクト
  const map = useMap();

  // position
  const positionClass = useMemo(
    () => POSITION_CLASSES[position] || POSITION_CLASSES.topright,
    [position]
  );

  // コンテナ上のクリックとスクロールの伝播を無効化
  useEffect(() => {
    if (controlContainerRef.current) {
      L.DomEvent.disableClickPropagation(controlContainerRef.current);
      L.DomEvent.disableScrollPropagation(controlContainerRef.current);
    }
  }, []);

  // positionの設定
  useEffect(() => {
    const mapContainer = map.getContainer();
    const targetDiv = mapContainer.getElementsByClassName(positionClass)[0];
    if (targetDiv instanceof HTMLElement) {
      setPortalRoot(targetDiv);
    }
  }, [positionClass, map]);

  useEffect(() => {
    if (portalRoot) {
      if (prepend) {
        portalRoot.prepend(controlContainerRef.current);
      } else {
        portalRoot.append(controlContainerRef.current);
      }
    }
  }, [portalRoot, prepend]);

  const className = `${container?.className ?? ""} leaflet-control`.trim();

  return (
    <div {...container} ref={controlContainerRef} className={className}>
      {children}
    </div>
  );
};
