import { Button, ButtonVariantOption } from "@/components/Button";
import { Checkbox, CheckboxGroup } from "@/components/Checkbox";
import { DatePicker } from "@/components/DatePicker";
import { MultiSelect } from "@/components/Select";
import { RowSpacingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import type {
  DestinationConditions,
  DestinationConditionsProps,
} from "@/features/customerManagement/types";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import {
  Box,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

export const CustomerListDestinationCondition: React.FC<
  DestinationConditionsProps
> = ({ destinationConditions, handleFiltering, setDestinationConditions }) => {
  const { receptionReasonOptions } = useReceptionReasonOptions();

  // チェックボックスの状態を管理する関数を集約
  const handleCheckboxChange = (
    condition: keyof DestinationConditions
  ): void => {
    setDestinationConditions((prev) => ({
      ...prev,
      [condition]: !prev[condition],
    }));
  };

  return (
    <>
      <Box sx={{ display: "grid", mx: 2, mb: 1, gap: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* 登記原因 */}
            <Stack direction={"row"}>
              <MultiSelect
                label="登記原因"
                onChange={(event) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    receptionReason: event,
                  }));
                }}
                options={receptionReasonOptions}
                size={SizingWrapperStyle.LARGE}
                value={destinationConditions.receptionReason}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-reception-reasons"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeReceptionReason:
                      event.target.value === "true" ?? false,
                  }));
                }}
                value={
                  destinationConditions.includeReceptionReason
                    ? "true"
                    : "false"
                }
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {/* 持分 */}
            <Stack direction={"row"}>
              <TextField
                fullWidth
                id="mochibun"
                label="持分"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    mochibun: event.target.value,
                  }));
                }}
                type="text"
                value={destinationConditions.mochibun}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-mochibun"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeMochibun: event.target.value === "true" ?? false,
                  }));
                }}
                value={destinationConditions.includeMochibun ? "true" : "false"}
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {/* 登記申請年月 */}
            <Stack direction={"row"}>
              <DatePicker
                inputFormat="yyyy/MM"
                label="登記申請年月"
                onChange={(event) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    legalAffairsBureauRequestDate: event,
                  }));
                }}
                openTo="month"
                size={SizingWrapperStyle.LARGE}
                value={destinationConditions.legalAffairsBureauRequestDate}
                views={["month", "year"]}
              />

              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-legal-affairs-bureau-request-date"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeLegalAffairsBureauRequestDate:
                      event.target.value === "true" ?? false,
                  }));
                }}
                value={
                  destinationConditions.includeLegalAffairsBureauRequestDate
                    ? "true"
                    : "false"
                }
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* 郵便番号 */}
            <Stack direction={"row"}>
              <TextField
                fullWidth
                id="postcode"
                label="郵便番号"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    postcode: event.target.value,
                  }));
                }}
                type="text"
                value={destinationConditions.postcode}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-postcode"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includePostcode: event.target.value === "true" ?? false,
                  }));
                }}
                value={destinationConditions.includePostcode ? "true" : "false"}
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {/* メールアドレス */}
            <Stack direction={"row"}>
              <TextField
                fullWidth
                id="email"
                label="メールアドレス"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    email: event.target.value,
                  }));
                }}
                type="email"
                value={destinationConditions.email}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-email"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeEmail: event.target.value === "true" ?? false,
                  }));
                }}
                value={destinationConditions.includeEmail ? "true" : "false"}
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {/* 物件情報 */}
            <Stack direction={"row"}>
              <TextField
                fullWidth
                id="original-shozai"
                label="物件情報"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    originalShozai: event.target.value,
                  }));
                }}
                type="text"
                value={destinationConditions.originalShozai}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-original-shozai"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeOriginalShozai:
                      event.target.value === "true" ?? false,
                  }));
                }}
                value={
                  destinationConditions.includeOriginalShozai ? "true" : "false"
                }
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>

        {/* 不動産種別 */}
        <Stack direction={"row"}>
          <CheckboxGroup label="不動産種別">
            <RowSpacingWrapper>
              <Checkbox
                label="土地"
                checked={destinationConditions.realEstateTypeTochi}
                onChange={(checked: boolean) => {
                  handleCheckboxChange("realEstateTypeTochi");
                }}
              />
              <Checkbox
                label="区分建物"
                checked={destinationConditions.realEstateTypeKutate}
                onChange={(checked: boolean) => {
                  handleCheckboxChange("realEstateTypeKutate");
                }}
              />
              <Checkbox
                label="建物"
                checked={destinationConditions.realEstateTypeTatemono}
                onChange={(checked: boolean) => {
                  handleCheckboxChange("realEstateTypeTatemono");
                }}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-real-estate-type"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeRealEstateType:
                      event.target.value === "true" ?? false,
                  }));
                }}
                value={
                  destinationConditions.includeRealEstateType ? "true" : "false"
                }
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </RowSpacingWrapper>
          </CheckboxGroup>
        </Stack>

        {/* ステータス */}

        <Stack direction={"row"}>
          <CheckboxGroup label="ステータス">
            <RowSpacingWrapper>
              <Checkbox
                label="未営業"
                checked={destinationConditions.statusNoApproach}
                onChange={() => {
                  handleCheckboxChange("statusNoApproach");
                }}
              />
              <Checkbox
                label="営業中"
                checked={destinationConditions.statusApproach}
                onChange={() => {
                  handleCheckboxChange("statusApproach");
                }}
              />
              <Checkbox
                label="お断り"
                checked={destinationConditions.statusRejected}
                onChange={() => {
                  handleCheckboxChange("statusRejected");
                }}
              />
              <Checkbox
                label="レスなし"
                checked={destinationConditions.statusNoResponse}
                onChange={() => {
                  handleCheckboxChange("statusNoResponse");
                }}
              />
              <Checkbox
                label="クレームありNG"
                checked={destinationConditions.statusComplaint}
                onChange={() => {
                  handleCheckboxChange("statusComplaint");
                }}
              />
              <Checkbox
                label="送付済み"
                checked={destinationConditions.statusSent}
                onChange={() => {
                  handleCheckboxChange("statusSent");
                }}
              />
              <Checkbox
                label="反響あり"
                checked={destinationConditions.statusReaction}
                onChange={() => {
                  handleCheckboxChange("statusReaction");
                }}
              />
              <Typography my={2} mx={1}>
                を
              </Typography>
              <Select
                id="include-real-estate-type"
                onChange={(event: SelectChangeEvent<string>) => {
                  setDestinationConditions((prev) => ({
                    ...prev,
                    includeStatus: event.target.value === "true" ?? false,
                  }));
                }}
                value={destinationConditions.includeStatus ? "true" : "false"}
              >
                <MenuItem value="true">含む</MenuItem>
                <MenuItem value="false">含まない</MenuItem>
              </Select>
            </RowSpacingWrapper>
          </CheckboxGroup>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label="絞り込み"
          onClick={handleFiltering}
          variant={ButtonVariantOption.Outlined}
          size={SizingWrapperStyle.SMALL}
        />
      </Box>
    </>
  );
};
