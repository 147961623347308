import { useState, useEffect } from "react";

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void, () => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item !== null ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    try {
      setStoredValue(value);
      if (!value) {
        localStorage.removeItem(key);
        return;
      }
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!storedValue) {
      localStorage.removeItem(key);
      return;
    }
    localStorage.setItem(key, JSON.stringify(storedValue));
  }, [storedValue]);

  const removeValue = (): void => {
    localStorage.removeItem(key);
  };

  return [storedValue, setValue, removeValue];
};
