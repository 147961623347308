import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "@/components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";
import { Stack } from "@mui/material";
import { type RequestDetailAndRealEstates } from "@/features/ownerInfo/types";
import CircularProgress from "@mui/material/CircularProgress";
import React, {
  forwardRef,
  type ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import type { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import type { GridCallbackDetails } from "@mui/x-data-grid/models/api";

interface OwnerInfoListGridRow extends RequestDetailAndRealEstates {
  action?: boolean;
}

interface OwnerInfoListAPIResponse {
  count: number;
  list: OwnerInfoListGridRow[];
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "No",
      width: 60,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "count",
      headerName: "件数",
      width: 80,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "realEstateType",
      headerName: "登記原因",
      width: 240,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 480,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({
        id,
        row,
      }: GridRenderCellParams<string, OwnerInfoListGridRow>) => {
        return (
          <Stack direction="row" spacing={2}>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              href={`/monitoring/${id}/`}
            >
              詳細
            </LinkButton>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              href={`/monitoring/${id}/`}
            >
              所有者事項解析
            </LinkButton>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              href={`/monitoring/${id}/`}
            >
              不動産登記取得
            </LinkButton>
          </Stack>
        );
      },
    },
  ];
};

/**
 * _FeedGrid
 * reload関数を親コンポーネントに外部公開するために、関数名にはアンダースコアをつけている
 * @param _props
 * @param ref
 */
const _FeedGrid: ForwardRefRenderFunction<{ reload: () => void }> = (
  _props,
  ref
) => {
  let rows: OwnerInfoListGridRow[] = [];
  const { apiClient } = useApiClient();
  const colDef = makeGridColDef();

  const fetcher = async (url: string): Promise<OwnerInfoListAPIResponse> => {
    const { data } = await apiClient.get<OwnerInfoListAPIResponse>(url);
    return data;
  };

  const { data, isLoading, mutate } = useSWR<OwnerInfoListAPIResponse>(
    "/monitoring/",
    fetcher,
    { revalidateOnFocus: true, errorRetryInterval: 500 }
  );

  // refを使って外部公開する関数を定義する
  useImperativeHandle(
    ref,
    () => {
      return {
        // mutate()を使って再読み込みを行う
        async reload() {
          await mutate().then((r) => {
            if (r) rows = r.list;
          });
        },
      };
    },
    []
  );

  if (isLoading || data === undefined) {
    return (
      <Stack alignItems="center">
        <CircularProgress sx={{ my: "20px" }} />
      </Stack>
    );
  }

  rows = data.list;

  const selectionModelChange = (
    selectionModel: GridSelectionModel,
    _details: GridCallbackDetails
  ): void => {
    console.log("Selected IDs:", selectionModel);
  };

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      checkboxSelection
      rows={rows}
      columns={colDef}
      onSelectionModelChange={selectionModelChange}
      sortModel={[
        {
          field: "id",
          sort: "asc",
        },
      ]}
    />
  );
};

/**
 * FeedGrid
 * forwardRefを使ってreload()を外部から呼び出せるようにしている
 */
export const MonitoringFeedGrid = forwardRef<{ reload: () => void }>(_FeedGrid);
