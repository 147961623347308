import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { EmailListFeedGrid } from "../components/EmailListFeedGrid";
import { ScheduleCancelledButtonSection } from "../components/ButtonSection";
import type { GridSelectionModel } from "@mui/x-data-grid";
import { FilteringSection } from "../components/FilteringSection";
import { type EmailListDataGridRow } from "../types";
import { useApiClient } from "@/hooks/useApiClient";
import { GetMailListAPI } from "../api";
import MailPageTemplate from "../components/MailPageTemplate";
import EmailIcon from "@mui/icons-material/Email";

export const ScheduledEmailList: React.FC = () => {
  const [rows, setRows] = useState<EmailListDataGridRow[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [sendDate, setSendDate] = useState<Date | null>(null);
  const [mailSendIds, setMailSendIds] = useState<GridSelectionModel>([]);
  const status = "scheduled";
  const { apiClient } = useApiClient();

  const handleRowSelectionChange = (
    newSelectionModel: GridSelectionModel
  ): void => {
    setMailSendIds(newSelectionModel);
  };

  const handleFiltering = (): void => {
    (async () => {
      const response = await GetMailListAPI(
        apiClient,
        status,
        keyword,
        sendDate
      );
      setRows(response);
    })();
  };

  useEffect(() => {
    (async () => {
      const response = await GetMailListAPI(
        apiClient,
        status,
        keyword,
        sendDate
      );
      setRows(response);
    })();
  }, []);

  return (
    <MailPageTemplate>
      <form>
        {/* ページタイトル */}
        <Box sx={{ display: "flex", mb: 3 }}>
          <EmailIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>送信待ちメール一覧</PageTitle>
        </Box>

        {/* フィルタリングセクション */}
        <FilteringSection
          keyword={keyword}
          sendDate={sendDate}
          setKeyword={setKeyword}
          setSendDate={setSendDate}
          handleFiltering={handleFiltering}
        />

        {/* 送信取消ボタンセクション */}
        <ScheduleCancelledButtonSection
          keyword={keyword}
          sendDate={sendDate}
          status={status}
          mailSendIds={mailSendIds}
          setRows={setRows}
        />

        {/* 送信待ちメール一覧情報 */}
        <SectionPaper>
          <EmailListFeedGrid
            status={status}
            rows={rows}
            handleRowSelectionChange={handleRowSelectionChange}
          />
          <Box sx={{ mb: 6 }} />
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"center"}
            mb={2}
          />
        </SectionPaper>
      </form>
    </MailPageTemplate>
  );
};
