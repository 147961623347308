import { type AxiosInstance } from "axios";
import { type DestinationConditionSchema } from "../routes/MailScheduleCreator";
import type { DestinationTypeValues } from "../types/enums";

export interface MailSendToCountAPIResponse {
  count: number;
}

export interface MailSendToCountAPIRequest {
  destinationType: DestinationTypeValues.ALL | DestinationTypeValues.CONDITION;
  destinationConditions: DestinationConditionSchema[] | null;
}

export const getMailSendToCountAPIUrl = (): string => "/mail/send_to";

export const getMailSendToCountAPIfetcher =
  (apiClient: AxiosInstance, params: MailSendToCountAPIRequest) =>
  async (url: string): Promise<MailSendToCountAPIResponse> => {
    return await apiClient
      .post<MailSendToCountAPIResponse>(url, params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
