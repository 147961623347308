import * as React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { grey, blue } from "@mui/material/colors"; // colorsのインポートを追加
import { styled } from "@mui/system";

interface TextAreaProps {
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  defaultValue?: string;
  ariaLabel?: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
}

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const TextArea: React.FC<TextAreaProps> = ({
  minRows = 2,
  maxRows = 4,
  placeholder = "",
  defaultValue = "",
  ariaLabel = "text-area",
  style,
  onChange,
}) => {
  return (
    <Textarea
      minRows={minRows}
      maxRows={maxRows}
      aria-label={ariaLabel}
      placeholder={placeholder}
      defaultValue={defaultValue}
      style={style}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
      }}
    />
  );
};

export default TextArea;
