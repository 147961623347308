import React from "react";
import { TextField, MenuItem, Stack } from "@mui/material";
import { PageTitle } from "@/components/Title";
import { MailTemplateListTable } from "./ListTable";
import { ButtonMenu } from "@/components/Menu";
import { ButtonColorOption, ButtonVariantOption } from "@/components/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDeleteMailTemplateRequest } from "../../hooks/useDeleteMailTemplateRequest";
import { useMailTemplateListQueryParams } from "../../hooks/useMailTemplateListRequest";

export const MailTemplateList: React.FC = () => {
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const deleteMailTemplate = useDeleteMailTemplateRequest();
  const [keyword, setKeyword] = useMailTemplateListQueryParams();

  return (
    <>
      <PageTitle>テンプレート一覧</PageTitle>
      <Stack my={2} direction="column" spacing={1}>
        <TextField
          label="検索"
          defaultValue={keyword}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            setKeyword(event.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
              setKeyword((event.target as HTMLInputElement).value);
            }
          }}
          sx={{ width: "300px" }}
        />
        <ButtonMenu
          label="一括操作"
          endIcon={<ArrowDropDownIcon />}
          buttonColor={ButtonColorOption.Inherit}
          variant={ButtonVariantOption.Outlined}
          disabled={selectedIds.length === 0}
          sx={{ width: "120px" }}
        >
          <MenuItem
            onClick={() => {
              deleteMailTemplate(selectedIds);
            }}
          >
            削除
          </MenuItem>
        </ButtonMenu>
      </Stack>
      <MailTemplateListTable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </>
  );
};
