import React from "react";

interface ValidateItem<T> {
  state: ValidateState;
  setState: React.Dispatch<React.SetStateAction<ValidateState>>;
  validate: (data: T) => boolean;
  clearError: () => void;
}

interface ValidateState {
  helperTextWhenError: string;
  error: boolean;
}

interface UseValidatorParams {
  commercialBookTypeCompanyName: boolean;
  commercialBookTypeCompanyNumber: boolean;
}

interface Validator {
  pref: ValidateItem<string>;
  city: ValidateItem<string>;
  commercialBookName: ValidateItem<string>;
  commercialBookNumberPart1: ValidateItem<string>;
  commercialBookNumberPart2: ValidateItem<string>;
  commercialBookNumberPart3: ValidateItem<string>;
}

const useValidator = (params?: UseValidatorParams): Validator => {
  const [statePrefecture, setStatePrefecture] = React.useState<ValidateState>({
    error: false,
    helperTextWhenError: "",
  });
  const [stateCity, setStateCity] = React.useState<ValidateState>({
    error: false,
    helperTextWhenError: "",
  });
  const [stateCommercialBookName, setStateCommercialBookName] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateCommercialBookNumberPart1, setStateCommercialBookNumberPart1] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateCommercialBookNumberPart2, setStateCommercialBookNumberPart2] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateCommercialBookNumberPart3, setStateCommercialBookNumberPart3] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });

  // 都道府県のバリデーション
  const pref: ValidateItem<string> = {
    state: statePrefecture,
    setState: setStatePrefecture,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStatePrefecture({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStatePrefecture({
          error: true,
          helperTextWhenError: "都道府県を選択してください",
        });
        return false;
      }
    },
    clearError: (): void => {
      setStatePrefecture({ error: false, helperTextWhenError: "" });
    },
  };

  // 市区町村のバリデーション
  const city: ValidateItem<string> = {
    state: stateCity,
    setState: setStateCity,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateCity({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateCity({
          error: true,
          helperTextWhenError: "市区町村を選択してください",
        });
        return false;
      }
    },
    clearError: (): void => {
      setStateCity({ error: false, helperTextWhenError: "" });
    },
  };

  // 商号・名称のバリデーション
  const commercialBookName: ValidateItem<string> = {
    state: stateCommercialBookName,
    setState: setStateCommercialBookName,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateCommercialBookName({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateCommercialBookName({
          error: true,
          helperTextWhenError: "商号・名称を入力してください。",
        });
        return false;
      }
    },
    clearError: (): void => {
      setStateCommercialBookName({ error: false, helperTextWhenError: "" });
    },
  };

  // 商業・番号のバリデーション
  const commercialBookNumberPart1: ValidateItem<string> = {
    state: stateCommercialBookNumberPart1,
    setState: setStateCommercialBookNumberPart1,
    validate: (data: string): boolean => {
      if (data.length === 4) {
        setStateCommercialBookNumberPart1({
          error: false,
          helperTextWhenError: "",
        });
        return true;
      } else {
        setStateCommercialBookNumberPart1({
          error: true,
          helperTextWhenError: "会社番号をを入力してください",
        });
        return false;
      }
    },
    clearError: (): void => {
      setStateCommercialBookNumberPart1({
        error: false,
        helperTextWhenError: "",
      });
    },
  };

  const commercialBookNumberPart2: ValidateItem<string> = {
    state: stateCommercialBookNumberPart2,
    setState: setStateCommercialBookNumberPart2,
    validate: (data: string): boolean => {
      if (data.length === 2) {
        setStateCommercialBookNumberPart2({
          error: false,
          helperTextWhenError: "",
        });
        return true;
      } else {
        setStateCommercialBookNumberPart2({
          error: true,
          helperTextWhenError: "会社番号をを入力してください",
        });
        return false;
      }
    },
    clearError: (): void => {
      setStateCommercialBookNumberPart2({
        error: false,
        helperTextWhenError: "",
      });
    },
  };
  const commercialBookNumberPart3: ValidateItem<string> = {
    state: stateCommercialBookNumberPart3,
    setState: setStateCommercialBookNumberPart3,
    validate: (data: string): boolean => {
      if (data.length === 6) {
        setStateCommercialBookNumberPart3({
          error: false,
          helperTextWhenError: "",
        });
        return true;
      } else {
        setStateCommercialBookNumberPart3({
          error: true,
          helperTextWhenError: "会社番号をを入力してください",
        });
        return false;
      }
    },
    clearError: (): void => {
      setStateCommercialBookNumberPart3({
        error: false,
        helperTextWhenError: "",
      });
    },
  };

  return {
    pref,
    city,
    commercialBookName,
    commercialBookNumberPart1,
    commercialBookNumberPart2,
    commercialBookNumberPart3,
  };
};

export { useValidator };
