import { Navigate, Route, Routes } from "react-router-dom";
import { CustomerList } from "@/features/customerManagement/routes/CustomerList";
import React from "react";
import { ExcludedCustomerDetail } from "./ExcludedCustomerDetail";
import { ExcludedCustomerList } from "./ExcludedCustomerList";
import { CustomerDetail } from "./CustomerDetail";
import { useFeatureFlags } from "@/configs/featureFlag";

export const CustomerManagementRoutes: React.FC = () => {
  const { customerManagement } = useFeatureFlags();

  return (
    <Routes>
      <Route path="customerlist" element={<CustomerList />} />
      {customerManagement && (
        <>
          <Route
            path="excludedcustomer/:id"
            element={<ExcludedCustomerDetail />}
          />
          <Route
            path="excludedcustomerlist"
            element={<ExcludedCustomerList />}
          />
          <Route path=":id" element={<CustomerDetail />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/customerlist" />} />
    </Routes>
  );
};
