import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  type Theme,
} from "@mui/material";
import { type OverridableStringUnion } from "@mui/types";
import { type CheckboxPropsSizeOverrides } from "@mui/material/Checkbox/Checkbox";
import { type SxProps } from "@mui/system";

interface IProps {
  label?: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  size?: OverridableStringUnion<"small" | "medium", CheckboxPropsSizeOverrides>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const CheckboxForAcquireBook: React.FC<IProps> = (props) => {
  const { label, checked, onChange, size, disabled, sx } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.checked);
  };

  const labeledCheckbox = (
    <FormControlLabel
      control={
        <MuiCheckbox
          sx={
            sx ?? {
              padding: "0px 4px",
            }
          }
          checked={checked}
          onChange={handleChange}
          size={size}
          disabled={disabled}
        />
      }
      label={label}
    />
  );

  const checkbox = (
    <MuiCheckbox
      sx={
        sx ?? {
          padding: "0px 4px",
        }
      }
      checked={checked}
      onChange={handleChange}
      size={size}
      disabled={disabled}
    />
  );

  // labelがundefinedの時は、単体チェックボックスのみ返す
  // labelに何かある場合は、<FormControlLabel>を使用したチェックボックスを返す
  if (label === undefined) return checkbox;
  else return labeledCheckbox;
};

export { CheckboxForAcquireBook };
