import { type AxiosInstance, HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { type KaokuNumberSearchRequest } from "../types";

export const getKaokuNumberSearchRequestAPI = async (
  apiClient: AxiosInstance,
  id: number
): Promise<KaokuNumberSearchRequest | undefined> => {
  const url = `/kaoku-number/${id}`;

  const response = await apiClient
    .get<KaokuNumberSearchRequest>(url)
    .catch((error): void => {
      if (
        isAxiosError(error) &&
        error.response?.status !== HttpStatusCode.Unauthorized
      ) {
        Sentry.captureException(error);
      }
      return undefined;
    });
  if (response) {
    return response.status === HttpStatusCode.Ok ? response.data : undefined;
  }
};
