import { styled } from "@mui/material/styles";
import { CommercialBookDataGrid } from "../../../components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import { type CommercialBookStatus } from "../types";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";

interface IProps {
  row: CommercialBookStatus[] | undefined;
}

interface CommercialBookStatusDataGridRow {
  id: number;
  kubunType: string;
  prefectureName: string;
  location: string;
  status: string;
  corporateNumber: string;
  companyInfo: string;
  companyName: string;
  uri: string;
  closed: boolean;
  errorMessage: string;
}

const StyledDataGrid = styled(CommercialBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

// セルの値に応じて適切なアイコンを表示する
// undefined : 空欄
// "" : 空欄
// FAILED : エラーアイコン
// PROCESSING : ダウンロード中アイコン
// URL : ダウンロードアイコン
const renderIndicatorIconButton = ({
  value,
}: GridRenderCellParams<string>): React.ReactNode => {
  if (value === undefined) return "";
  if (value === "") return "";

  if (value === "FAILED") {
    return (
      <Tooltip title={"取得に失敗しました"}>
        <ErrorIcon />
      </Tooltip>
    );
  } else if (value === "PROCESSING") {
    return (
      <Tooltip title={"取得しています"}>
        <DownloadingIcon />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={"ダウンロードできます"}>
        <IconButton href={value}>
          <CloudDownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
    );
  }
};

// カラム設定
const coldef: GridColDef[] = [
  {
    field: "kubunType",
    headerName: "区分",
    width: 120,
    sortable: false,
  },
  {
    field: "corporateNumber",
    headerName: "会社法人等番号",
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<GridColDef>) => {
      const { corporateNumber, closed } = params.row as {
        corporateNumber: string;
        closed: boolean;
      };
      return (
        <div>
          {corporateNumber}
          {closed && (
            <Typography variant="caption" display="block">
              (閉鎖登記簿)
            </Typography>
          )}
        </div>
      );
    },
  },
  {
    field: "location",
    headerName: "所在",
    width: 400,
    sortable: false,
  },
  {
    field: "companyName",
    headerName: "商業・名称",
    width: 400,
    sortable: false,
  },
  {
    field: "uri",
    headerName: "商業登記",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: renderIndicatorIconButton,
  },
  {
    field: "errorMessage",
    headerName: "エラーメッセージ",
    width: 800,
    sortable: false,
  },
];

// statusの状態に応じてDataGridのセルに格納する値を決める
const getUriIfSuccess = (value: CommercialBookStatus): string => {
  if (value.status === "SUCCEEDED") {
    return value.downloadUrl ?? "";
  } else if (value.status === "FAILED") {
    return "FAILED";
  } else {
    return "PROCESSING";
  }
};

// uriから最後のスラッシュと「法人登記簿」の間のテキスト(商業・名称)を抽出
const extractCompanyInfoFromUri = (value: CommercialBookStatus): string => {
  if (!value.downloadUrl) {
    return "";
  }
  const lastSlashIndex = value.downloadUrl.lastIndexOf("/");
  const lastPart = value.downloadUrl.substring(lastSlashIndex + 1);
  const keywordIndex = lastPart.indexOf("法人登記簿");
  return lastPart.substring(0, keywordIndex);
};

/**
 * バックエンドから受け取ったレスポンスからメッセージに変換する
 * エラーでは無い、または存在しないエラーの場合は空文字を返す
 * @param commercialBookStatus バックエンドから受け取ったレスポンス
 */
const getCommercialBookErrorMessage = (
  commercialBookStatus: CommercialBookStatus
): string => {
  const messageTable: Record<string, string> = {
    NO_RECORDS_ERROR:
      "検索した商業登記はありません。入力事項を確認の上、再度請求してください。",
    EXISTING_SESSION_ERROR:
      "サイトが混雑しているため取得できませんでした。再度請求してください。",
    REGISTRATION_IN_PROGRESS_ERROR:
      "請求のあった会社・法人等は登記事件の処理中です。登記が完了した後に再度請求してください。",
  };
  if (commercialBookStatus.errorDetail) {
    const errorDetail = commercialBookStatus.errorDetail;
    if (errorDetail === "NO_RECORDS_ERROR") {
      // エラーがNO_RECORDS_ERRORの場合の特別処理
      if (commercialBookStatus.matchMethod === "商号・名称") {
        if (
          commercialBookStatus.conditionLocation &&
          commercialBookStatus.conditionCompanyName
        ) {
          const location = commercialBookStatus.conditionLocation;
          const companyName = commercialBookStatus.conditionCompanyName;

          return `${location}に${companyName}は見つかりませんでした。入力事項を確認の上、再度請求してください。`;
        }
      } else if (commercialBookStatus.matchMethod === "会社法人等番号") {
        if (
          commercialBookStatus.conditionLocation &&
          commercialBookStatus.corporateNumber
        ) {
          const location = commercialBookStatus.conditionLocation;
          const corporateNumber = commercialBookStatus.corporateNumber;

          return `${location}に${corporateNumber}は見つかりませんでした。入力事項を確認の上、再度請求してください。`;
        }
      }
    }

    // それ以外のエラーはmessageTableに従う
    return messageTable[errorDetail];
  }
  return "";
};

const convertRows = (
  rows: CommercialBookStatus[] | undefined
): CommercialBookStatusDataGridRow[] => {
  if (rows === undefined) return [];

  const result = rows.map((value): CommercialBookStatusDataGridRow => {
    return {
      id: value.id,
      kubunType: "商業及び法人",
      prefectureName: value.prefectureName,
      location: value.location,
      corporateNumber: value.corporateNumber ?? "",
      companyInfo: extractCompanyInfoFromUri(value),
      companyName: value.companyName ?? "",
      status: value.status as string,
      uri: getUriIfSuccess(value),
      closed: value.closed,
      errorMessage: getCommercialBookErrorMessage(value),
    };
  });
  return result;
};

export const FeedGrid: React.FC<IProps> = (props) => {
  const rows = props.row;

  // DataGridに表示できる形式に変換する
  const convertedRows = convertRows(rows);

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={convertedRows}
      columns={coldef}
    />
  );
};
