import { type AxiosInstance, HttpStatusCode } from "axios";
import type {
  DeleteMailDetailAPIRequest,
  DeleteMailDetailAPIResponse,
} from "../types";

/**
 * メール削除API
 * @param {DeleteMailDetailAPIRequest} data - データ
 * @returns {DeleteMailDetailAPIResponse | undefined} DeleteMailDetailAPIResponse | undefined
 */
export const DeleteMailDetailAPI = async (
  apiClient: AxiosInstance,
  data: DeleteMailDetailAPIRequest
): Promise<DeleteMailDetailAPIResponse | undefined> => {
  const response = await apiClient.delete<DeleteMailDetailAPIResponse>(
    "/mail/",
    { data }
  );
  return response.status === HttpStatusCode.Ok ? response.data : undefined;
};
