import { type GridSelectionModel } from "@mui/x-data-grid";
import { type AxiosInstance } from "axios";

interface IRequests {
  startDate: Date | null; // 登記年月開始日
  endDate: Date | null; // 登記年月終了日
  ids: GridSelectionModel; // モニタリング詳細テーブル（RealEstateMonitoringDetail）のIDの配列
}

/**
 * モニタリングするAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 */
export const ExecuteMonitoringAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<void> => {
  // TODO: モニタリングするAPIを実行する。

  console.log("モニタリング実行APIが実行されました。");
  console.log("startDate =", requests.startDate);
  console.log("endDate =", requests.endDate);
  console.log("ids =", requests.ids);
};
