import { type IPrefecture } from "@/types/prefectures";
import { type AxiosInstance, HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

export const GetPrefecturesAPI = async (
  apiClient: AxiosInstance
): Promise<IPrefecture[]> => {
  const url = "/general/prefecture";

  const response = await apiClient
    .get<IPrefecture[]>(url, {})
    .catch((error): void => {
      /**
       * apiClientをSWRを通さず単体で利用する場合はいくつかの制約事項がある
       * - accesstokenが有効期限切れで失敗した場合
       *     accesstokenの再設定は自動で行われるが、このAPI呼び出し自体の再実行はされないことに注意する
       * - Promiseがrejectedとなった場合の処理を必ず書いておくこと
       *     こうしないとaxiosが失敗と判断した場合常にsentryに通知されてしまう
       *     accesstokenが無効であった時(401)でさえもsentryに通知される
       */
      if (
        isAxiosError(error) &&
        error.response?.status !== HttpStatusCode.Unauthorized
      ) {
        Sentry.captureException(error);
      }
      return undefined;
    });
  if (response) {
    return response.status === HttpStatusCode.Ok ? response.data : [];
  }
  return [];
};
