import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";
import { toast } from "react-toastify";

interface CompanyListAPIRequest {
  matchType: string;
  matchMethod: string;
  todouhuken: string;
  shyozai: string;
  kubun: string;
  companyName?: string;
  corporateNumber?: string;
}

interface CompanyListAPIResponseBody {
  id: number;
  corporateNumber: string;
  companyName: string;
  location: string;
  closed: boolean;
}

interface CompanyListAPIResponse {
  status: string;
  detail: string;
  body: CompanyListAPIResponseBody[];
}

interface CompanyListAPIResult {
  data: CompanyListAPIResponseBody[];
  isLoading: boolean;
}

const handleError = (status: string): boolean => {
  const errorMessages: Record<string, string> = {
    "400": "予期しないエラーが発生しました",
    "408": "サイトが混雑しているため取得できませんでした。再度請求してください",
    "500": "サイトが混雑しているため取得できませんでした。再度請求してください",
    "503":
      "民事法務協会サイトサイトがサービス時間外のため取得できませんでした。営業時間内で再度請求してください",
  };

  if (errorMessages[status]) {
    toast.error(errorMessages[status]);
    return true;
  }
  return false;
};

export const CompanyListAPI = (
  fetchParams: CompanyListAPIRequest
): CompanyListAPIResult => {
  const { apiClient } = useApiClient();
  const fetcher = async (
    url: string,
    params: CompanyListAPIRequest
  ): Promise<CompanyListAPIResponse> => {
    const { data } = await apiClient.get<CompanyListAPIResponse>(url, {
      params,
    });
    return data;
  };

  const { data, isLoading } = useSWR<CompanyListAPIResponse>(
    ["/commercial-picture-request/search/", fetchParams],
    async ([url, params]: [string, CompanyListAPIRequest]) =>
      await fetcher(url, params),
    { revalidateOnFocus: false }
  );

  // スクレイピングシステムでエラーがあった場合
  if (data && handleError(data.status)) {
    return { data: [], isLoading: false };
  }

  // 検索結果が0だった場合
  if (data?.status === "200" && data.body.length === 0) {
    toast.info("検索結果が0件です。");
    return { data: [], isLoading: false };
  }

  // データがまだ取得されていない場合に動作
  if (data === undefined) return { data: [], isLoading };

  return { data: data.body, isLoading: false };
};
