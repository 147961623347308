import { useApiClient } from "@/hooks/useApiClient";
import { type GeoSpaceChiban } from "../types";

interface GeospaceSearchResponse {
  status: string;
  error_message: string;
  chiban: GeoSpaceChiban[];
}

interface GeospaceReverseResponse {
  status: string;
  error_message: string;
  chiban: GeoSpaceChiban;
}

interface GeospaceReverseFreeResponse {
  lat: number;
  lng: number;
}

interface GeoSpaceSearchRequestParams {
  chiban: string;
}

interface GeoSpaceReverseRequestParams {
  address: string;
}

interface GeoSpaceReverseRequestFreeParams
  extends GeoSpaceReverseRequestParams {}

interface GeoSpaceReverseByLatLngRequestParams {
  lat: number;
  lng: number;
}

interface GeoSpaceReverseByPolygonRequestParams {
  polygon: string | undefined;
}

interface GeoSpaceReverseByCircleRequestParams {
  radius: number;
  lat: number;
  lng: number;
}

interface geospaceGetMapServiceContractAppIdResponse {
  appId: string;
  contractStartDate: string;
  createdAt: string;
  id: number;
  updatedAt: string;
  user: number;
}

interface GeoSpaceApi {
  geospaceSearch: (d: string) => Promise<GeoSpaceChiban | undefined>;
  geospaceReverse: (d: string) => Promise<GeoSpaceChiban | undefined>;
  geospaceReverseFree: (
    d: string
  ) => Promise<GeospaceReverseFreeResponse | undefined>;
  geospaceReverseByLatLng: (
    lat: number,
    lng: number
  ) => Promise<GeoSpaceChiban | undefined>;
  geospaceReverseByPolygon: (
    polygon: string | undefined
  ) => Promise<GeoSpaceChiban | undefined>;
  geospaceReverseByCircle: (
    radius: number,
    lat: number,
    lng: number
  ) => Promise<GeoSpaceChiban | undefined>;
  geospaceGetMapServiceContractAppId: () => Promise<string | undefined>;
}

export const useGeoSpaceApi = (): GeoSpaceApi => {
  const { apiClient } = useApiClient();

  const geospaceSearch = async (
    d: string
  ): Promise<GeoSpaceChiban | undefined> => {
    const params: GeoSpaceSearchRequestParams = {
      chiban: d,
    };
    try {
      const response = await apiClient.get<GeospaceSearchResponse>(
        "/geospace/search",
        {
          params,
        }
      );
      if (response.data.status === "success") {
        return response.data.chiban[0];
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  };

  const geospaceReverse = async (
    d: string
  ): Promise<GeoSpaceChiban | undefined> => {
    const params: GeoSpaceReverseRequestParams = {
      address: d,
    };
    const response = await apiClient.get<GeospaceReverseResponse>(
      "/geospace/reverse",
      {
        params,
      }
    );
    if (response.data.status === "success") {
      return response.data.chiban;
    }
    // 検索結果が0だった場合
    if (response.data.status === "") {
      return undefined;
    }
    throw new Error();
  };

  // 地図フリープラン向け、住居表示から座標を取得するAPIを呼び出す
  const geospaceReverseFree = async (
    d: string
  ): Promise<GeospaceReverseFreeResponse | undefined> => {
    const params: GeoSpaceReverseRequestFreeParams = {
      address: d,
    };
    const response = await apiClient.get<{ lat: number; lon: number }>(
      "/geospace/reverse-free",
      {
        params,
      }
    );
    if (response.status === 200) {
      return { lat: response.data.lat, lng: response.data.lon };
    }

    throw new Error();
  };

  const geospaceReverseByLatLng = async (
    lat: number,
    lng: number
  ): Promise<GeoSpaceChiban | undefined> => {
    const params: GeoSpaceReverseByLatLngRequestParams = { lat, lng };

    try {
      const response = await apiClient.get<GeospaceReverseResponse>(
        "/geospace/reverse-latlng",
        {
          params,
        }
      );
      if (response.data.status === "success") {
        return response.data.chiban;
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  };

  const geospaceReverseByPolygon = async (
    polygon: string | undefined
  ): Promise<GeoSpaceChiban | undefined> => {
    const params: GeoSpaceReverseByPolygonRequestParams = { polygon };

    try {
      const response = await apiClient.get<GeospaceReverseResponse>(
        "/geospace/reverse-polygon",
        {
          params,
        }
      );
      if (response.data.status === "success") {
        return response.data.chiban;
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  };

  const geospaceReverseByCircle = async (
    radius: number,
    lat: number,
    lng: number
  ): Promise<GeoSpaceChiban | undefined> => {
    const params: GeoSpaceReverseByCircleRequestParams = { radius, lat, lng };

    try {
      const response = await apiClient.get<GeospaceReverseResponse>(
        "/geospace/reverse-circle",
        {
          params,
        }
      );
      if (response.data.status === "success") {
        return response.data.chiban;
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  };

  const geospaceGetMapServiceContractAppId = async (): Promise<
    string | undefined
  > => {
    try {
      const response =
        await apiClient.get<geospaceGetMapServiceContractAppIdResponse>(
          "/user/map-service-contract"
        );
      return response.data.appId;
    } catch (e) {
      return undefined;
    }
  };

  return {
    geospaceSearch,
    geospaceReverse,
    geospaceReverseFree,
    geospaceReverseByLatLng,
    geospaceReverseByPolygon,
    geospaceReverseByCircle,
    geospaceGetMapServiceContractAppId,
  };
};
