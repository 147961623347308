import { type ReactNode } from "react";
import { StyledDataGrid } from "@/features/map/components/DataGrid";
import {
  type GridSelectionModel,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  type KeyName,
  type CheckboxStatus,
  type MonitoringDataGridRow,
} from "../types";
import { format } from "date-fns";
import { Checkbox } from "@mui/material";

interface IProps {
  ids: GridSelectionModel;
  rows: MonitoringDataGridRow[];
  setAcquireBooksRows: React.Dispatch<
    React.SetStateAction<MonitoringDataGridRow[]>
  >;
}

interface ICheckboxChangeProps {
  checked: boolean;
  id: number;
  keyName: KeyName;
}

const makeGridColDef = (
  rows: MonitoringDataGridRow[],
  setAcquireBooksRows: React.Dispatch<
    React.SetStateAction<MonitoringDataGridRow[]>
  >
): GridColDef[] => {
  /**
   * チェックボックスが操作されたときにチェックの状態を更新する。
   * @param {ICheckboxChangeProps} props
   */
  const handleCheckboxChange = (props: ICheckboxChangeProps): void => {
    const { checked, id, keyName } = props;
    const acquireBooksRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [keyName]: { checked: !checked, disabled: false }, // チェックできるということはdisabledはfalse。
        };
      }

      return row;
    });

    setAcquireBooksRows(acquireBooksRows);
  };

  /**
   * チェックボックスのセルを表示する処理。
   * @param {boolean} checked
   * @param {boolean} disabled
   * @param {number} id
   * @param {CheckboxStatus | undefined} value
   * @param {ICheckboxChangeProps["keyName"]} keyName
   * @returns {ReactNode}
   */
  const renderCheckboxCell = (
    checked: boolean,
    disabled: boolean,
    id: number,
    value: CheckboxStatus | undefined,
    keyName: ICheckboxChangeProps["keyName"]
  ): ReactNode => {
    return (
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={() => {
          handleCheckboxChange({
            id,
            checked: value != null ? value.checked : false,
            keyName,
          });
        }}
      />
    );
  };

  return [
    {
      field: "id",
      headerName: "No",
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "realEstateTypeJpn",
      headerName: "不動産種別",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "所在",
      width: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chibanKaokuNumber",
      headerName: "地番または家屋番号",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "latestReceptionDate",
      headerName: "最新登記年月日",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value != null) return format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      field: "soto",
      headerName: "外",
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remarks",
      headerName: "備考",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "kyodo",
      headerName: "共担",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.kyodo.checked,
          row.kyodo.disabled,
          row.id,
          value,
          "kyodo"
        );
      },
    },
    {
      field: "sintaku",
      headerName: "信託",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.sintaku.checked,
          row.sintaku.disabled,
          row.id,
          value,
          "sintaku"
        );
      },
    },
    {
      field: "genzai",
      headerName: "現在",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.genzai.checked,
          row.genzai.disabled,
          row.id,
          value,
          "genzai"
        );
      },
    },
    {
      field: "syoyusya",
      headerName: "所有者事項",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.syoyusya.checked,
          row.syoyusya.disabled,
          row.id,
          value,
          "syoyusya"
        );
      },
    },
    {
      field: "densi",
      headerName: "電子公図",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.densi.checked,
          row.densi.disabled,
          row.id,
          value,
          "densi"
        );
      },
    },
    {
      field: "tiseki",
      headerName: "地積測量図",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.tiseki.checked,
          row.tiseki.disabled,
          row.id,
          value,
          "tiseki"
        );
      },
    },
    {
      field: "tieki",
      headerName: "地役権図面",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.tieki.checked,
          row.tieki.disabled,
          row.id,
          value,
          "tieki"
        );
      },
    },
    {
      field: "tatemono",
      headerName: "建物図面",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<CheckboxStatus, MonitoringDataGridRow>
      ) => {
        const { value, row } = params;

        return renderCheckboxCell(
          row.tatemono.checked,
          row.tatemono.disabled,
          row.id,
          value,
          "tatemono"
        );
      },
    },
  ];
};

/**
 * 謄本取得するモーダルの表部分
 * @param {IProps} props
 * @returns
 */
export const AcquireBooksDataGrid: React.FC<IProps> = (props: IProps) => {
  const { ids, rows, setAcquireBooksRows } = props;
  const colDef = makeGridColDef(rows, setAcquireBooksRows);

  // チェックが入っているデータのみ表に表示する。
  const selectedRows = rows.filter((row) => ids.includes(row.id));

  return (
    <StyledDataGrid
      autoHeight
      columns={colDef}
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={selectedRows}
      sortModel={[
        {
          field: "latestReceptionDate",
          sort: "desc",
        },
      ]}
    />
  );
};
