import { useLogin } from "../../../hooks/useLogin";

interface ReturnTypes {
  isVisible: boolean;
  handleClickLogout: () => void;
}
export const useHeaderMenu = (): ReturnTypes => {
  const { isLoggedIn, logout } = useLogin();

  const handleClickLogout = (): void => {
    logout();
  };

  return {
    isVisible: isLoggedIn,
    handleClickLogout,
  };
};
