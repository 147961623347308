import { useAcquireBookForm } from "./hooks/useAcquireBookForm";
import { AcquireMultipleBooksPresenter } from "./presenter";
import { useEffect, useState } from "react";
import { UserConfigGetAPI } from "@/features/accountSettings/api/UserConfigAPI";

export const AcquireMultipleBooksContainer: React.FC = () => {
  const { handleSubmit } = useAcquireBookForm();

  return <AcquireMultipleBooksPresenter handleSubmit={handleSubmit} />;
};
