import { useState, useEffect } from "react";

export const useMapServicePermission = (): [boolean | null, boolean] => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const appId = localStorage.getItem("mapServiceAppId");
    setHasPermission(!!appId);
    setLoading(false);
  }, []);

  return [hasPermission, loading];
};
