import { useApiClient } from "@/hooks/useApiClient";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";

interface MailTemplateListItem {
  id: number; // ID
  name: string; // 名前
  updatedAt: string; // 更新日
}

export type MailTemplateListAPIResponse = MailTemplateListItem[];

export const getMailTemplateListUrl = (keyword: string): string => {
  return `/mail/template?keyword=${keyword}`;
};

export const useMailTemplateListRequest = (): {
  data: MailTemplateListAPIResponse;
  refetch: () => Promise<void>;
} => {
  const { apiClient } = useApiClient();
  const [keyword] = useMailTemplateListQueryParams();

  const fetcher = async (url: string): Promise<MailTemplateListAPIResponse> => {
    const { data } = await apiClient.get<MailTemplateListAPIResponse>(url);
    return data;
  };
  const { data } = useSWR<MailTemplateListAPIResponse>(
    getMailTemplateListUrl(keyword),
    fetcher,
    {
      revalidateOnFocus: true,
    }
  );

  const refetch = async (): Promise<void> => {
    await mutate(getMailTemplateListUrl(keyword));
  };

  if (data === undefined) return { data: [], refetch };

  return { data, refetch };
};

export const useMailTemplateListQueryParams = (): [
  string,
  (keyword: string) => void
] => {
  const location = useLocation();
  const params = new URLSearchParams();
  const navigate = useNavigate();
  const setKeyword = (keyword: string): void => {
    params.set("keyword", keyword);
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };
  const keyword = new URLSearchParams(location.search).get("keyword") ?? "";

  return [keyword, setKeyword];
};
