import { useApiClient } from "../../../hooks/useApiClient";
import MenuItem from "@mui/material/MenuItem";
import {
  type GridExportMenuItemProps,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  type ExcelExportOptions,
  useExcelExport,
} from "@/hooks/useExcelExport";
import { CustomModal } from "@/components/Modal";
import { PageTitle } from "@/components/Title";
import { Box, Stack, Typography } from "@mui/material";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { useState } from "react";
import { RealEstateReceptionBookDataGridColumns } from "../utils";
import { feedAPI } from "../api/feedAPI";
import {
  type IFilterCondition,
  type IRealEstateReceptionBookDataGridRow,
} from "../types";
import { defaultGetRowsToExport } from "@mui/x-data-grid/hooks/features/export/utils";
import { toast } from "react-toastify";
import { HttpStatusCode } from "axios";
import { toFetchParams, toGridRows } from "../utils/feedUtils";

// GridExportMenuItemPropsにhidemenu()の型定義が書かれている
declare type ExcelExportMenuItemProps =
  GridExportMenuItemProps<ExcelExportOptions> & {
    condition: IFilterCondition;
  };

const PRICE_PER_ITEM = 50; // 課金1件あたりの金額
const FEED_FETCH_SIZE = 1000;

const ExcelExportWithChibanMenuItem: React.FC<ExcelExportMenuItemProps> = (
  props
) => {
  const { condition, hideMenu, options } = props;

  const { apiClient } = useApiClient();
  const { downloadAsExcelFile, makeExcelUint8Array, getFeedGridData } =
    useExcelExport();
  const apiRef = useGridApiContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chargeAmount, setChargeAmount] = useState<number>(0);

  // 画面に表示されている検索結果を持っておくための変数。CSVダウンロード後にGridに検索結果を再反映する。
  const [rows, setRows] = useState<IRealEstateReceptionBookDataGridRow[]>([]);
  const columns = apiRef.current.getAllColumns();
  const gridRowIds = defaultGetRowsToExport({ apiRef });

  // 金額をセットしてモーダル表示。
  const handleClickCsvDownload = (): void => {
    setChargeAmount(PRICE_PER_ITEM * apiRef.current.state.selection.length);
    setIsOpen(true);
  };

  // CSVをダウンロードし、検索結果を元に戻す。
  const handleClickYes = (): void => {
    (async () => {
      // csvをダウンロード。
      await downloadCsv();

      // csvダウンロード時にGridを変更したので、元に戻す。
      apiRef.current.updateColumns(columns);
      apiRef.current.setRows(rows);
      apiRef.current.setSelectionModel(gridRowIds);
      apiRef.current.setColumnVisibilityModel({
        // Hide columns, the other columns will remain visible
        id: false, // Only CSV
        receptionReasonType: false, // Only CSV
        volumeRate: false, // Only CSV
        hasTrade: false, // 売買スクリーニング列はデフォルトで非表示
      });

      closeMenu();
    })();
  };

  // モーダル非表示とエクスポートボタンのメニューを非表示。
  const closeMenu = (): void => {
    hideMenu?.();
    setIsOpen(false);
  };

  // 選択した行の地番・家屋番号入りデータを取得しcsvでダウンロードする。
  const downloadCsv = async (): Promise<void> => {
    const response = await feedAPI(
      apiClient,
      toFetchParams(
        {
          ...condition,
          size: FEED_FETCH_SIZE,
          realEstateReceptionBookIds: gridRowIds,
        },
        0,
        undefined
      )
    );

    if (response?.status !== HttpStatusCode.Ok) {
      toast.error(response?.error ?? "予期しないエラーが発生しました");
      return;
    }
    if (response?.data != null) {
      const gridRowsData = toGridRows(response.data);
      apiRef.current.setRows(gridRowsData);
      setRows(gridRowsData);
    }

    // DataGridのヘッダーに所在と地番・家屋番号を設定。
    apiRef.current.updateColumns([
      ...columns,
      RealEstateReceptionBookDataGridColumns.Address,
      RealEstateReceptionBookDataGridColumns.Chiban,
    ]);

    // 親のReact componentでoptionの設定を忘れていた場合に使用されるデフォルトのファイル名とシート名
    const defaultSheetName = "不動産登記受付帳_地番_家屋番号";
    const defaultFileName = "R.E.DATA_不動産データ_地番_家屋番号";
    const arrayOfArray = getFeedGridData(apiRef, options);
    const data = makeExcelUint8Array(
      arrayOfArray,
      options?.sheetName ?? defaultSheetName
    );
    downloadAsExcelFile(data, options?.fileName ?? defaultFileName);
  };

  return (
    <>
      <MenuItem onClick={handleClickCsvDownload}>
        エクセルダウンロード（所在・地番/家屋番号セット）
      </MenuItem>

      {/* モーダル表示 */}
      <CustomModal
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        ariaLabelledby="billing-modal"
        ariaDescribedby="billing-modal-description"
      >
        <PageTitle>課金確認</PageTitle>
        <Stack my={2} direction={"column"} spacing={1}>
          <Typography>
            {chargeAmount.toLocaleString()}円の課金が発生します。
          </Typography>
          <Typography>本当にダウンロードしますか？</Typography>
          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <SubmitButton
              label="はい"
              onClick={handleClickYes}
              timeout={30000}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              onClick={closeMenu}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Stack>
      </CustomModal>
    </>
  );
};

export { ExcelExportWithChibanMenuItem };
