import { HazardBaseLayer } from "./HazardBaseLayer";

interface Props {
  defaultVisible: boolean;
}

const host = process.env.VITE_HAZARD_TILE_HOST ?? "";
const url = `${host}/30/flood_30.pmtiles`;

export const FLOOD_30_LAYER_NAME = "浸水継続時間（想定最大規模）";
const colorMap: Record<number, string> = {
  1: "rgb(165,212,254)",
  2: "rgb(13,75,254)",
  3: "rgb(250,246,14)",
  4: "rgb(252,157,11)",
  5: "rgb(255,51,14)",
  6: "rgb(182,12,108)",
  7: "rgb(104,14,104)",
};
const attributeKey = "A31b_301";

/*
浸水継続時間（想定最大規模）レイヤー
タイル元データ: 洪水浸水想定区域データ（1次メッシュ単位）2023年度
https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A31b-2023.html
*/
export const HazardFlood30Layer: React.FC<Props> = ({ defaultVisible }) => {
  return (
    <HazardBaseLayer
      defaultVisible={defaultVisible}
      layerName={FLOOD_30_LAYER_NAME}
      url={url}
      attributeKey={attributeKey}
      colorMap={colorMap}
    />
  );
};
