import { Box, Grid } from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption } from "@/components/Button";
import { CheckboxGroup, Checkbox } from "@/components/Checkbox";
import { Divider } from "@/components/Divider";
import { Label } from "@/components/Label";
import { SectionPaper } from "@/components/Paper";
import { MultiSelect } from "@/components/Select";
import { SectionTitle } from "@/components/Title";
import { SizingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { usePrefectureOptions } from "@/features/realEstateReceptionBookFeed/hooks/usePrefectureOptions";
import { useCityOptions } from "@/features/realEstateReceptionBookFeed/hooks/useCityOptions";
import { useCitySelect } from "@/features/realEstateReceptionBookFeed/hooks/useCitySelect";
import type { FilterCondition, FilteringConditionProps } from "../types";

export const FilteringCondition: React.FC<FilteringConditionProps> = ({
  filterCondition,
  handleFiltering,
  setFilterCondition,
}) => {
  // 都道府県の選択肢を取得
  const { prefectureOptions } = usePrefectureOptions();
  const { cityParams } = useCityOptions();
  const { cityOptions } = useCitySelect(
    filterCondition.prefectures,
    cityParams
  );

  // 不動産種別のチェックボックスの状態を管理する関数を集約
  const handleCheckboxChange = (condition: keyof FilterCondition): void => {
    setFilterCondition((prev) => ({
      ...prev,
      [condition]: !prev[condition],
    }));
  };

  return (
    <SectionPaper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SectionTitle>絞り込み</SectionTitle>
      </Box>
      <Box sx={{ mx: 2, mb: 2 }}>
        <Divider />
      </Box>
      <Box sx={{ mx: 2, mb: 1 }}>
        <Label>地域</Label>
        <Grid container>
          <Box sx={{ mr: 1 }}>
            <SizingWrapper>
              <MultiSelect
                label="都道府県"
                value={filterCondition.prefectures}
                onChange={(value: string[]) => {
                  setFilterCondition((prevState) => ({
                    ...prevState,
                    prefectures: value,
                  }));
                }}
                options={prefectureOptions}
              />
            </SizingWrapper>
          </Box>

          <SizingWrapper>
            <MultiSelect
              label="市区町村 *"
              value={filterCondition.cities}
              onChange={(value: string[]) => {
                setFilterCondition((prevState) => ({
                  ...prevState,
                  cities: value,
                }));
              }}
              options={cityOptions}
              maxSelect={10}
            />
          </SizingWrapper>
        </Grid>
      </Box>

      {/* 不動産種別 */}
      <Box sx={{ mx: 2, mb: 2 }}>
        <CheckboxGroup label="不動産種別">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              label="土地"
              checked={filterCondition.realEstateTypeTochi}
              onChange={() => {
                handleCheckboxChange("realEstateTypeTochi");
              }}
            />
            <Checkbox
              label="区分建物"
              checked={filterCondition.realEstateTypeKutate}
              onChange={() => {
                handleCheckboxChange("realEstateTypeKutate");
              }}
            />
            <Checkbox
              label="建物"
              checked={filterCondition.realEstateTypeTatemono}
              onChange={() => {
                handleCheckboxChange("realEstateTypeTatemono");
              }}
            />
          </Box>
        </CheckboxGroup>
      </Box>

      <Box
        sx={{
          mr: 2,
          mb: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          label="絞り込み"
          onClick={handleFiltering}
          variant={ButtonVariantOption.Outlined}
          size={SizingWrapperStyle.SMALL}
        />
      </Box>
    </SectionPaper>
  );
};
