import {
  type Coords,
  type DoneCallback,
  TileLayer,
  type TileLayerOptions,
} from "leaflet";
import {
  createElementObject,
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from "@react-leaflet/core";
import { type TileLayerProps } from "react-leaflet";
import * as Sentry from "@sentry/react";

/**
 * 画像取得用関数
 * @param url 画像URL
 * @param callback 取得完了時の処理
 * @param headers 付与したいHTTPヘッダー
 * @description https://github.com/jaq316/leaflet-header
 */
async function fetchImage(
  url: string,
  callback: (resp: Blob) => void,
  headers: Array<{ header: string; value: string }> | undefined
): Promise<void> {
  const _headers: Record<string, string> = {};
  if (headers) {
    headers.forEach((h) => {
      _headers[h.header] = h.value;
    });
  }
  const f = await fetch(url, {
    method: "GET",
    headers: _headers,
    mode: "cors",
  }).catch((error) => {
    console.error("ZenrinAPI 接続エラー", error);
    Sentry.captureException(error);
  });

  if (f) {
    if (f.ok) {
      const blob = await f.blob();
      callback(blob);
    } else {
      console.error("ZenrinAPI HTTPエラー", f.statusText);
      Sentry.captureException(`ZenrinAPI HTTPエラー${f.statusText}`);
    }
  }
}

/**
 * タイルレイヤー生成処理＋HTTPヘッダー付与
 * 下記URLのコードを参考に実装
 * https://github.com/jaq316/leaflet-header
 */
class TileLayerWithHeaders extends TileLayer {
  private readonly headers:
    | Array<{ header: string; value: string }>
    | undefined;

  constructor(
    url: string,
    options?: TileLayerOptions,
    headers?: Array<{ header: string; value: string }>
  ) {
    super(url, options);
    this.headers = headers;
  }

  protected createTile(coords: Coords, done: DoneCallback): HTMLImageElement {
    const url = this.getTileUrl(coords);
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");

    void fetchImage(
      url,
      (resp: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result === "string") {
            img.src = reader.result;
          }
        };
        reader.readAsDataURL(resp);
        done(undefined, img);
      },
      this.headers
    );
    return img;
  }
}

/**
 * ゼンリン住宅地図を返すコンポーネント
 */
export const ZenrinTileLayer = createTileLayerComponent(
  function createTileLayer({ url, ...options }: TileLayerProps, context) {
    // Headers型を使いたいがCORSエラーとなる
    // https://qiita.com/mtoutside/items/cee708841cad7e02f85c
    const headers = [
      { header: "Authorization", value: "referer" },
      {
        header: "x-api-key",
        value: "n3y4zp5GaJ1JnGRRh4gB3aVi0uxI4zYE3Ghxw9vc",
      },
    ];
    const layer = new TileLayerWithHeaders(
      url,
      withPane(options, context),
      headers
    );
    return createElementObject(layer, context);
  },
  function updateTileLayer(layer, props, prevProps) {
    updateGridLayer(layer, props, prevProps);
    const { url } = props;
    if (url != null && url !== prevProps.url) {
      layer.setUrl(url);
    }
  }
);
