import { styled } from "@mui/material/styles";
import { CustomerManagementListDataGrid } from "../../../components/DataGrid";
import {
  type GridSelectionModel,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";

export interface CustomerSearchDataGridRow {
  id: number; // ID
  realEstateType: string | null; // 種別
  originalShozai: string; // 物件情報
  postcode: string | null; // 郵便番号
  ownerAddress: string; // 所有者住所
  ownerName: string; // 所有者氏名
  legalAffairsBureauRequestDate: string | null; // 登記申請年月日
  mochibunStr: string | null; // 持分
  receptionReason: string | null; // 登記原因
}

const StyledDataGrid = styled(CustomerManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  // データグリッドのルートに対して border-radius を設定
  &.MuiDataGrid-root {
    border-radius: 0px !important;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "realEstateType",
      headerName: "種別",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "originalShozai",
      headerName: "物件情報",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "postcode",
      headerName: "郵便番号",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ownerAddress",
      headerName: "所有者住所",
      width: 240,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ownerName",
      headerName: "所有者氏名",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "legalAffairsBureauRequestDate",
      headerName: "登記申請年月",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value != null) {
          const date = new Date(value);
          return format(date, "yyyy/MM");
        }
        return "";
      },
    },
    {
      field: "mochibunStr",
      headerName: "持分",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "receptionReason",
      headerName: "登記原因",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];
};

export const CustomerSerachResultFeedGrid: React.FC<{
  rows: CustomerSearchDataGridRow[];
  selectedRowIds: GridSelectionModel;
  handleRowSelectionChange: (newSelectionModel: GridSelectionModel) => void;
}> = ({ rows, selectedRowIds, handleRowSelectionChange }) => {
  const colDef = makeGridColDef();

  return (
    <StyledDataGrid
      checkboxSelection
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      onSelectionModelChange={handleRowSelectionChange}
      selectionModel={selectedRowIds}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
