import React from "react";

interface ValidateItem<T> {
  state: ValidateState;
  setState: React.Dispatch<React.SetStateAction<ValidateState>>;
  validate: (data: T) => boolean;
}
interface ValidateState {
  helperTextWhenError: string;
  error: boolean;
}

interface Validator {
  identifierNumber: ValidateItem<string>;
  identifierName: ValidateItem<string>;
}

const useValidator = (): Validator => {
  const [stateIdentifierNumber, setStateIdentifierNumber] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateIdentifierName, setStateIdentifierName] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });

  const identifierNumber: ValidateItem<string> = {
    state: stateIdentifierNumber,
    setState: setStateIdentifierNumber,
    validate: (data: string): boolean => {
      if (data !== "") {
        const re = /^\d*$/;
        if (re.test(data)) {
          // 入力あり 半角数値のみ -> OK
          const toInt = parseInt(data);
          // PostgresのIntegerの限界
          if (toInt > 2147483647) {
            setStateIdentifierNumber({ error: true, helperTextWhenError: "" });
            return false;
          }
          setStateIdentifierNumber({ error: false, helperTextWhenError: "" });
          return true;
        } else {
          // 入力あり 半角数値以外の値あり -> NG
          setStateIdentifierNumber({ error: true, helperTextWhenError: "" });
          return false;
        }
      } else {
        // 空欄 -> NG
        setStateIdentifierNumber({ error: true, helperTextWhenError: "" });
        return false;
      }
    },
  };
  const identifierName: ValidateItem<string> = {
    state: stateIdentifierName,
    setState: setStateIdentifierName,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateIdentifierName({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateIdentifierName({ error: true, helperTextWhenError: "" });
        return false;
      }
    },
  };

  return {
    identifierNumber,
    identifierName,
  };
};

export { useValidator };
