import * as React from "react";
import {
  MultiSelect,
  type MultiSelectIProps,
} from "@/components/Select/MultiSelect";
import { type Control, Controller, type FieldValues } from "react-hook-form";
import type { FieldPath } from "react-hook-form/dist/types";
import type { ReactElement } from "react";

interface IProps<T extends FieldValues>
  extends Omit<MultiSelectIProps, "value" | "onChange"> {
  control: Control<T> | undefined;
  name: FieldPath<T>;
}

/**
 * MultiSelectコンポーネントのコントローラー対応版
 * コントローラーの詳細はReact-Hook-Formのドキュメントを参照
 * https://www.react-hook-form.com/api/usecontroller/controller/
 * @param props
 */
const CMultiSelect = <T extends FieldValues>(
  props: IProps<T>
): ReactElement => {
  const { name, control, label, options, isClearable, maxSelect, size } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <MultiSelect
            label={label}
            value={value}
            onChange={onChange}
            options={options}
            maxSelect={maxSelect}
            isClearable={isClearable}
            size={size}
            error={Boolean(error)}
            formHelperText={error?.message}
          />
        );
      }}
    />
  );
};

export { CMultiSelect };
