import { SectionPaper } from "@/components/Paper";
import { Select } from "@/components/Select";
import { SectionTitle } from "@/components/Title";
import { ConsumeCreditGrid } from "@/features/accountSettings/components";
import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { type MonthlyBudgetStatus } from "@/features/accountSettings/types";
import { MonthlyBudgetStatusListAPI } from "@/features/accountSettings/api";
import { format } from "date-fns";

export const ConsumeCredit: React.FC = (props) => {
  const [monthlyBudgetStatus, setMonthlyBudgetStatus] = useState<
    MonthlyBudgetStatus | undefined
  >(undefined);

  const monthlyBudgetStatusListResponse = MonthlyBudgetStatusListAPI();

  const monthlyBudgetStatuses: MonthlyBudgetStatus[] =
    monthlyBudgetStatusListResponse.map<MonthlyBudgetStatus>((data) => {
      return {
        planPrice: data.planPrice,
        carryoverMoney: data.carryoverMoney,
        budget: data.budget,
        remainingBudget: data.remainingBudget,
        id: data.id ?? 0,
        totalConsumeCredit: data.totalConsumeCredit ?? 0,
        tax: data.tax ?? 0,
        billingDate: data.billingDate ? new Date(data.billingDate) : new Date(),
      };
    });

  const handleChange = (value: string): void => {
    const selectedMonthlyBudgetStatus = monthlyBudgetStatuses.find(
      (e) => e.id === Number(value)
    );

    setMonthlyBudgetStatus(selectedMonthlyBudgetStatus);
  };

  /**
   * 請求月から使用月を取得する。当月の場合は「当月」を返す。
   * @param monthlyBudgetStatus - 月次予算状況
   * @returns 使用年月のテキスト
   */
  const getUsageYearMonthText = (
    monthlyBudgetStatus: MonthlyBudgetStatus
  ): string => {
    const isCurrentMonth =
      monthlyBudgetStatus.billingDate.toLocaleDateString() ===
      new Date().toLocaleDateString();
    return isCurrentMonth
      ? "当月"
      : format(monthlyBudgetStatus.billingDate, "yyyy年MM月");
  };

  return (
    <SectionPaper>
      <Box sx={{ display: "flex" }}>
        <SectionTitle>消費クレジット</SectionTitle>
        <Box sx={{ mx: "auto" }}></Box>
        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
          <Select
            label="月"
            isClearable={false}
            options={monthlyBudgetStatuses.map((monthlyBudgetStatus) => {
              return {
                label: getUsageYearMonthText(monthlyBudgetStatus),
                value: String(monthlyBudgetStatus.id),
              };
            })}
            onChange={handleChange}
          ></Select>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <SectionTitle>
          {monthlyBudgetStatus !== undefined
            ? `${getUsageYearMonthText(monthlyBudgetStatus)}の予算`
            : "予算"}
          ：{monthlyBudgetStatus?.budget.toLocaleString() ?? 0}
          円（契約：{monthlyBudgetStatus?.planPrice.toLocaleString() ?? 0}円
          ＋前月からの繰越：
          {(
            (monthlyBudgetStatus?.budget ?? 0) -
            (monthlyBudgetStatus?.planPrice ?? 0)
          ).toLocaleString()}
          円）
        </SectionTitle>
        <SectionTitle>
          残り予算：{monthlyBudgetStatus?.remainingBudget.toLocaleString() ?? 0}
          円
        </SectionTitle>
        <Box sx={{ mx: "auto" }}></Box>
        <SectionTitle>
          消費金額合計：
          {monthlyBudgetStatus?.totalConsumeCredit.toLocaleString() ?? 0}円
        </SectionTitle>
      </Box>
      {monthlyBudgetStatus ? (
        <ConsumeCreditGrid
          date={monthlyBudgetStatus.billingDate}
        ></ConsumeCreditGrid>
      ) : null}
    </SectionPaper>
  );
};
