import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";

export interface ConsumeDetailResponse {
  id: number;
  quantity: number;
  itemName: string;
  consumeCredit: number;
  createdAt: string;
}

export const ConsumeDetailsAPI = (
  year: number,
  month: number
): ConsumeDetailResponse[] => {
  const { apiClient } = useApiClient();
  const params = {
    year,
    month,
  };
  const url = "/credit/cunsume-details";

  const fetcher = async (): Promise<ConsumeDetailResponse[]> => {
    return (
      await apiClient.get<ConsumeDetailResponse[]>(url, {
        params,
      })
    ).data;
  };
  const { data: consumesDetail } = useSWR({ url, params }, fetcher, {
    revalidateOnFocus: false,
  });

  return consumesDetail ?? [];
};
