import { useApiClient } from "../../../hooks/useApiClient";
import useSWR from "swr";
import { type IStationResponse } from "../types";
import { type ISelectObject } from "../../../types/select";

interface IUseStationOptionsReturnType {
  stationOptions: ISelectObject[];
}

// 指定したstationLine(数字文字列)から駅の一覧を取得するcustom hook
export const useStationOptions = (
  stationLine: number
): IUseStationOptionsReturnType => {
  const { apiClient } = useApiClient();

  // swrに引き渡すfetcher
  const fetcher = async (url: string): Promise<IStationResponse> => {
    const { data } = await apiClient.get<IStationResponse>(url);
    return data;
  };

  const { data } = useSWR<IStationResponse>(
    stationLine === -1 ? null : `/option/station-names?line_id=${stationLine}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  if (data === undefined) {
    return { stationOptions: [] };
  }
  return { stationOptions: data.list };
};
