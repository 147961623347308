import { type NewsSummary } from "@/features/mypage/api";
import { Box, Grid, Typography, type SxProps, type Theme } from "@mui/material";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { red, blue, green } from "@mui/material/colors";

export const News: React.FC<NewsSummary> = (props) => {
  const newsRecordSx: SxProps<Theme> = {
    // NOTE: Dividerを入れても反映されないのでCSSのボーダで表現
    borderColor: "#AFAFAF",
    borderStyle: "solid",
    borderWidth: "0 0 2px 0",
    padding: ".5em",
  };

  const newsDateTextSx: SxProps<Theme> = {
    color: "#000000",
    alignContent: "center",
  };
  const newsSummaryTextSx: SxProps<Theme> = {
    color: "#000000",
    alignContent: "center",
    padding: "0 0 0 1.5em ",
  };

  const categorySxDefinition: Record<string, SxProps<Theme>> = {
    お知らせ: {
      color: blue[700],
      borderColor: blue[700],
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: 2,
      padding: "2px 0 2px 0",
      textAlign: "center",
    },
    メンテナンス: {
      color: red[700],
      borderColor: red[700],
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: 2,
      padding: "2px 0 2px 0",
      textAlign: "center",
    },
    その他: {
      color: blue[700],
      borderColor: green[700],
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: 2,
      padding: "2px 0 2px 0",
      textAlign: "center",
    },
  };

  const theme = createTheme();
  theme.typography.h5 = {
    fontSize: "16px",
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  };

  const getCategorySx = (category: string): SxProps<Theme> => {
    return categorySxDefinition[category] ?? categorySxDefinition["お知らせ"];
  };

  return (
    <>
      <Box sx={newsRecordSx}>
        <Grid container direction="row">
          <Grid item xs={5} sm={4} md={2} lg={1} xl={1} sx={newsDateTextSx}>
            <ThemeProvider theme={theme}>
              <Typography variant="h5">
                {format(new Date(props.publishedAt), "yyyy.MM.dd", {
                  locale: ja,
                })}
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid
            item
            xs={7}
            sm={7}
            md={2}
            lg={1.5}
            xl={1}
            sx={getCategorySx(props.category)}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="h5">{props.category}</Typography>
            </ThemeProvider>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={9.5}
            xl={10}
            sx={newsSummaryTextSx}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="h5">{props.title}</Typography>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
