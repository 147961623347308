import { toast } from "react-toastify";
import { useMailTemplateListRequest } from "./useMailTemplateListRequest";
import { useApiClient } from "@/hooks/useApiClient";

interface DeleteMailTemplateResponse {
  updatedCount: number;
}

export const useDeleteMailTemplateRequest = (): ((ids: number[]) => void) => {
  const { apiClient } = useApiClient();
  const { refetch } = useMailTemplateListRequest();
  const deleteMailTemplate = (ids: number[]): void => {
    apiClient
      .delete<DeleteMailTemplateResponse>("/mail/template", {
        data: { mail_send_template_ids: ids },
      })
      .then(async (_res) => {
        await refetch();
        toast.success("メールテンプレートを削除しました。");
      })
      .catch((e) => {
        console.error(e);
        toast.error("メールテンプレートを削除に失敗しました");
      });
  };
  return deleteMailTemplate;
};
