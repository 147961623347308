import { styled } from "@mui/material/styles";
import { CustomerManagementListDataGrid } from "../../../components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { ButtonVariantOption, LinkButton } from "../../../components/Button";
import { SizingWrapperStyle } from "../../../components/Wrapper";
import type { CustomerStatusDataGridRow } from "../types";
import { format } from "date-fns";
import { RealEstateOwnerStatusLabels } from "@/types/realEstateOwner";

const StyledDataGrid = styled(CustomerManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  // データグリッドのルートに対して border-radius を設定
  &.MuiDataGrid-root {
    border-radius: 0px !important;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "status",
      headerName: "ステータス",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<string>) =>
        RealEstateOwnerStatusLabels[
          params.value as keyof typeof RealEstateOwnerStatusLabels
        ],
    },
    {
      field: "realEstateType",
      headerName: "種別",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "originalShozai",
      headerName: "物件情報",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "連絡メールアドレス",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tel",
      headerName: "電話番号",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "postcode",
      headerName: "郵便番号",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ownerAddress",
      headerName: "所有者住所",
      width: 240,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ownerName",
      headerName: "所有者氏名",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "legalAffairsBureauRequestDate",
      headerName: "登記申請年月",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value != null) {
          const date = new Date(value);
          return format(date, "yyyy/MM");
        }
        return "";
      },
    },
    {
      field: "mochibunStr",
      headerName: "持分",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "responsiblePersonName",
      headerName: "担当者名",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "receptionReason",
      headerName: "登記原因",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ id }: GridRenderCellParams<string>) => {
        return (
          <LinkButton
            variant={ButtonVariantOption.Contained}
            wrapperSize={SizingWrapperStyle.SMALL}
            href={`/customermanagement/${id}/`}
          >
            詳細
          </LinkButton>
        );
      },
    },
  ];
};

export const FeedGrid: React.FC<{ rows: CustomerStatusDataGridRow[] }> = (
  props
) => {
  const { rows } = props;
  const colDef = makeGridColDef();

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
