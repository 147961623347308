import { type GridSortDirection, type GridSortModel } from "@mui/x-data-grid";
import type React from "react";
import { useEffect } from "react";
import {
  type IRealEstateReceptionBookDataGridRow,
  type IMultipleBookAcquireData,
  type IUseFeedParams,
} from "../types";
import { useFeed } from "./useFeed";
import { type GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import { type GridCallbackDetails } from "@mui/x-data-grid/models/api";
import {
  type AcquireMultipleBookHandoffData,
  type BookTypeJpn,
} from "../../../types/acquirebook";
import { convertBookTypeToEng } from "../../../utils/utilsAcquireBook";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import type { User } from "@/types/localstorage";
import { toGridRows } from "../utils/feedUtils";

interface IUseFeedGridParams extends Omit<IUseFeedParams, "size"> {
  page: number;
  pageSize: number;
  setMultipleBookAcquireData: React.Dispatch<
    React.SetStateAction<IMultipleBookAcquireData>
  >;
}

interface IUseFeedGridReturnType {
  rows: IRealEstateReceptionBookDataGridRow[];
  allCount: number;
  loading: boolean;
  from: number;
  prev: () => void;
  next: () => void;
  sortByGridSortModel: (sortModel: GridSortModel | undefined) => void;
  selectionModelChange: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails
  ) => void;
}

const FEED_FETCH_SIZE = 1000;

// useFeedの結果を、DataGridに表示するためのデータに変換して返すcustom hook
export const useGridFeed = (
  params: IUseFeedGridParams
): IUseFeedGridReturnType => {
  const { page, pageSize, setMultipleBookAcquireData, ...feedParams } = params;
  const { feed, allCount, from, prev, next, sort } = useFeed({
    ...feedParams,
    size: FEED_FETCH_SIZE,
  });
  const [user] = useLocalStorage<User | undefined>("user", undefined);

  // 現在取得済みの件数よりも、表示するページの件数が多い場合は、次のページを取得する
  useEffect(() => {
    const pageNumberForCalcDataset = page + 1;
    if (feed === null || feed === undefined) return;
    if (pageNumberForCalcDataset * pageSize <= feed.list.length) return;
    next();
  }, [page, pageSize]);

  // MUIのDataGridのソート条件を、APIのソート条件に変換する
  const sortByGridSortModel = (sortModel: GridSortModel | undefined): void => {
    sort(toSortCondition(sortModel));
  };

  // dataGridの行が選択された時に呼び出される処理
  const selectionModelChange = (
    selectionModel: GridSelectionModel,
    _details: GridCallbackDetails
  ): void => {
    if (feed) {
      // selectionModel: 選択された項目のIDがリストで入っている
      // feedからselectionModelと同じIDを持っている項目を一つ取り出し、AcquireBookCsvUploadResponse型で出力する
      const selectedItem = selectionModel.map(
        (rowid, index): AcquireMultipleBookHandoffData => {
          const row = feed.list.filter((value) => value.id === rowid)[0];

          return {
            id: index + 1,
            bookId: row.id,
            bookType: convertBookTypeToEng(
              row.realEstateTypeName as BookTypeJpn
            ),
            prefCode: row.prefecturesId.toString(),
            prefName: row.prefecturesName,
            locationFull: row.address,
            chibanKaokuNumber: row.chiban + row.kaokuNumber,
            kyoutan: false,
            sintaku: false,
            genzai: false,
            ownerInfo: false,
            electricDrawings: false,
            tisekiDrawings: false,
            tiekiDrawings: false,
            buildingDrawings: false,
          };
        }
      );

      if (selectionModel.length > 0) {
        const isFree = user?.isFree ?? false; // isFreeが読めない時は通常ユーザー扱いとする
        setMultipleBookAcquireData({
          buttonString: "選択した登記を取得",
          buttonDisabled: isFree,
          tooltipMessage: isFree ? "有料プランでご利用いただける機能です" : "",
          selectedItem,
        });
      } else {
        setMultipleBookAcquireData({
          buttonString: "不動産登記/図面取得",
          buttonDisabled: false,
          tooltipMessage: "",
          selectedItem,
        });
      }
    }
  };

  return {
    rows: toGridRows(feed),
    allCount,
    loading: feed === undefined,
    from,
    prev,
    next,
    sortByGridSortModel,
    selectionModelChange,
  };
};

/**
 * 引数の値が、キーとなるフィールド名かどうかを判定するType Guard関数
 */
const isConditionField = (
  field: string
): field is keyof Omit<IRealEstateReceptionBookDataGridRow, "id"> => {
  const keys: Array<keyof Omit<IRealEstateReceptionBookDataGridRow, "id">> = [
    "chiban",
    "kaokuNumber",
    "realEstateBookType",
    "receptionReason",
    "realEstateType",
    "receptionKind",
    "prefectures",
    "city",
    "address",
    "outside",
    "legalAffairsBureauRequestDate",
    "legalAffairsBureauReceptionNumber",
    "estimatedChiseki",
    "publishedPrice",
    "estimatedPrice",
    "areaUsePurpose",
    "buildingRate",
    "volumeRate",
    "isTowerApartment",
    "isOneRoom",
    "isPlant",
    "isRenewableEnergy",
    "propertyName",
    "buildDate",
    "walk",
    "station",
    "farmlandArea",
    "farmlandCategory",
  ];

  return keys.some((key) => key === field);
};

/**
 * 引数がソート順かどうかを判定するType Guard関数
 */
const isSortOrder = (order: GridSortDirection): order is "asc" | "desc" => {
  if (order === undefined || order === null) return false;
  return ["asc", "desc"].includes(order);
};

/**
 * MUI Data Gridのソート条件を、APIのソート条件に変換する関数
 */
const toSortCondition = (
  model: GridSortModel | undefined
): Parameters<ReturnType<typeof useFeed>["sort"]>[0] | undefined => {
  if (model === undefined || model.length === 0) return undefined;

  // 無料版のMUI Data Gridは複数列のソートに対応していないので、
  // 1列目のみをソート条件として扱う
  const { field, sort } = model[0];
  if (!isConditionField(field) || !isSortOrder(sort)) return undefined;
  return { sortBy: field, order: sort };
};
