import { useApiClient } from "@/hooks/useApiClient";
import { type AxiosError } from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  type MailSendToCountAPIResponse,
  getMailSendToCountAPIUrl,
  getMailSendToCountAPIfetcher,
  type MailSendToCountAPIRequest,
} from "../api/GetMailSendToCountAPI";

export const useMailSendToCountAPI = (
  params: MailSendToCountAPIRequest
): {
  data: MailSendToCountAPIResponse | undefined;
  refetch: () => Promise<void>;
} => {
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  const fetcher = getMailSendToCountAPIfetcher(apiClient, params);

  const { data, error } = useSWR<MailSendToCountAPIResponse, AxiosError>(
    getMailSendToCountAPIUrl,
    fetcher,
    {
      revalidateOnFocus: true,
    }
  );

  const refetch = async (): Promise<void> => {
    await mutate(getMailSendToCountAPIUrl());
  };

  React.useEffect(() => {
    if (error?.response?.status && error.response.status >= 500) {
      toast.error("メール送信先件数の取得に失敗しました。");
    }
  }, [error, navigate]);

  return { data, refetch };
};
