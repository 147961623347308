import { HttpStatusCode, type AxiosInstance } from "axios";
import type {
  CancelMailDetailAPIRequest,
  CancelMailDetailAPIResponse,
} from "../types";

/**
 * メール送信取り消しAPI
 * @param {CancelMailDetailAPIRequest} data - データ
 * @returns {CancelMailDetailAPIResponse | undefined} CancelMailDetailAPIResponse | undefined
 */
export const CancelMailDetailAPI = async (
  apiClient: AxiosInstance,
  data: CancelMailDetailAPIRequest
): Promise<CancelMailDetailAPIResponse | undefined> => {
  const { mailSendIds } = data;
  const response = await apiClient.put<CancelMailDetailAPIResponse>("/mail/", {
    mailSendIds,
  });
  return response.status === HttpStatusCode.Ok ? response.data : undefined;
};
