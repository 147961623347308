import { useApiClient } from "../../../hooks/useApiClient";
import useSWR from "swr";
import { type AcquireBookStatusResponse } from "../types";
import {
  isScrapingOutOfService,
  getRemainingCountWithErrorCount,
  getStatusString,
  type AcquireBookStatusString,
} from "../../../utils/utilsBookStatus";

interface UseAcquireBookStatus {
  data: AcquireBookStatusResponse | undefined;
  getStatusString: (
    data: AcquireBookStatusResponse | undefined
  ) => AcquireBookStatusString | "";
  getRemainingCountWithErrorCount: (
    data: AcquireBookStatusResponse | undefined
  ) => [number | null, number | null];
  isScrapingOutOfService: () => boolean;
}

export const useAcquireBookStatus = (
  id: string | undefined
): UseAcquireBookStatus => {
  const { apiClient } = useApiClient();

  const fetcher = async (url: string): Promise<AcquireBookStatusResponse> => {
    const { data } = await apiClient.get<AcquireBookStatusResponse>(url);
    return data;
  };

  const { data } = useSWR<AcquireBookStatusResponse>(
    id === undefined ? null : `/picture-request/${id}`,
    fetcher,
    {
      revalidateOnFocus: true,
      refreshInterval: 1000 * 30,
    }
  );

  return {
    data,
    getStatusString,
    getRemainingCountWithErrorCount,
    isScrapingOutOfService,
  };
};
