import { DataGrid } from "@/components/DataGrid";
import { ConsumeDetailsAPI } from "@/features/accountSettings/api";
import { type GridColDef } from "@mui/x-data-grid";

interface ConsumeCreditHeaders
  extends Omit<GridColDef, "field" | "headerName" | "width"> {
  field: keyof ConsumeCreditRow;
  headerName: string;
  width: number;
}

interface ConsumeCreditRow {
  id: number;
  date: string;
  item: string;
  quantity: number;
  price: string;
}

interface Props {
  date: Date;
}

const culumn: ConsumeCreditHeaders[] = [
  {
    field: "date",
    headerName: "日時",
    width: 180,
  },
  {
    field: "item",
    headerName: "商品",
    width: 220,
  },
  {
    field: "quantity",
    headerName: "数量",
    width: 120,
  },
  {
    field: "price",
    headerName: "小計",
    width: 120,
  },
];

export const ConsumeCreditGrid: React.FC<Props> = (props) => {
  const { date } = props;
  const consumeDetailsRes = ConsumeDetailsAPI(
    date.getFullYear(),
    date.getMonth() + 1
  );
  const consumeDetails = consumeDetailsRes.map<ConsumeCreditRow>(
    (consumeDetail) => {
      return {
        id: consumeDetail.id,
        item: consumeDetail.itemName,
        quantity: consumeDetail.quantity,
        price: consumeDetail.consumeCredit.toLocaleString(),
        date: consumeDetail.createdAt,
      };
    }
  );
  return <DataGrid rows={consumeDetails} columns={culumn}></DataGrid>;
};
