import { useApiClient } from "@/hooks/useApiClient";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetMonitoringDetailReceptionBookAPI } from "../api";
import {
  type MonitoringDetailRow,
  type MonitoringDetailReceptionBookRow,
} from "../types";
import { PagePaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { MonitoringDetailReceptionBookDataGrid } from "../components/MonitoringDetailReceptionBookDataGrid";
import { convertBookTypeToJpn } from "@/utils/utilsAcquireBook";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

/**
 * モニタリング詳細受付帳結果画面
 */
export const MonitoringDetailReceptionBook: React.FC = () => {
  const { id } = useParams();
  const { apiClient } = useApiClient();

  const [monitoringDetail, setMonitoringDetail] =
    useState<MonitoringDetailRow | null>(null);
  const [rows, setRows] = useState<MonitoringDetailReceptionBookRow[]>([]); // 受付帳結果用State

  useEffect(() => {
    (async () => {
      const response = await GetMonitoringDetailReceptionBookAPI(apiClient, {
        id: Number(id),
      });

      setMonitoringDetail({
        ...response.detail,
        realEstateTypeJpn: convertBookTypeToJpn(response.detail.realEstateType), // 種別を英語から日本語に変換。
      });
      setRows(response.results);
    })();
  }, []);

  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <MonitorHeartIcon sx={{ mr: 1 }} fontSize="large" />
        <PageTitle>不動産登記モニタリング詳細受付帳結果</PageTitle>
      </Box>

      {/* モニタリング詳細の情報 */}
      <Stack direction={"column"} spacing={1} sx={{ mt: 3 }}>
        <Stack direction={"row"} spacing={1}>
          <Typography>不動産種別：</Typography>
          <Typography>{monitoringDetail?.realEstateTypeJpn}</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>所在：</Typography>
          <Typography>{monitoringDetail?.location}</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>地番家屋番号：</Typography>
          <Typography>{monitoringDetail?.chibanKaokuNumber}</Typography>
        </Stack>
      </Stack>

      {/* 結果 */}
      <Box sx={{ mt: 3 }}>
        <MonitoringDetailReceptionBookDataGrid rows={rows} />
      </Box>

      {/* 戻るリンク */}
      <Box sx={{ mt: 3 }}>
        <Link
          href={
            monitoringDetail != null
              ? `/monitoring/${monitoringDetail.monitoringId}`
              : "/monitoring"
          }
          sx={{ display: "inline-block" }}
        >
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>不動産登記モニタリング詳細に戻る</Typography>
          </Box>
        </Link>
      </Box>
    </PagePaper>
  );
};
