import { ButtonVariantOption, ButtonColorOption } from "@/components/Button";
import { StyledDataGrid } from "./DataGrid";
import { type GeoSpaceChiban, type PropertySelectionRow } from "../types";
import {
  type GridColDef,
  type GridSelectionModel,
  type GridRenderCellParams,
  type GridColumnHeaderParams,
} from "@mui/x-data-grid";
import { useCallback, useState, useEffect } from "react";
import { Box, Button as MuiButton, Typography } from "@mui/material";
import { SizingWrapperStyle } from "@/components/Wrapper";
import {
  convertTypeEnglishToJapanese,
  formatArea,
  skipSelectChiban,
} from "../utils";
import { type BookTypeEng } from "@/types/acquirebook";

interface IProps {
  rows: PropertySelectionRow[];
  setRows: React.Dispatch<React.SetStateAction<PropertySelectionRow[]>>;
  setSelectedProperties: React.Dispatch<
    React.SetStateAction<PropertySelectionRow[]>
  >;
  setRangeSelectModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buildLocationString: (r: GeoSpaceChiban) => string;
  checkDuplicateProperties: (
    rows: PropertySelectionRow[],
    bookType: BookTypeEng,
    prefName: string,
    location: string,
    chiban: string,
    area: number | null
  ) => boolean;
  geospaceReverseByShapeResult: GeoSpaceChiban | undefined;
}

export const RangeSelectResultModal: React.FC<IProps> = (props) => {
  const {
    rows,
    setRows,
    setSelectedProperties,
    setRangeSelectModalIsOpen,
    checkDuplicateProperties,
    buildLocationString,
    geospaceReverseByShapeResult,
  } = props;

  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>(() =>
    rows.filter((row) => row.selected).map((row) => row.id)
  );

  useEffect(() => {
    setSelectedRowIds(rows.filter((row) => row.selected).map((row) => row.id));
  }, [rows]);

  const handleRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedRowIds(newSelectionModel);
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          selected: newSelectionModel.includes(row.id),
        }))
      );
    },
    [setRows]
  );

  const [deletedRows, setDeletedRows] = useState<PropertySelectionRow[]>([]);

  const handleDelete = useCallback(
    (id: number) => {
      setRows((prevRows) => {
        // 削除する前に、削除対象の行を取得
        const rowToDelete = prevRows.find((row) => row.id === id);

        // 削除対象の行を配列に追加
        if (rowToDelete) {
          setDeletedRows((prevDeletedRows) => [
            ...prevDeletedRows,
            rowToDelete,
          ]);
        }

        // 行を削除して新しい配列を返す
        return prevRows.filter((row) => row.id !== id);
      });
    },
    [setRows]
  );

  const convertFullWidthDigitsAndHyphenToHalfWidth = (str: string): string => {
    return str.replace(/[０-９－]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
    });
  };

  // カラム設定
  const columns: GridColDef[] = [
    {
      field: "id",
      align: "center",
    },
    {
      field: "bookType",
      headerName: "種別",
      sortable: false,
      align: "center",
      flex: 0.5,
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return convertTypeEnglishToJapanese(String(value));
      },
    },
    {
      field: "prefName",
      headerName: "都道府県",
      sortable: false,
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "所在",
      sortable: false,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        // 長い文字列の場合は折り返す
        return <Box sx={{ whiteSpace: "normal" }}>{value}</Box>;
      },
    },
    {
      field: "chibanKaokuNumber",
      headerName: "地番または家屋番号",
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return convertFullWidthDigitsAndHyphenToHalfWidth(value as string);
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          地番または
          <br />
          家屋番号
        </strong>
      ),
    },
    {
      field: "area",
      headerName: "推計地積(㎡)",
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return formatArea(value as number);
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          推計地積
          <br />
          (㎡)
        </strong>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box>
            <MuiButton
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Outlined}
              color={ButtonColorOption.Error}
              onClick={() => {
                handleDelete(params.id as number);
              }}
            >
              削除する
            </MuiButton>
          </Box>
        );
      },
    },
  ];

  // 物件リストに追加をクリックした時の処理
  const handleOkClick = (): void => {
    if (Array.isArray(geospaceReverseByShapeResult)) {
      geospaceReverseByShapeResult.forEach((result: GeoSpaceChiban) => {
        // 日本語の文字またはアルファベットを含む場合はスキップ（CHIBAN_FULL_PATTERNは除く）
        if (skipSelectChiban(result.chiban)) {
          return;
        }

        // 謄本取得物件選択テーブルに追加
        setSelectedProperties((prev) => {
          const bookType = "LAND" as BookTypeEng;
          // 重複チェック
          if (
            checkDuplicateProperties(
              prev,
              bookType,
              result.prefName,
              buildLocationString(result),
              result.chiban,
              result.area
            )
          ) {
            return prev;
          }

          // モーダルで削除された行と全てのプロパティが一致する場合は追加しない
          if (
            deletedRows.some(
              (deletedRow) =>
                deletedRow.bookType === bookType &&
                deletedRow.prefName === result.prefName &&
                deletedRow.location === buildLocationString(result) &&
                deletedRow.chibanKaokuNumber === result.chiban &&
                deletedRow.area === result.area &&
                JSON.stringify(deletedRow.rawData) === JSON.stringify(result)
            )
          ) {
            return prev;
          }

          const lastId: number = prev.length > 0 ? prev[prev.length - 1].id : 0;
          return [
            ...prev,
            {
              id: lastId + 1,
              selected: true,
              bookType,
              prefName: result.prefName,
              location: buildLocationString(result),
              chibanKaokuNumber: result.chiban,
              area: result.area,
              isFeedOrigin: false,
              rawData: result,
            },
          ].sort((a, b) => a.id - b.id);
        });
      });

      // テーブルを初期化
      setRows([]);
      setRangeSelectModalIsOpen(false);
    }
  };

  // キャンセルをクリックした時の処理
  const handleChancelClick = (): void => {
    // テーブルを初期化
    setRows([]);
    setRangeSelectModalIsOpen(false);
  };

  // rowsの個数を計算
  const rowCount = rows.length;

  return (
    <Box
      sx={{
        height: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {rowCount > 0
          ? `${rowCount} 件のデータ取得が完了しました。道などの情報は除外されています。`
          : "データがありません。"}
      </Typography>

      <Box sx={{ flexGrow: 1, mb: 2 }}>
        <StyledDataGrid
          disableColumnMenu
          disableSelectionOnClick={true}
          rows={rows}
          pageSize={50}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          onSelectionModelChange={handleRowSelectionChange}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <MuiButton
          onClick={handleChancelClick}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          キャンセル
        </MuiButton>
        <MuiButton
          onClick={handleOkClick}
          sx={{ textTransform: "none" }}
          variant="contained"
        >
          物件リストに追加
        </MuiButton>
      </Box>
    </Box>
  );
};
