import { createContext, useCallback, useState } from "react";

interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  isFree: boolean;
  isStaff: boolean;
  email: string;
}

interface UserDataContext {
  data: UserData;
  setUserData: (current: UserData) => void;
}

const getDefaultUseData = (): UserData => {
  const storedData = localStorage.getItem("user");
  const storedUserData = storedData
    ? (JSON.parse(storedData) as Record<string, unknown>)
    : null;
  return {
    id: typeof storedUserData?.id === "string" ? storedUserData.id : "",
    firstName:
      typeof storedUserData?.firstName === "string"
        ? storedUserData.firstName
        : "",
    lastName:
      typeof storedUserData?.lastName === "string"
        ? storedUserData.lastName
        : "",
    isFree:
      typeof storedUserData?.isFree === "boolean"
        ? storedUserData.isFree
        : false,
    isStaff:
      typeof storedUserData?.isStaff === "boolean"
        ? storedUserData.isStaff
        : false,
    email:
      typeof storedUserData?.email === "string" ? storedUserData.email : "",
  };
};

const defaultContext: UserDataContext = {
  data: getDefaultUseData(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserData: () => {},
};

export const userDataContext = createContext<UserDataContext>(defaultContext);

export const useUserData = (): UserDataContext => {
  const [data, setData] = useState<UserData>(getDefaultUseData());

  const setUserData = useCallback((current: UserData): void => {
    setData(current);
  }, []);

  return { data, setUserData };
};
