import React from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

export const CustomerManagementLink: React.FC = () => {
  return (
    <LinkButton
      variant={ButtonVariantOption.Text}
      startIcon={<ManageAccountsIcon />}
      href={"/customermanagement/customerList"}
      wrapperSize={SizingWrapperStyle.INHERIT}
      sx={{ color: "#FFF", fontWeight: "bold" }}
    >
      顧客管理
    </LinkButton>
  );
};
