import { Box } from "@mui/material";
import React from "react";
import { PagePaper } from "@/components/Paper";
import { CustomerList as CustomerListComponent } from "../components/CustomerList";
import { CustomerSearch } from "../components/CustomerSearch";
import { useSWRConfig } from "swr";
import { PageTitle } from "@/components/Title";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

export const CustomerList: React.FC = () => {
  const { mutate } = useSWRConfig();

  // 顧客一覧の再取得
  const handleCustomerRefresh = async (): Promise<void> => {
    await mutate("/real-estate-owner-customer-management/customer-list");
  };

  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <ManageSearchIcon sx={{ mr: 1 }} fontSize="large" />
        <PageTitle>顧客一覧</PageTitle>
      </Box>

      {/* 顧客検索 */}
      <CustomerSearch handleCustomerRefresh={handleCustomerRefresh} />

      <Box sx={{ mt: 3 }}>
        {/* 顧客一覧 */}
        <CustomerListComponent />
      </Box>
    </PagePaper>
  );
};
