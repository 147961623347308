import React, { useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { PageTitle } from "@/components/Title";
import type {
  CustomerStatusDataGridRow,
  DestinationConditions,
} from "@/features/customerManagement/types";
import { useApiClient } from "@/hooks/useApiClient";
import { GetConditionCustomerListAPI } from "@/features/emailManagement/api";
import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { FilterCustomerFeedGrid } from "./FilterCustomerFeedGrid";
import { CustomerListDestinationCondition } from "./CustomerListDestinationCondition";

interface Props {
  handleFilter: (
    rows: CustomerStatusDataGridRow[],
    destinationConditions: DestinationConditions
  ) => void;
  defaultDestinationConditions: DestinationConditions | null;
}

export const FilterCustomerModal: React.FC<Props> = ({
  handleFilter,
  defaultDestinationConditions,
}) => {
  // 検索結果の顧客リスト管理
  const [searchRows, setSearchRows] = useState<CustomerStatusDataGridRow[]>([]);

  // 検索条件の状態管理
  const [destinationConditions, setDestinationConditions] =
    useState<DestinationConditions>(
      defaultDestinationConditions != null
        ? defaultDestinationConditions
        : {
            email: "",
            legalAffairsBureauRequestDate: null,
            mochibun: "",
            originalShozai: "",
            postcode: "",
            receptionReason: [],
            // 不動産種別
            realEstateTypeTochi: true,
            realEstateTypeKutate: true,
            realEstateTypeTatemono: true,
            // ステータス
            statusNoApproach: true,
            statusApproach: true,
            statusRejected: true,
            statusNoResponse: true,
            statusComplaint: true,
            statusSent: true,
            statusReaction: true,
            includeEmail: true,
            includeLegalAffairsBureauRequestDate: true,
            includeMochibun: true,
            includeOriginalShozai: true,
            includePostcode: true,
            includeReceptionReason: true,
            includeRealEstateType: true,
            includeStatus: true,
          }
    );
  const { apiClient } = useApiClient();

  // 検索条件に基づいて顧客リストを取得
  const handleFiltering = async (): Promise<void> => {
    const response = await GetConditionCustomerListAPI(
      apiClient,
      "/real-estate-owner-customer-management/destination-conditions-customer-list",
      destinationConditions
    );
    setSearchRows(response);
  };

  return (
    <>
      <PageTitle>顧客絞り込み</PageTitle>
      <Stack my={2} direction="column" spacing={1}>
        <CustomerListDestinationCondition
          destinationConditions={destinationConditions}
          handleFiltering={() => {
            (async () => {
              await handleFiltering();
            })();
          }}
          setDestinationConditions={setDestinationConditions}
        />

        <Divider />

        <Stack my={2} direction="column" spacing={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              label="以下の顧客を登録"
              onClick={() => {
                handleFilter(searchRows, destinationConditions);
              }}
              variant={ButtonVariantOption.Outlined}
              size={SizingWrapperStyle.SMALL}
            />
          </Box>
        </Stack>

        <FilterCustomerFeedGrid rows={searchRows} />
      </Stack>
    </>
  );
};
