import { Box } from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption } from "@/components/Button";
import { Divider } from "@/components/Divider";
import { SectionPaper } from "@/components/Paper";
import { TextField } from "@/components/TextField";
import { DatePicker } from "@/components/DatePicker";
import { SectionTitle } from "@/components/Title";
import { SizingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import type { FilteringSectionProps } from "../types";

export const FilteringSection: React.FC<FilteringSectionProps> = ({
  keyword,
  sendDate,
  setKeyword,
  setSendDate,
  handleFiltering,
}) => {
  return (
    <SectionPaper>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SectionTitle>絞り込み</SectionTitle>
      </Box>

      <Box sx={{ mx: 2, mb: 2 }}>
        <Divider />
      </Box>

      <Box sx={{ mx: 2, mb: 2 }}>
        <SizingWrapper>
          <TextField
            id="keyword"
            label={"検索"}
            onChange={(e) => {
              setKeyword(e);
            }}
            value={keyword}
            autoFocus={false}
          />
        </SizingWrapper>
      </Box>
      <Box sx={{ mx: 2, mb: 2 }}>
        <SizingWrapper>
          <DatePicker
            label="送信日"
            value={sendDate}
            onChange={(e) => {
              if (e != null) setSendDate(e);
            }}
          />
        </SizingWrapper>
      </Box>
      <Box
        sx={{
          mr: 2,
          mb: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          label="絞り込み"
          onClick={handleFiltering}
          variant={ButtonVariantOption.Outlined}
          size={SizingWrapperStyle.SMALL}
        />
      </Box>
    </SectionPaper>
  );
};
