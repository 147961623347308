import React from "react";
import { TextField, Stack } from "@mui/material";
import { PageTitle } from "@/components/Title";

import { useMailTemplateListQueryParams } from "../../hooks/useMailTemplateListRequest";
import { MailTemplateListTable } from "./MailTemplateListTable";

interface Props {
  onSelectTemplate: (id: number) => void;
}

export const SearchMailTemplateModal: React.FC<Props> = ({
  onSelectTemplate,
}) => {
  const [keyword, setKeyword] = useMailTemplateListQueryParams();

  return (
    <>
      <PageTitle>テンプレート選択</PageTitle>
      <Stack my={2} direction="column" spacing={1}>
        <TextField
          label="検索"
          defaultValue={keyword}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            setKeyword(event.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
              setKeyword((event.target as HTMLInputElement).value);
            }
          }}
          sx={{ width: "300px" }}
        />
      </Stack>
      <MailTemplateListTable onSelectTemplate={onSelectTemplate} />
    </>
  );
};
