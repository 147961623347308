import { Chip } from "@mui/material";

interface MailStatusProps {
  status:
    | "保存"
    | "保存済み"
    | "未保存"
    | "送信待ち"
    | "送信取消済"
    | "送信済み";
}

export const MailStatus: React.FC<MailStatusProps> = ({ status }) => {
  const getStatusColor = (
    status: string
  ): "success" | "warning" | "primary" | "secondary" | "default" => {
    switch (status) {
      case "保存":
      case "保存済み":
        return "success";
      case "未保存":
        return "warning";
      case "送信待ち":
        return "primary";
      case "送信済み":
        return "secondary";
      case "送信取消済":
        return "default";
      default:
        return "default";
    }
  };
  return (
    <Chip label={status} color={getStatusColor(status)} variant="outlined" />
  );
};
