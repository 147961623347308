import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHeaderMenu } from "@/features/header/hooks/useHeaderMenu";
import { MenuItem, IconButtonMenu, MenuItemDivider } from "@/components/Menu";
import { useFeatureFlags } from "@/configs/featureFlag";
import { getMenuData, replaceToSemiBlank } from "@/configs/menuDataDefinition";
import Typography from "@mui/material/Typography";
import { Link, Stack } from "@mui/material";
import { getHrefRel, getHrefTarget } from "@/utils/externalLink";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";

export const HeaderMenu: React.FC = () => {
  const { isVisible, handleClickLogout } = useHeaderMenu();
  const {
    realEstateRegistrationAggregationOfNames,
    realEstateRegistrationMonitoring,
    commercialBookList,
    commercialRegistrationReceptionBook,
    commercialRegistrationAggregationOfNames,
    commercialRegistrationMonitoring,
    allMattersAnalysis,
    dmManagement,
    emailManagement,
    customerManagement,
    ownerInfoList,
    ownerInfoViaScraping,
    mypageTop,
  } = useFeatureFlags();

  // NOTE: フラグオン(true)は〈表示する〉なのだが、引数はDisable（非表示）かどうかなので逆転している
  const menuData = getMenuData({
    realEstateRegistrationAggregationOfNames:
      !realEstateRegistrationAggregationOfNames,
    commercialRegistrationReceptionBook: !commercialRegistrationReceptionBook,
    realEstateRegistrationMonitoring: !realEstateRegistrationMonitoring,
    commercialBookList: !commercialBookList,
    commercialRegistrationAggregationOfNames:
      !commercialRegistrationAggregationOfNames,
    commercialRegistrationMonitoring: !commercialRegistrationMonitoring,
    allMattersAnalysis: !allMattersAnalysis,
    dmManagement: !dmManagement,
    emailManagement: !emailManagement,
    customerManagement: !customerManagement,
    ownerInfoList: !ownerInfoList,
    ownerInfoViaScraping: !ownerInfoViaScraping,
  });

  if (!isVisible) return null;

  return (
    <IconButtonMenu
      icon={
        mypageTop ? (
          <MoreVertIcon sx={{ color: "#FFF" }} />
        ) : (
          <ManageAccountsIcon sx={{ color: "#FFF" }} />
        )
      }
    >
      {mypageTop &&
        menuData.map((category) => {
          return (
            <div key={`header-menu-block-${category.title}`}>
              <Stack
                direction={"row"}
                sx={{
                  padding: "5px 10px",
                }}
                gap={0.5}
              >
                {category.icon != null
                  ? React.createElement(category.icon)
                  : null}
                <Typography
                  key={`header-menu-${category.title}`}
                  fontWeight={700}
                >
                  {category.title}
                </Typography>
              </Stack>
              {category.menus.map((menu) => {
                return menu.disabled ? (
                  <MenuItem
                    key={`$header-menu-${category.title}-${menu.label}`}
                    disabled={menu.disabled}
                  >
                    {replaceToSemiBlank(menu.label)}
                  </MenuItem>
                ) : (
                  <Link
                    key={`$header-menu-link-${category.title}-${menu.label}`}
                    href={menu.href}
                    target={getHrefTarget(menu.href)}
                    rel={getHrefRel(menu.href)}
                    color="inherit"
                    underline="none"
                  >
                    <MenuItem
                      key={`$header-menu-${category.title}-${menu.label}`}
                      disabled={menu.disabled}
                    >
                      {menu.label}
                    </MenuItem>
                  </Link>
                );
              })}
              <MenuItemDivider
                key={`header-menu-divider-${category.title}-bottom`}
              />
            </div>
          );
        })}
      {!mypageTop && (
        <Link href="/account" color="inherit" underline="none">
          <MenuItem>アカウント情報</MenuItem>
        </Link>
      )}
      {!mypageTop && <MenuItemDivider />}
      <MenuItem onClick={handleClickLogout}>
        <LogoutIcon fontSize="small" />
        ログアウト
      </MenuItem>
    </IconButtonMenu>
  );
};
