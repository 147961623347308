import { toast } from "react-toastify";
import { useMailTemplateListRequest } from "./useMailTemplateListRequest";
import { useApiClient } from "@/hooks/useApiClient";
import { useNavigate } from "react-router-dom";

interface UpdateMailTemplateRequest {
  name: string;
  body: string;
}

interface UpdateMailTemplateResponse {
  updated_count: number;
}

export const useUpdateMailTemplateRequest = (
  id: number
): ((params: UpdateMailTemplateRequest) => void) => {
  const { apiClient } = useApiClient();
  const { refetch } = useMailTemplateListRequest();
  const navigate = useNavigate();
  const updateMailTemplate = (params: UpdateMailTemplateRequest): void => {
    apiClient
      .put<UpdateMailTemplateResponse>(`/mail/template/detail/${id}`, params)
      .then(async (_res) => {
        await refetch();
        navigate("/emailManagement/mailTemplate/list");
        toast.success("メールテンプレートの更新に成功しました。");
      })
      .catch((e) => {
        console.error(e);
        toast.error("メールテンプレートの更新に失敗しました。");
      });
  };
  return updateMailTemplate;
};
