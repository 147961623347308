import { styled } from "@mui/material/styles";
import { CommercialBookDataGrid } from "../../../components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { useApiClient } from "../../../hooks/useApiClient";
import { type AxiosInstance } from "axios";
import useSWR from "swr";
import { ButtonVariantOption, LinkButton } from "../../../components/Button";
import { SizingWrapperStyle } from "../../../components/Wrapper";
import { format } from "date-fns";

interface CommercialStatusDataGridRow {
  id: number;
  requestedAt: Date;
  requestPictureCount: number;
  status: string;
  action?: boolean;
}

interface PictureRequestAPIRequestStatus {
  id: number;
  task_id: string;
  status: string;
  requestPictureCount: number;
  requestedAt: Date;
  finishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface PictureRequestAPIResponse {
  count: number;
  list: PictureRequestAPIRequestStatus[];
}

const StyledDataGrid = styled(CommercialBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "No",
      width: 90,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "requestedAt",
      headerName: "商業登記依頼日",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === undefined) return "";
        return format(new Date(value), "yyyy-MM-dd HH:mm");
      },
    },
    {
      field: "requestPictureCount",
      headerName: "取得数（リクエスト数）",
      width: 180,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "status",
      headerName: "取得状況",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === undefined) return "";
        switch (value) {
          case "REQUESTING":
            return "依頼中";
          case "SUCCESS":
            return "成功";
          case "SOME_FAILED":
            return "失敗あり";
          case "COMPLETED":
            return "完了";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ id }: GridRenderCellParams<string>) => {
        return (
          <LinkButton
            variant={ButtonVariantOption.Contained}
            wrapperSize={SizingWrapperStyle.SMALL}
            href={`/commercialbookstatus/${id}/`}
          >
            詳細
          </LinkButton>
        );
      },
    },
  ];
};

const useCommercialPictureRequest = (
  apiClient: AxiosInstance
): CommercialStatusDataGridRow[] => {
  const fetcher = async (url: string): Promise<PictureRequestAPIResponse> => {
    const { data } = await apiClient.get<PictureRequestAPIResponse>(url);
    return data;
  };

  const { data } = useSWR<PictureRequestAPIResponse>(
    "/commercial-picture-request/",
    fetcher,
    { revalidateOnFocus: true }
  );

  if (data === undefined) return [];
  return data.list;
};

export const FeedGrid: React.FC = () => {
  const { apiClient } = useApiClient();
  const colDef = makeGridColDef();

  const rows = useCommercialPictureRequest(apiClient);

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
