import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

export const EmailManagementLink: React.FC = () => {
  return (
    <LinkButton
      variant={ButtonVariantOption.Text}
      startIcon={<EmailIcon />}
      href={"/emailManagement/mail/savedEmailList"}
      wrapperSize={SizingWrapperStyle.INHERIT}
      sx={{ color: "#FFF", fontWeight: "bold" }}
    >
      メール管理
    </LinkButton>
  );
};
