import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export const OrderButton: React.FC = () => {
  return (
    <LinkButton
      variant={ButtonVariantOption.Text}
      startIcon={<BorderColorIcon />}
      href={
        "https://docs.google.com/forms/d/e/1FAIpQLScyb6YBLZ2DRlMB4Djlef3fFHoyJKnxiO6I4RnYvjRqCBXK5w/viewform"
      }
      wrapperSize={SizingWrapperStyle.INHERIT}
      sx={{ color: "#FFF", fontWeight: "bold" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      発注する
    </LinkButton>
  );
};
