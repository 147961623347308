import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import {
  SizingWrapper,
  type SizingWrapperStyle,
} from "@/components/Wrapper/SizingWrapper";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import React, { type ReactElement, useId } from "react";
import { type Control, Controller, type FieldValues } from "react-hook-form";
import type { FieldPath } from "react-hook-form/dist/types";

interface IProps<T extends FieldValues> {
  control: Control<T> | undefined;
  name: FieldPath<T>;
  size?: SizingWrapperStyle;
}

/**
 * 前回取得日からの経過日数を入力するテキストフィールド
 * RHF向けにコントロール化されています
 */
const CTextFieldPastDays = <T extends FieldValues>(
  props: IProps<T>
): ReactElement => {
  const { control, name, size } = props;

  const id = useId();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { name, value, onChange, onBlur, ref, disabled },
        fieldState,
      }) => {
        // RHFからUIへ値を送る際に文字列へ変換する
        const v =
          isNaN(value) || value === 0 ? "0" : (value as string).toString();

        return (
          <SizingWrapper size={size}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor={id}>
                前回取得日からの経過日数を指定する
              </InputLabel>
              <OutlinedInput
                name={name}
                value={v}
                ref={ref}
                onChange={(e) => {
                  // UIからRHFへ値を送る際に数値へ変換する
                  const output = parseInt(e.target.value, 0);
                  onChange(isNaN(output) ? 0 : output);
                }}
                onBlur={onBlur}
                disabled={disabled}
                error={Boolean(fieldState.error)}
                id={id}
                type={"text"}
                fullWidth={true}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    日
                    <Tooltip
                      title="取得済の場合でも経過日数を過ぎたものは再取得いたします"
                      placement="bottom"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </InputAdornment>
                }
                label="前回取得日からの経過日数を指定する"
              />
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          </SizingWrapper>
        );
      }}
    />
  );
};
export { CTextFieldPastDays };
