import { Box, Button as MuiButton, Grid, Link, Stack } from "@mui/material";

import React, { useState } from "react";
import { FeedGrid } from "../components/CustomerListFeedGrid";
import { SectionPaper } from "@/components/Paper";
import { useApiClient } from "../../../hooks/useApiClient";
import type { CustomerStatusDataGridRow, FilterCondition } from "../types";
import { FilteringCondition } from "../components/FilteringCondition";
import { GetCustomerListAPI } from "../api";

export const CustomerList: React.FC = () => {
  const [rows, setRows] = useState<CustomerStatusDataGridRow[]>([]);

  // 検索条件の状態管理
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({
    prefectures: [],
    cities: [],
    // 不動産種別
    realEstateTypeTochi: false,
    realEstateTypeKutate: false,
    realEstateTypeTatemono: false,
  });
  const { apiClient } = useApiClient();

  const handleFiltering = async (): Promise<void> => {
    // 都道府県 or 市区町村が選択されていない場合はアラートを表示
    const messages = [];
    if (filterCondition.prefectures.length === 0) {
      messages.push("都道府県を選択して下さい");
    }
    if (filterCondition.cities.length === 0) {
      messages.push("市区町村を選択して下さい");
    }
    if (messages.length > 0) {
      alert(messages.join("\n"));
    } else {
      const realEstateTypes: string[] = [];
      if (filterCondition.realEstateTypeKutate)
        realEstateTypes.push("CONDOMINIUM");
      if (filterCondition.realEstateTypeTatemono)
        realEstateTypes.push("BUILDING");
      if (filterCondition.realEstateTypeTochi) realEstateTypes.push("LAND");

      const response = await GetCustomerListAPI(
        apiClient,
        "/real-estate-owner-customer-management/customer-list",
        filterCondition.cities,
        filterCondition.prefectures,
        realEstateTypes
      );
      setRows(response);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs>
          <Stack my={2} direction="row" justifyContent="end" spacing={1}>
            <MuiButton
              variant="contained"
              size="large"
              component={Link}
              href="/customermanagement/excludedcustomerlist"
            >
              対象外顧客一覧
            </MuiButton>
          </Stack>
        </Grid>
      </Grid>

      {/* フィルタ部分 */}
      <FilteringCondition
        filterCondition={filterCondition}
        handleFiltering={() => {
          (async () => {
            await handleFiltering();
          })();
        }}
        setFilterCondition={setFilterCondition}
      />

      <Box sx={{ mt: 3 }} />

      <SectionPaper>
        <form>
          <FeedGrid rows={rows} />
          <Box sx={{ mb: 6 }} />
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"center"}
            mb={2}
          />
        </form>
      </SectionPaper>
    </>
  );
};
