import { toast } from "react-toastify";
import { useMailTemplateListRequest } from "./useMailTemplateListRequest";
import { useApiClient } from "@/hooks/useApiClient";
import { useNavigate } from "react-router-dom";

interface CreateMailTemplateRequest {
  name: string;
  body: string;
}

interface CreateMailTemplateResponse {
  id: number;
}

export const useCreateMailTemplateRequest = (): ((
  params: CreateMailTemplateRequest
) => void) => {
  const { apiClient } = useApiClient();
  const { refetch } = useMailTemplateListRequest();
  const navigate = useNavigate();
  const createMailTemplate = (params: CreateMailTemplateRequest): void => {
    apiClient
      .post<CreateMailTemplateResponse>("/mail/template/detail", params)
      .then(async (_res) => {
        await refetch();
        navigate("/emailManagement/mailTemplate/list");
        toast.success("メールテンプレートの作成に成功しました。");
      })
      .catch((e) => {
        console.error(e);
        toast.error("メールテンプレートの作成に失敗しました。");
      });
  };
  return createMailTemplate;
};
