import { Box, Grid, Link, Typography } from "@mui/material";
import {
  Button,
  ButtonVariantOption,
  LinkButton,
} from "../../../components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import React, { useState } from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { DateRangePicker } from "@/components/DatePicker";
import {
  getSearchMaxDate,
  getSearchMinDate,
  getStartAndEndDate,
} from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { MultiSelect } from "@/components/Select";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import { Divider } from "@/components/Divider";
import { MonitoringFeedGrid } from "@/features/monitoring/components/MonitoringFeedGrid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReceptionBookImportStatus } from "@/features/realEstateReceptionBookFeed/components/ReceptionBookImportStatus";
import { useReceptionBookImportStatuses } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionBookImportStatuses";

export const Monitoring: React.FC = () => {
  const {
    mypageTop,
    realEstateRegistrationMonitoring,
    realEstateRegistrationMonitoringInformation,
  } = useFeatureFlags();

  const [startDate, endDate] = getStartAndEndDate();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  const { receptionReasonOptions } = useReceptionReasonOptions();

  const [
    legalAffairsBureauRequestDateStart,
    setLegalAffairsBureauRequestDateStart,
  ] = useState<Date | null>(startDate);
  const [
    legalAffairsBureauRequestDateEnd,
    setLegalAffairsBureauRequestDateEnd,
  ] = useState<Date | null>(endDate);

  const [receptionReasons, setReceptionReasons] = useState<string[]>([]);

  const importStatuses = useReceptionBookImportStatuses();

  if (!realEstateRegistrationMonitoring) return null;

  return (
    <PagePaper>
      {realEstateRegistrationMonitoringInformation ? (
        <Box sx={{ mb: 3 }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">お知らせ</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ReceptionBookImportStatus importStatuses={importStatuses} />
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : null}
      <form>
        {/* ページタイトル */}
        <Box sx={{ display: "flex", mb: 3 }}>
          <MonitorHeartIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産登記モニタリング</PageTitle>
        </Box>
        <SectionPaper>
          <Grid sx={{ m: 2 }}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", my: 1 }}>
                <DateRangePicker
                  startDateLabel="登録日 開始"
                  startDate={legalAffairsBureauRequestDateStart}
                  onChangeStartDate={setLegalAffairsBureauRequestDateStart}
                  endDateLabel="登録日 終了"
                  endDate={legalAffairsBureauRequestDateEnd}
                  onChangeEndDate={setLegalAffairsBureauRequestDateEnd}
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultCalendarMonth={startDate}
                />
              </Box>

              <Box sx={{ my: 1 }}>
                <MultiSelect
                  label="登記原因"
                  value={receptionReasons}
                  onChange={setReceptionReasons}
                  options={receptionReasonOptions}
                  maxSelect={1}
                />
              </Box>

              <Box
                sx={{
                  my: 3,
                  mr: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label="検索"
                  onClick={() => {
                    console.log("find");
                  }}
                  variant={ButtonVariantOption.Contained}
                  size={SizingWrapperStyle.SMALL}
                />
              </Box>

              <Box
                sx={{
                  my: 3,
                  mr: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdYazgZcqCbsObM9kcNVpHCgkQjJ2XtYWSx_L-Qw4OTJtCkSA/viewform"
                  sx={{ textAlign: "center", fontWeight: "bold", mr: 2 }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  データクレンジング
                  <br />
                  依頼
                </LinkButton>
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/monitoring/upload"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  モニタリングデータ
                  <br />
                  アップロード
                </LinkButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ my: 2 }}>
                <Button
                  label={"一括操作"}
                  variant={ButtonVariantOption.Contained}
                  onClick={() => {
                    console.log("aa");
                  }}
                />
              </Box>
              <MonitoringFeedGrid />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  my: 3,
                  mr: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/monitoring"
                  sx={{ textAlign: "center", fontWeight: "bold", mr: 2 }}
                >
                  一括ダウンロード
                </LinkButton>
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/monitoring"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  モニタリング登録を解除する
                </LinkButton>
              </Box>
            </Grid>
          </Grid>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
