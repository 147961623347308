import { isBefore, isEqual, isValid } from "date-fns";
import { z } from "zod";

// 登記年月開始・終了日のスキーマ
export const MonitoringDetailFormSchema = z
  .object({
    startDate: z.date().nullable(), // 登記年月開始日
    endDate: z.date().nullable(), // 登記年月終了日
  })
  .refine(
    (args) => {
      const { startDate, endDate } = args;

      if (
        startDate != null &&
        endDate != null &&
        isValid(startDate) &&
        isValid(endDate)
      ) {
        // 同じ日付の場合はOK。
        if (isEqual(startDate, endDate)) return true;

        return isBefore(startDate, endDate);
      }

      return true;
    },
    {
      message: "登記年月開始日は登記年月終了日より前の日にちを設定してください",
      path: ["endDate"],
    }
  );

// 定義したZodのスキーマをTypescriptの型に変換
export type MonitoringDetailForm = z.infer<typeof MonitoringDetailFormSchema>;

// 不動産登記取得のスキーマ
export const AcquireBookSchema = z.object({
  identifyName: z.string().min(1, "識別名は必須です"), // 識別名
  location: z.string().optional(), // 所在
  chibanKaokuNumber: z.string().optional(), // 地番または家屋番号
  pastDays: z // 前回取得日からの経過日数
    .preprocess(
      (value) => Number(value),
      z
        .number({ invalid_type_error: "経過日数を0日以上で入力してください" })
        .min(0, "経過日数は0日以上で入力してください")
        .max(1000, "経過日数は1000日以内で入力してください")
    ),
});

export type AcquireBookForm = z.infer<typeof AcquireBookSchema>;
