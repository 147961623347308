import React from "react";

interface ValidateItem {
  state: ValidateState;
  setState: React.Dispatch<React.SetStateAction<ValidateState>>;
  validate: () => boolean;
  clearError: () => void;
}

interface ValidateState {
  helperTextWhenError: string;
  error: boolean;
}

interface Validator {
  name: ValidateItem;
  body: ValidateItem;
  isValid: () => boolean;
}

interface UseValidatorParams {
  valueStateName: string;
  valueStateBody: string;
}

const useMailTemplateValidator = (params: UseValidatorParams): Validator => {
  const [validateStateName, setValidateStateName] =
    React.useState<ValidateState>({
      error: false,
      helperTextWhenError: "",
    });
  const [validateStateBody, setValidateStateBody] =
    React.useState<ValidateState>({
      error: false,
      helperTextWhenError: "",
    });

  // テンプレート名のバリデーション
  const name: ValidateItem = {
    state: validateStateName,
    setState: setValidateStateName,
    validate: (): boolean => {
      if (params.valueStateName !== "") {
        setValidateStateName({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setValidateStateName({
          error: true,
          helperTextWhenError: "テンプレート名を入力してください。",
        });
        return false;
      }
    },
    clearError: (): void => {
      setValidateStateName({ error: false, helperTextWhenError: "" });
    },
  };
  // テンプレート名のバリデーション
  const body: ValidateItem = {
    state: validateStateBody,
    setState: setValidateStateBody,
    validate: (): boolean => {
      if (params.valueStateBody === "") {
        setValidateStateBody({
          error: true,
          helperTextWhenError: "本文を入力してください。",
        });
        return false;
      } else if (!params.valueStateBody.includes("{name}")) {
        setValidateStateBody({
          error: true,
          helperTextWhenError: "本文に{name}を含めてください。",
        });
        return false;
      } else {
        setValidateStateBody({ error: false, helperTextWhenError: "" });
        return true;
      }
    },
    clearError: (): void => {
      setValidateStateBody({ error: false, helperTextWhenError: "" });
    },
  };

  const isValid = (): boolean => {
    const isNameValid = name.validate();
    const isBodyValid = body.validate();
    return isNameValid && isBodyValid;
  };

  return {
    name,
    body,
    isValid,
  };
};

export { useMailTemplateValidator };
