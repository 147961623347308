import {
  type AcquireBookStatus,
  type AcquireBookStatusResponse,
} from "../features/acquireBookStatus/types";
import {
  type CommercialBookStatus,
  type CommercialBookStatusResponse,
} from "../features/commercialBookStatus/types";
import { nationalHolidayCsv, isSameDate } from "./utils";

// 平日と土日祝の営業時間を表す定数
const START_HOUR_WEEKDAY = 8;
const START_MINUTES_WEEKDAY = 30;
const END_HOUR_WEEKDAY = 23;
const END_MINUTES_WEEKDAY = 0;
const START_HOUR_HOLIDAY = 9;
const START_MINUTES_HOLIDAY = 0;
const END_HOUR_HOLIDAY = 18;
const END_MINUTES_HOLIDAY = 0;

// nowの時刻が平日の営業時間内のときtrueを返す
const isBusinessHourWeekday = (now: Date): boolean => {
  const start = new Date(now.getTime());
  const end = new Date(now.getTime());

  start.setHours(START_HOUR_WEEKDAY);
  start.setMinutes(START_MINUTES_WEEKDAY);
  start.setSeconds(0);
  end.setHours(END_HOUR_WEEKDAY);
  end.setMinutes(END_MINUTES_WEEKDAY);
  end.setSeconds(0);

  return start <= now && now < end;
};

// nowの時刻が土日祝の営業時間内のときtrueを返す
const isBusinessHourHoliday = (now: Date): boolean => {
  const start = new Date(now.getTime());
  const end = new Date(now.getTime());

  start.setHours(START_HOUR_HOLIDAY);
  start.setMinutes(START_MINUTES_HOLIDAY);
  start.setSeconds(0);
  end.setHours(END_HOUR_HOLIDAY);
  end.setMinutes(END_MINUTES_HOLIDAY);
  end.setSeconds(0);

  return start <= now && now < end;
};

// 営業時間外の判定処理
export const isScrapingOutOfService = (): boolean => {
  const now = new Date();

  // 今日が土日に該当するか確認する
  if (now.getDay() === 0 || now.getDay() === 6) {
    // 土日の営業時間外の時trueを返す
    return !isBusinessHourHoliday(now);
  }

  // 今日が国民の祝日に該当するか確認する
  const holidayList = nationalHolidayCsv // utils/utils.tsのnationalHolidayCsvを使用
    .split("\n") // 改行で分割
    .filter((value) => value) // 空要素("")を削除
    .map((value) => value.split(",")[0]) // ","の左側のみ取り出し
    .map((value) => new Date(value)); // Dateオブジェクト作成

  if (
    holidayList.reduce((previousValue, currentValue) => {
      if (previousValue) return true;
      return isSameDate(currentValue, now);
    }, false)
  ) {
    // 国民の祝日かつ営業時間外のときtrueを返す
    return !isBusinessHourHoliday(now);
  }

  // 平日の営業時間外のときtrueを返す
  if (!isBusinessHourWeekday(now)) {
    return true;
  }

  // いずれにも該当しないので営業時間とする
  return false;
};

// 共通プロパティを持つ型を定義
interface StatusItem {
  status: string | null;
}

const countStatus = (
  items: Array<AcquireBookStatus | CommercialBookStatus>,
  statusToCount: string | null
): number => {
  return items.reduce((count, item) => {
    // 型アサーションを使用して、StatusItemとしてitemを扱う
    if ((item as StatusItem).status === statusToCount) return count + 1;
    return count;
  }, 0);
};

// 引数のdataから取得残数とエラー数を返す。引数のdataがundefinedの場合は[null,null]を返す
export const getRemainingCountWithErrorCount = (
  data: AcquireBookStatusResponse | CommercialBookStatusResponse | undefined
): [number | null, number | null] => {
  if (data === undefined) return [null, null];

  // 登記/図面取得では未取得の時、statusがnullになる
  // 商業登記取得では未取得の時、statusが"REQUESTING"になる
  // どちらかに揃えて欲しいが田原さんと話た結果今回はフロントで対応
  const remainingNull = countStatus(data.list, null);
  const remainingReq = countStatus(data.list, "REQUESTING");
  const remaining = Math.max(remainingNull, remainingReq);

  const error = countStatus(data.list, "FAILED");

  return [remaining, error];
};

export enum AcquireBookStatusString {
  Completed = "取得完了",
  SomeFailed = "失敗あり",
  OutOfService = "営業時間外",
  InProgress = "取得中",
}

// 引数のdataからacquirebookstatusのステータス部分に表示する文字列を返す
// 取得残がなく、エラーもない場合は取得完了
// 取得残がなく、エラーがある場合は失敗あり
// 取得残がありかつ営業時間外の場合は営業時間外
// 土日祝(9:00 - 18:00)内でかつ取得残がある場合は取得中
// 平日(8:30 - 23:00)内でかつ取得残がある場合は取得中
export const getStatusString = (
  data: AcquireBookStatusResponse | CommercialBookStatusResponse | undefined
): AcquireBookStatusString | "" => {
  if (data === undefined) return "";

  const [nullCount, errorCount] = getRemainingCountWithErrorCount(data);

  if (nullCount === null || errorCount === null) return "";
  else if (nullCount === 0 && errorCount === 0)
    return AcquireBookStatusString.Completed;
  else if (nullCount === 0 && errorCount > 0)
    return AcquireBookStatusString.SomeFailed;
  else if (isScrapingOutOfService())
    return AcquireBookStatusString.OutOfService;
  else if (nullCount > 0) return AcquireBookStatusString.InProgress;
  else return "";
};

export const acquireBookIsDone = (status: AcquireBookStatusString): boolean => {
  return (
    status === AcquireBookStatusString.Completed ||
    status === AcquireBookStatusString.SomeFailed
  );
};
