import type { GridApiCommunity } from "@mui/x-data-grid/models/api/gridApiCommunity";
import {
  defaultGetRowsToExport,
  getColumnsToExport,
} from "@mui/x-data-grid/hooks/features/export/utils";
import { utils, write } from "xlsx";
import type { GridFileExportOptions } from "@mui/x-data-grid/models/gridExport";

// 親React componentから渡してもらうoptionの型
export interface ExcelExportOptions extends GridFileExportOptions {
  fileName?: string;
  sheetName?: string;
}

interface useExcelExportReturnType {
  getFeedGridData: (
    apiRef: React.MutableRefObject<GridApiCommunity>,
    options?: ExcelExportOptions
  ) => string[][];
  makeExcelUint8Array: (data: string[][], sheetName: string) => Uint8Array;
  downloadAsExcelFile: (data: Uint8Array, fileName: string) => void;
}

/**
 * FeedGridから全ての行データを取得してSheetJSが読める形式に変換する
 */
const getFeedGridData = (
  apiRef: React.MutableRefObject<GridApiCommunity>,
  options: ExcelExportOptions = {}
): string[][] => {
  // エクスポート対象のカラムを取得
  const columnsToExport = getColumnsToExport({ apiRef, options });

  // エクスポート対象の行のIDを取得
  const rowsToExport = defaultGetRowsToExport({ apiRef });

  // すべてのセルを取得して配列に格納
  const rows = rowsToExport.map((rowId) => {
    return columnsToExport.map((column) => {
      const cellParams = apiRef.current.getCellParams(rowId, column.field);
      // formattedValueがnullの場合があるのでnullの場合は""にする
      return String(cellParams.formattedValue ?? "");
    });
  });

  // エクスポートすべきFeedGridのヘッダー行を読み出す
  const columnHeaderNames = columnsToExport.map(
    (column) => column.headerName ?? ""
  );

  // ヘッダ行とデータ本体を結合する
  rows.unshift(columnHeaderNames);

  return rows;
};

/**
 * string配列の配列を引数に取り、excelファイルのUint8Arrayを返す
 * sheetNameはexcelファイル開いた時の下部に表示されているシート名
 * @param data
 * @param sheetName
 */
const makeExcelUint8Array = (
  data: string[][],
  sheetName: string
): Uint8Array => {
  // const data :string[][] = [
  //   ["a", "い", "う"],
  //   ["2", "お", "ぴ"],
  //   ["3", "な", "き"],
  // ];

  // string[][]をシートにに変換
  const sheet = utils.aoa_to_sheet(data);

  // 全てのセルの横幅をexcel表現でwidth20に設定
  // 参考URL: https://docs.sheetjs.com/docs/csf/features/colprops
  sheet["!cols"] = new Array(data[0].length).fill({ wch: 20 });

  const book = utils.book_new();
  // excelファイル開いた時の下部に表示されているシート名を決める
  utils.book_append_sheet(book, sheet, sheetName);

  // Uint8Array型で書き出し
  return write(book, { type: "array" }) as Uint8Array;
};

/**
 * Uint8Arrayをxlsxファイルにしてダウンロードする
 * 参考ファイル: @mui/x-data-grid/utils/exportAs.js
 * @param data
 * @param fileName
 */
const downloadAsExcelFile = (data: Uint8Array, fileName: string): void => {
  const blob: Blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName + ".xlsx";
  a.click();
  a.remove();
  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

export const useExcelExport = (): useExcelExportReturnType => {
  return {
    downloadAsExcelFile,
    makeExcelUint8Array,
    getFeedGridData,
  };
};
