import { Container, FormHelperText } from "@mui/material";

interface Props {
  children: React.ReactNode;
  error?: boolean; // エラーがtrueの時は枠を赤く表示する
  helperText?: string; // エラーがtrueのときに表示する文字列
}
export const BlueSectionPaper: React.FC<Props> = ({
  children,
  error,
  helperText,
}) => {
  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "4px solid",
          borderRadius: "4px",
          borderColor: error ? "red" : "primary.main",
        }}
      >
        {children}
      </Container>
      <FormHelperText error sx={{ ml: "1em" }}>
        {helperText ?? ""}
      </FormHelperText>
    </>
  );
};
