import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";

export const SideNav: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string): boolean => location.pathname === path;

  const navItems = [
    { path: "/emailManagement/mail/createMail", label: "作成" },
    { path: "/emailManagement/mail/savedEmailList", label: "保存済みメール" },
    {
      path: "/emailManagement/mail/scheduledEmailList",
      label: "送信待ちメール",
    },
    { path: "/emailManagement/mail/sentEmailList", label: "送信済みメール" },
  ];

  return (
    <Stack spacing={2}>
      {navItems.map((item) => (
        <Stack direction={"row"} key={item.path}>
          <LinkButton
            variant={
              isActive(item.path)
                ? ButtonVariantOption.Contained
                : ButtonVariantOption.Outlined
            }
            fullWidth
            size={"large"}
            href={item.path}
          >
            {item.label}
          </LinkButton>
        </Stack>
      ))}
    </Stack>
  );
};
