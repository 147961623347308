import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { AcquireBookDataGrid } from "../../../components/DataGrid";
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridColumnHeaderParams,
} from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import { type AcquireBookStatus } from "../types";
import IconButton from "@mui/material/IconButton";
import { useFeatureFlags } from "@/configs/featureFlag";
import Typography from "@mui/material/Typography";

interface IProps {
  row: AcquireBookStatus[] | undefined;
  selectedOwnerInfos: Map<number, boolean>;
  setSelectedOwnerInfos: React.Dispatch<
    React.SetStateAction<Map<number, boolean>>
  >;
  bindOwnerAnalyzeRequestId: number | null | undefined;
}

interface AqcuireBookStatusDataGridRow {
  id: number;
  realEstateType: string;
  prefectureName: string;
  location: string;
  chibanKaokuNumber: string;
  zenbu: string;
  ownerInfo: string;
  ownerInfoDetailId: number;
  electricDrawings: string;
  tisekiDrawings: string;
  tiekiDrawings: string;
  buildingDrawings: string;
  status: string;
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

// statusの状態に応じてDataGridのセルに格納する値を決める
const getUriIfSuccess = (value: AcquireBookStatus): string => {
  if (value.status === "SUCCEEDED") {
    return value.uri ?? "";
  } else if (value.status === "FAILED") {
    return "FAILED";
  } else {
    return "PROCESSING";
  }
};

// pictureTypeやoptionsの状態に応じて適切な項目にURIを格納する
const distributeUris = (
  value: AcquireBookStatus
): Omit<
  AqcuireBookStatusDataGridRow,
  | "id"
  | "realEstateType"
  | "prefectureName"
  | "location"
  | "chibanKaokuNumber"
  | "status"
> => {
  const result = {
    zenbu: "",
    ownerInfo: "",
    ownerInfoDetailId: -1,
    electricDrawings: "",
    tisekiDrawings: "",
    tiekiDrawings: "",
    buildingDrawings: "",
  };

  result.ownerInfoDetailId = value.id;

  if (value.pictureType === "SHOYUSHAJIKO")
    result.ownerInfo = getUriIfSuccess(value);
  if (value.pictureType === "CHISEKISOKURYOZU")
    result.tisekiDrawings = getUriIfSuccess(value);
  if (value.pictureType === "DENSHIKOZU")
    result.electricDrawings = getUriIfSuccess(value);
  if (value.pictureType === "CHIEKIKENZUMEN")
    result.tiekiDrawings = getUriIfSuccess(value);
  if (value.pictureType === "TATEMONOZUMEN")
    result.buildingDrawings = getUriIfSuccess(value);
  if (value.pictureType === "ZENBUJIKO") result.zenbu = getUriIfSuccess(value);
  return result;
};

// 同じ都道府県、住所、地番を持っている項目は一つにマージする
const merge = (
  rows: AqcuireBookStatusDataGridRow[]
): AqcuireBookStatusDataGridRow[] => {
  // 作業用の空配列を作る
  const workingArray: Array<{
    key: string;
    value: AqcuireBookStatusDataGridRow;
  }> = [];

  rows.forEach((value) => {
    // 同一項目と識別するためのkeyを生成する
    const key = `${value.realEstateType}${value.prefectureName}${value.location}${value.chibanKaokuNumber}`;

    // 作業用配列から上で生成したkeyと同じkeyを持っている項目があるか探す
    const anItemThatHasSameKey = workingArray.find((item) => item.key === key);

    if (anItemThatHasSameKey === undefined) {
      // 同じkeyを持っていないならそのkeyで作業用配列に新規追加
      workingArray.push({ key, value });
    } else {
      // 同じkeyを持っているなら各項目を上書き
      if (value.ownerInfo !== "")
        anItemThatHasSameKey.value.ownerInfo = value.ownerInfo;
      if (value.tisekiDrawings !== "")
        anItemThatHasSameKey.value.tisekiDrawings = value.tisekiDrawings;
      if (value.electricDrawings !== "")
        anItemThatHasSameKey.value.electricDrawings = value.electricDrawings;
      if (value.tiekiDrawings !== "")
        anItemThatHasSameKey.value.tiekiDrawings = value.tiekiDrawings;
      if (value.buildingDrawings !== "")
        anItemThatHasSameKey.value.buildingDrawings = value.buildingDrawings;
      if (value.zenbu !== "") anItemThatHasSameKey.value.zenbu = value.zenbu;
    }
  });

  // 最終的に出来上がった作業用配列からvalueのみを取り出す
  return workingArray.map((item): AqcuireBookStatusDataGridRow => {
    return item.value;
  });
};

const convertRows = (
  rows: AcquireBookStatus[] | undefined
): AqcuireBookStatusDataGridRow[] => {
  if (rows === undefined) return [];

  const result = rows.map((value): AqcuireBookStatusDataGridRow => {
    return {
      id: value.id,
      realEstateType: value.realEstateType as string,
      prefectureName: value.prefectureName,
      location: value.location,
      chibanKaokuNumber: value.chibanKaokuNumber,
      status: value.status as string,
      ...distributeUris(value),
    };
  });
  return merge(result);
};

const checkboxHelpMessage = "所有者事項解析を行う場合はチェックしてください";

export const FeedGrid: React.FC<IProps> = (props) => {
  const rows = props.row;
  const {
    selectedOwnerInfos,
    setSelectedOwnerInfos,
    bindOwnerAnalyzeRequestId,
  } = props;

  // テーブルヘッダーの「所有者事項解析」チェックボックスの状態
  const [selectAllOwnerInfo, setSelectAllOwnerInfo] = useState<boolean>(false);

  // DataGridに表示できる形式に変換する
  const convertedRows = convertRows(rows);

  const { ownerInfoViaScraping } = useFeatureFlags();

  /**
   * テーブルヘッダーの所有者事項解析全選択チェックボックスを操作した時の関数
   * @param event
   */
  const handleSelectAllOwnerInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newChecked = new Map<number, boolean>(selectedOwnerInfos);
    convertedRows.forEach((row) => {
      newChecked.set(row.ownerInfoDetailId, event.target.checked);
    });
    setSelectedOwnerInfos(newChecked);
    setSelectAllOwnerInfo(event.target.checked);
  };

  /**
   * セルの値（引数として渡されたvalueの値）に応じて適切なアイコンを表示する関数
   * @param value 次のいずれかの値が引数として渡されると想定している [undefined, "", "FAILED", "PROCESSING", <URL>]
   * @description valueの値と出力の関係は下記の通り
   * - undefined : 空欄
   * - "" : 空欄
   * - "FAILED" : エラーアイコン
   * - "PROCESSING" : ダウンロード中アイコン
   * - <URL> : ダウンロードアイコン
   */
  const renderIndicatorIconButton = ({
    value,
  }: GridRenderCellParams<
    string,
    AqcuireBookStatusDataGridRow
  >): React.ReactNode => {
    if (value === undefined) return "";
    if (value === "") return "";

    if (value === "FAILED") {
      return (
        <Tooltip title={"取得に失敗しました"}>
          <ErrorIcon />
        </Tooltip>
      );
    } else if (value === "PROCESSING") {
      return (
        <Tooltip title={"取得しています"}>
          <DownloadingIcon />
        </Tooltip>
      );
    } else {
      return (
        <>
          <Tooltip title={"ダウンロードできます"}>
            <IconButton href={value}>
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    }
  };

  // カラム設定
  const makeColDef = (): GridColDef[] => {
    // 読み込み時、最初の一瞬だけ所有者事項チェックボックスが表示されて、そのあと非表示になるのを防ぐ
    if (convertedRows.length === 0) return [];

    const colDef: GridColDef[] = [
      {
        field: "realEstateType",
        headerName: "種別",
        width: 120,
        sortable: true,
        renderCell: ({ value }: GridRenderCellParams) => {
          if (value === "LAND") return "土地";
          if (value === "BUILDING") return "建物";
          if (value === "CONDOMINIUM") return "区分建物";
          return "";
        },
      },
      {
        field: "prefectureName",
        headerName: "都道府県",
        width: 110,
        sortable: true,
      },
      {
        field: "location",
        headerName: "所在",
        width: 260,
        sortable: true,
      },
      {
        field: "chibanKaokuNumber",
        headerName: "地番または家屋番号",
        width: 190,
        sortable: true,
      },
      {
        field: "zenbu",
        headerName: "全部事項",
        width: 110,
        sortable: true,
        headerAlign: "center",
        align: "center",
        renderCell: renderIndicatorIconButton,
      },
      {
        field: "ownerInfo",
        headerName: "所有者事項",
        width: 130,
        sortable: true,
        headerAlign: "center",
        align: "center",
        renderCell: renderIndicatorIconButton,
      },
      {
        field: "ownerInfoCheckBox",
        headerName: "所有者事項チェックボックス",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (
          params: GridRenderCellParams<string, AqcuireBookStatusDataGridRow>
        ): React.ReactNode => {
          const { row } = params;

          return (
            <Tooltip title={checkboxHelpMessage} placement={"right-start"}>
              <Checkbox
                checked={selectedOwnerInfos.get(row.ownerInfoDetailId) ?? false}
                onChange={(e) => {
                  const newChecked = new Map<number, boolean>(
                    selectedOwnerInfos
                  );
                  newChecked.set(row.ownerInfoDetailId, e.target.checked);
                  if (selectAllOwnerInfo) {
                    setSelectAllOwnerInfo(false);
                  }
                  setSelectedOwnerInfos(newChecked);
                }}
              />
            </Tooltip>
          );
        },
        renderHeader: (_params: GridColumnHeaderParams) => (
          <>
            <Typography
              fontSize={"0.875rem"}
              fontWeight={700}
              sx={{ color: "rgba(0,0,0,0.87)" }}
            >
              解析
            </Typography>
            <Tooltip title={checkboxHelpMessage} placement={"top"}>
              <Checkbox
                checked={selectAllOwnerInfo}
                onChange={handleSelectAllOwnerInfoChange}
              />
            </Tooltip>
          </>
        ),
      },
      {
        field: "electricDrawings",
        headerName: "電子公図",
        width: 110,
        sortable: true,
        headerAlign: "center",
        align: "center",
        renderCell: renderIndicatorIconButton,
      },
      {
        field: "tisekiDrawings",
        headerName: "地積測量図",
        width: 120,
        sortable: true,
        headerAlign: "center",
        align: "center",
        renderCell: renderIndicatorIconButton,
      },
      {
        field: "tiekiDrawings",
        headerName: "地役権図面",
        width: 120,
        sortable: true,
        headerAlign: "center",
        align: "center",
        renderCell: renderIndicatorIconButton,
      },
      {
        field: "buildingDrawings",
        headerName: "建物図面",
        width: 110,
        sortable: true,
        headerAlign: "center",
        align: "center",
        renderCell: renderIndicatorIconButton,
      },
    ];

    // ownerInfoViaScraping無効の場合はチェックボックスのカラムを非表示にする
    // 所有者事項解析済みならチェックボックスのカラムを非表示にする
    if (!ownerInfoViaScraping || bindOwnerAnalyzeRequestId) {
      colDef.splice(6, 1);
    }

    return colDef;
  };

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={convertedRows}
      columns={makeColDef()}
    />
  );
};
