import { IconButton } from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "react";
import { NewVersionAvailableContext } from "@/components/UpdateChecker";

export const UpdateButton: React.FC = () => {
  const [isNewVersionAvailable] = useContext<
    [boolean, FrontendAppVersionInfo | undefined]
  >(NewVersionAvailableContext);
  const message = `新しいバージョンがあります`;

  if (isNewVersionAvailable) {
    return (
      <Tooltip title={message} arrow>
        <IconButton
          color="warning"
          onClick={() => {
            window.location.reload();
          }}
        >
          <SystemUpdateAltIcon />
        </IconButton>
      </Tooltip>
    );
  }
  return null;
};
