import { TileLayer } from "react-leaflet";
import { useEffect, useRef, useState } from "react";
import { type TileLayer as LeafletTileLayer } from "leaflet";

/**
 * 地番レイヤーを返すコンポーネント
 */
interface GeospaceChibanTileLayerProps {
  mapServiceAppId: string;
}

export const GeospaceChibanTileLayer: React.FC<
  GeospaceChibanTileLayerProps
> = ({ mapServiceAppId }) => {
  const ref = useRef<LeafletTileLayer>(null);
  const [url, setUrl] = useState("");

  useEffect(() => {
    const newUrl = `https://api-maptile.geospace.jp/chibanmap/${mapServiceAppId}/{z}/{y}/{x}.png`;
    setUrl(newUrl);
    ref.current?.setUrl(newUrl);
  }, [mapServiceAppId]);

  return <TileLayer ref={ref} url={url} maxZoom={21} />;
};
