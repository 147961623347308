import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";
import useSWRMutation, { type SWRMutationResponse } from "swr/mutation";
import type { UserConfigFormData } from "@/features/accountSettings/types/schemas";

export interface UserConfigAPIResponse {
  pictureRequestPastDays: number;
}

const url = "/user/config";

/**
 * ユーザー設定の取得API
 * @returns 成功ならUserConfigAPIResponse、失敗ならpostを行って新規作成し、作成した結果が返る
 */
export const UserConfigGetAPI = (): UserConfigAPIResponse | undefined => {
  const { apiClient } = useApiClient();

  const fetcher = async (url: string): Promise<UserConfigAPIResponse> => {
    const res = await apiClient
      .get<UserConfigAPIResponse>(url)
      .catch(async (reason) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (reason.response.status === 404) {
          return await apiClient.post<UserConfigAPIResponse>(url);
        }
        throw reason;
      });
    return res.data;
  };
  const { data } = useSWR<UserConfigAPIResponse, number | undefined>(
    url,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return data;
};

/**
 * ユーザー設定の更新API
 * @returns trigger()が返ってくるのでtrigger(data: UserConfigFormData)で設定を送信することができます
 */
export const UserConfigUpdateAPI = (): SWRMutationResponse<
  UserConfigAPIResponse,
  never,
  UserConfigFormData
> => {
  const { apiClient } = useApiClient();

  const fetcher = async (
    url: string,
    { arg }: { arg: UserConfigFormData }
  ): Promise<UserConfigAPIResponse> => {
    return (await apiClient.put<UserConfigAPIResponse>(url, arg)).data;
  };

  return useSWRMutation(url, fetcher, { revalidate: false });
};
