import { useState, useEffect } from "react";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Popover,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

interface IProps {
  hasTrade: boolean;
  setHasTrade: React.Dispatch<React.SetStateAction<boolean>>;
  handleSearch: () => void;
  disabled: boolean;
}

const StyledPopover = styled(Popover)`
  // Popoverにデフォルトで設定されているbox-shadowを削除
  .MuiPopover-paper {
    box-shadow: none;
  }
`;

const FilterTradeSwitch: React.FC<IProps> = (props) => {
  const { hasTrade, setHasTrade, handleSearch, disabled } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  // feedGridの要素数を読み取るためapiRefを取得
  const apiRef = useGridApiContext();

  // 売買スクリーニングスイッチを無効化する条件はfeedGridの要素数が0の時と受付帳が1,000件より多い場合
  const isDisabled = apiRef.current.getRowsCount() === 0 ? true : disabled;

  // hasTradeのstateを更新
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;
    setHasTrade(checked);
  };

  // 売買スクリーニングスイッチの状態(hasTrade)の変更が完了したら実行する
  // ちなみに初期のロード時も呼び出されている
  useEffect(() => {
    // feedGridの要素が1以上のときのみhandleSearch()を実行
    if (apiRef.current.getRowsCount() > 0) {
      handleSearch();
    }
    // feedGridの売買登記あり列の表示/非表示を切り替える
    apiRef.current.setColumnVisibility("hasTrade", hasTrade);
    // CSV,Excelダウンロードの際にhasTradeが非表示の時は出力しない
    apiRef.current.getColumn("hasTrade").disableExport = !hasTrade;
  }, [hasTrade]);

  return (
    <FormGroup
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{ m: 2 }}
    >
      <FormControlLabel
        control={
          <Switch
            disabled={isDisabled}
            checked={hasTrade}
            onChange={handleChange}
          />
        }
        label="売買スクリーニング"
      />
      {disabled && (
        <StyledPopover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={isOpenPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: 39, // エクスポートボタンから8px離れる用に設定
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              padding: "8px 6px",
              background: "rgba(0, 0, 0, 0.54)",
              color: "#FFF",
              fontSize: "11px",
            }}
          >
            受付帳の検索結果を1,000件未満に絞り込んでください
          </Typography>
        </StyledPopover>
      )}
    </FormGroup>
  );
};

export { FilterTradeSwitch };
