import { InputAdornment, TextField } from "@mui/material";
import {
  SizingWrapper,
  type SizingWrapperStyle,
} from "@/components/Wrapper/SizingWrapper";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import React, { type ReactElement } from "react";
import { type Control, Controller, type FieldValues } from "react-hook-form";
import type { FieldPath } from "react-hook-form/dist/types";

interface IProps<T extends FieldValues> {
  control: Control<T> | undefined;
  name: FieldPath<T>;
  size?: SizingWrapperStyle;
}

/**
 * 識別名を入力するテキストフィールド
 * RHF向けにコントロール化されています
 */
const CTextFieldIdentifyName = <T extends FieldValues>(
  props: IProps<T>
): ReactElement => {
  const { control, name, size } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur, ref, disabled },
        fieldState,
      }) => {
        return (
          <SizingWrapper size={size}>
            <TextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              disabled={disabled}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              label="識別名"
              fullWidth={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="後で検索しやすくするために台帳名を入れてください"
                      placement="bottom"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </SizingWrapper>
        );
      }}
    />
  );
};
export { CTextFieldIdentifyName };
