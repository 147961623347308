import React from "react";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { EmailManagementListDataGrid } from "../../../components/DataGrid";
import { ButtonVariantOption, LinkButton } from "../../../components/Button";
import type { Props } from "../types";

const StyledDataGrid = styled(EmailManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (status: string): GridColDef[] => {
  return [
    {
      field: "title",
      headerName: "メールタイトル",
      width: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "body",
      headerName: "メール本文",
      width: 400,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "destinationEmails",
      headerName: "送信先メールアドレス",
      width: 600,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "scheduledSendAt",
      headerName: status === "sent" ? "送信日時" : "送信予定日時",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        return value != null ? format(new Date(value), "yyyy/MM/dd HH:mm") : "";
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ id }: GridRenderCellParams<string>) => {
        return (
          <LinkButton
            variant={ButtonVariantOption.Contained}
            href={`/emailManagement/mail/detail/${id}/`}
          >
            詳細
          </LinkButton>
        );
      },
    },
  ];
};

export const EmailListFeedGrid: React.FC<Props> = ({
  rows,
  status,
  handleRowSelectionChange,
}) => {
  const colDef = makeGridColDef(status);

  return (
    <StyledDataGrid
      checkboxSelection
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      onSelectionModelChange={handleRowSelectionChange}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "scheduled_send_at",
          sort: "desc",
        },
      ]}
    />
  );
};
