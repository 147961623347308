// 送信先の選択のvalue
export enum DestinationTypeValues {
  ALL = "ALL",
  CONDITION = "CONDITION",
  CUSTOMER_SELECT = "CUSTOMER_SELECT",
}
// 送信先の選択のlabel
export enum DestinationTypeLabels {
  ALL = "すべての顧客へ送付",
  CONDITION = "条件を絞り込んで送付",
  CUSTOMER_SELECT = "顧客リストから選択して送付",
}
