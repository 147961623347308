import { AxiosError, type AxiosInstance, HttpStatusCode } from "axios";
import {
  type IFetchParams,
  type IRealEstateReceptionBookResponse,
} from "../types";

interface FeedChargeAPIResponse {
  data?: IRealEstateReceptionBookResponse;
  status: number;
  error?: string;
}

/**
 * 不動産受付帳で、課金して家屋番号/地番が付与されたデータを取得するAPI
 * @param {AxiosInstance} apiClient
 * @param {IFetchParams} body
 * @returns {Promise<FeedChargeAPIResponse | undefined>}
 */
export const feedAPI = async (
  apiClient: AxiosInstance,
  body: IFetchParams
): Promise<FeedChargeAPIResponse | undefined> => {
  try {
    const response = await apiClient.post<IRealEstateReceptionBookResponse>(
      "/real-estate-book/feed",
      { body }
    );

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error instanceof AxiosError && error.response != null) {
      return {
        status: error.status ?? HttpStatusCode.InternalServerError,
        error: error.message,
      };
    }

    return {
      status: HttpStatusCode.InternalServerError,
    };
  }
};
