import { HttpStatusCode, type AxiosInstance, isAxiosError } from "axios";
import type {
  DuplicateMailDetailAPIRequest,
  DuplicateMailDetailAPIResponse,
} from "../types";
import * as Sentry from "@sentry/react";

/**
 * メール複製API
 * @param {AxiosInstance} apiClient - axios
 * @param {DuplicateMailDetailAPIRequest} data - データ
 * @returns {DuplicateMailDetailAPIResponse | undefined} DuplicateMailDetailAPIResponse | undefined
 */
export const DuplicateMailDetailAPI = async (
  apiClient: AxiosInstance,
  data: DuplicateMailDetailAPIRequest
): Promise<DuplicateMailDetailAPIResponse | undefined> => {
  const response = await apiClient
    .post<DuplicateMailDetailAPIResponse>("/mail/detail/duplicate", data)
    .catch((error): void => {
      if (
        isAxiosError(error) &&
        error.response?.status !== HttpStatusCode.Unauthorized
      ) {
        Sentry.captureException(error);
      }
      return undefined;
    });

  if (response) {
    return response.status === HttpStatusCode.Created
      ? response.data
      : undefined;
  }
  return undefined;
};
