import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import { ButtonVariantOption } from "@/components/Button";

export interface LegendProps {
  title: string;
  tableData: Array<{ color: string; label: string }>;
}

/*
凡例コンポーネント（単体）
*/
export const Legend: React.FC<LegendProps> = ({ title, tableData }) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(255, 255, 255, 0.9)",
        margin: "10px 10px 25px",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      <div>
        {title.split("\\n").map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
      <div style={{ marginTop: 2 }}>
        {tableData.map((data, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "2px",
            }}
          >
            <i
              style={{
                background: data.color,
                height: "1em",
                width: "1em",
                marginRight: "0.5em",
              }}
            ></i>
            <span>{data.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

interface LegendsProps {
  setIsShow: (isShow: boolean) => void;
}

/*
凡例コンポーネント（リスト）
*/
export const Legends: React.FC<LegendsProps> = ({ setIsShow }) => {
  const floodDepthTableData = [
    { color: "rgb(222,128,222)", label: "20m~" },
    { color: "rgb(244,139,205)", label: "10m ~ 20m" },
    { color: "rgb(255,152,151)", label: "5m ~ 10m" },
    { color: "rgb(255,187,186)", label: "3m ~ 5m" },
    { color: "rgb(254,218,194)", label: "0.5m ~ 3m" },
    { color: "rgb(246,246,172)", label: "~0.5m" },
  ];

  const floodTimeTableData = [
    { color: "rgb(104 14 104)", label: "4週間以上" },
    { color: "rgb(182 12 108)", label: "2週間以上" },
    { color: "rgb(255 51 14)", label: "1週間~2週間" },
    { color: "rgb(252 157 11)", label: "3日~1週間" },
    { color: "rgb(250 246 14)", label: "1日~3日" },
    { color: "rgb(13 75 254)", label: "12時間~1日" },
    { color: "rgb(165 212 254)", label: "12時間未満" },
  ];

  return (
    <div
      style={{
        backgroundColor: "rgb(255, 255, 255, 0.9)",
        padding: "10px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Button
          variant={ButtonVariantOption.Outlined}
          onClick={() => {
            setIsShow(false);
          }}
          sx={{
            padding: "2px",
            fontSize: "0.8rem",
            borderRadius: 0,
          }}
        >
          閉じる
        </Button>
      </div>

      <Stack marginTop={4} direction="row" spacing={2}>
        <Legend
          tableData={floodDepthTableData}
          title="洪水浸水想定区域\n（計画規模）"
        />
        <Legend
          tableData={floodDepthTableData}
          title="洪水浸水想定区域\n（想定最大規模）"
        />
        <Legend
          tableData={floodTimeTableData}
          title="浸水継続時間\n（想定最大規模）"
        />
      </Stack>
    </div>
  );
};

/*
凡例コントロールコンポーネント
*/
export const LegendsControl: React.FC = () => {
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      {!isShow && (
        <button
          onClick={() => {
            setIsShow(!isShow);
          }}
          style={{
            padding: "10px",
            backgroundColor: "#FFF",
            cursor: "pointer",
          }}
          className="leaflet-control-layers leaflet-touch"
        >
          凡例
        </button>
      )}
      {isShow && <Legends setIsShow={setIsShow} />}
    </>
  );
};
