import { HttpStatusCode, type AxiosInstance } from "axios";
import { type DownloadCondition } from "../types";
import { type NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

export const downloadAPI = async (
  apiClient: AxiosInstance,
  downloadCondition: DownloadCondition,
  navigate: NavigateFunction
): Promise<void> => {
  const data = {
    billableData: downloadCondition.billableData.map((element) => {
      return {
        id: element.id,
        reusable: element.reusable,
      };
    }),
    noBillableData: downloadCondition.noBillableData,
  };

  await apiClient
    .post<{ pictureRequestId: number }>(
      "/picture-request/stored-pictures/download",
      data
    )
    .then((result) => {
      if (result.status !== HttpStatusCode.Ok) {
        toast.error("登記のダウンロードに失敗しました");
      }
      // 詳細画面に遷移。
      navigate(`/acquirebookstatus/${result.data.pictureRequestId}/`);
    })
    .catch(() => {
      toast.error("登記のダウンロードに失敗しました");
    });
};
