import { CustomModal } from "@/components/Modal";
import { PageTitle } from "@/components/Title";
import { Box, Button, Stack } from "@mui/material";
import { RestorePropertyGrid } from "./RestorePropertyGrid";
import { ButtonVariantOption, SubmitButton } from "@/components/Button";
import { type PropertySelectionRow, type RestorePropertyRow } from "../types";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  properties: RestorePropertyRow[];
  setProperties: React.Dispatch<React.SetStateAction<RestorePropertyRow[]>>;
  selectedRowIds: GridSelectionModel;
  setSelectedRowIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  setSelectedProperties: React.Dispatch<
    React.SetStateAction<PropertySelectionRow[]>
  >;
}

export const RestorePropertyModal: React.FC<IProps> = (props) => {
  const {
    open,
    setOpen,
    properties,
    setProperties,
    selectedRowIds,
    setSelectedRowIds,
    setSelectedProperties,
  } = props;

  /**
   * ピンのデータを呼び出し、画面に表示する。
   * selectedRowIdsには常に1件しか入らないので最初の要素を条件に使う。
   */
  const handleRestorePropertyHistory = (): void => {
    const property = properties.find(
      (property) => property.id === selectedRowIds[0]
    );

    if (property != null) {
      setSelectedProperties(property.properties);
      setSelectedRowIds([]);

      toast.success("ピンの呼び出しに成功しました。");
    } else {
      toast.error("ピンの呼び出しに失敗しました。");
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        variant={"outlined"}
        onClick={() => {
          setOpen(true);
        }}
      >
        ピンを呼び出す
      </Button>

      {/* ピンを呼び出すモーダル */}
      <CustomModal
        isOpen={open}
        handleClose={() => {
          setSelectedRowIds([]);
          setOpen(false);
        }}
        ariaLabelledby="restore-property-modal"
        ariaDescribedby="restore-property-modal-description"
        width={"80%"}
        height={"80%"}
      >
        {/* タイトル */}
        <PageTitle>ピン履歴を呼び出す</PageTitle>

        {/* リスト表示 */}
        <Box sx={{ mt: 2, height: "80%", width: "100%" }}>
          <RestorePropertyGrid
            rows={properties}
            setRows={setProperties}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
          />

          {/* 選択するボタン */}
          <Stack
            direction={"row"}
            justifyContent={"end"}
            spacing={1}
            marginTop={2}
          >
            <SubmitButton
              label="選択する"
              onClick={handleRestorePropertyHistory}
              timeout={30000}
              variant={ButtonVariantOption.Contained}
              disabled={!(properties.length && selectedRowIds.length)}
            />
          </Stack>
        </Box>
      </CustomModal>
    </>
  );
};
