import ListIcon from "@mui/icons-material/List";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

export const BookListButton: React.FC = () => {
  return (
    <LinkButton
      variant={ButtonVariantOption.Text}
      startIcon={<ListIcon />}
      href={"/acquirebooklist"}
      wrapperSize={SizingWrapperStyle.INHERIT}
      sx={{ color: "#FFF", fontWeight: "bold" }}
    >
      不動産登記/図面取得一覧
    </LinkButton>
  );
};
