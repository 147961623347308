import {
  Box,
  Link,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React, { useState } from "react";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { FeedGrid } from "../components/CompanyListFeedGrid";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiClient } from "@/hooks/useApiClient";
import type { PictureResponse } from "@/types/acquirebook";
import type { HandOffMessage } from "@/types/handOffMessage";

export interface FetchParams {
  matchMethod: string;
  matchType: string;
  closed: boolean;
  kubun: string;
  prefectureName: string;
  location: string;
  companyName?: string;
  corporateNumber?: string;
}

export interface CheckedCompanyDetail {
  closed: boolean;
  location: string;
  companyName: string;
  corporateNumber: string;
}

export const CompanyList: React.FC = () => {
  const [checkedCompanyDetails, setCheckedCompanyDetails] = useState<
    CheckedCompanyDetail[]
  >([]);
  const currentLocation = useLocation();
  const fetchParams = currentLocation.state as FetchParams;
  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = useState(false);

  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  const handleSubmit = (cancelLoading: () => void): void => {
    const reqList = checkedCompanyDetails.map((checkedCompanyDetail) => ({
      matchMethod: "会社法人等番号",
      matchType: "完全一致",
      closed: checkedCompanyDetail.closed,
      kubun: fetchParams.kubun,
      prefectureName: fetchParams.prefectureName,
      conditionLocation: fetchParams.location, // 検索条件の所在
      location: checkedCompanyDetail.location, // 法人一覧で取得した所在
      conditionCompanyName: fetchParams.companyName,
      companyName: checkedCompanyDetail.companyName,
      corporateNumber: checkedCompanyDetail.corporateNumber,
    }));
    apiClient
      .post<PictureResponse>("/commercial-picture-request/", reqList)
      .then((result) => {
        if (result.status === 200) {
          const handOffMessage: HandOffMessage = {
            command: "COMMERCIAL_INFO_SHOW_ACCEPT_MESSAGE",
          };
          navigate("/commercialbook/list", {
            replace: true,
            state: { handOffMessage },
          });
        }
      })
      .catch((e) => {
        console.error(e);
        cancelLoading();
        toast.error("商業登記の取得処理に失敗しました");
      });
  };
  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ImportContactsIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>会社・法人一覧</PageTitle>
          </Box>
        </Box>
        <SectionPaper>
          <FeedGrid
            checkedCompanyDetails={checkedCompanyDetails}
            setCheckedCompanyDetails={setCheckedCompanyDetails}
            fetchParams={fetchParams}
          />
          {/* 取得ボタン */}
          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              label="取得する"
              onClick={() => {
                setOpenModalFlag(true);
              }}
              variant={ButtonVariantOption.Contained}
              size={SizingWrapperStyle.SMALL}
              disabled={checkedCompanyDetails.length === 0}
            />
          </Box>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link href="/commercialbook" sx={{ display: "inline-block" }}>
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>商業登記検索画面に戻る</Typography>
            </Box>
          </Link>
        </Box>
      </form>
      {/* 確認のモーダル画面 */}
      <Dialog
        open={openModalFlag}
        onClose={() => {
          setOpenModalFlag(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            【課金が発生します】商業登記を取得します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            variant={ButtonVariantOption.Contained}
            onClick={(_event, cancelLoading) => {
              handleSubmit(cancelLoading);
            }}
            label={"取得する"}
            timeout={30000}
          />
          <Button
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              setOpenModalFlag(false);
            }}
            label={"キャンセル"}
          />
        </DialogActions>
      </Dialog>
    </PagePaper>
  );
};
