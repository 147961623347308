import React from "react";
import { ChibanViewer } from "./ChibanViewer";
import { useMapServicePermission } from "@/hooks/useMapServicePermission";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const ChibanViewerAccessControl: React.FC = () => {
  const [, loading] = useMapServicePermission();
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ChibanViewer />
    </>
  );
};
