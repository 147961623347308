import { Box, Link, Typography } from "@mui/material";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import React from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { AcquireBookFormUI } from "@/components/AcquireBook/AcquireBookFormUI";

export const AcquireBookPresenter: React.FC = () => {
  const { mypageTop } = useFeatureFlags();

  return (
    <PagePaper>
      <Box sx={{ display: "flex" }}>
        {/* ページタイトル */}
        <Box sx={{ display: "flex", mb: 3 }}>
          <RequestPageIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産登記/図面取得</PageTitle>
        </Box>
        <Box sx={{ ml: 10 }}>
          <ul>
            <li>最新の不動産登記/図面を取得いたします</li>
            <li>取得済みの場合は、課金が発生いたしません</li>
          </ul>
        </Box>
      </Box>

      <SectionPaper>
        <AcquireBookFormUI mode={"normal"} />
      </SectionPaper>
      {/* 戻る */}
      <Box sx={{ mt: 2 }}>
        <Link
          href={mypageTop ? "/mypage" : "/feed"}
          sx={{ display: "inline-block" }}
        >
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>
              {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
            </Typography>
          </Box>
        </Link>
      </Box>
    </PagePaper>
  );
};
