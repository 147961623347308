import { styled } from "@mui/material/styles";
import {
  type GridSelectionModel,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { CustomerManagementListDataGrid } from "@/components/DataGrid";
import { type CustomerStatusDataGridRow } from "@/features/customerManagement/types";
import { useState } from "react";
import { RealEstateOwnerStatusLabels } from "@/types/realEstateOwner";

const StyledDataGrid = styled(CustomerManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  // データグリッドのルートに対して border-radius を設定
  &.MuiDataGrid-root {
    border-radius: 0px !important;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "status",
      headerName: "ステータス",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<string>) =>
        RealEstateOwnerStatusLabels[
          params.value as keyof typeof RealEstateOwnerStatusLabels
        ],
    },
    {
      field: "realEstateType",
      headerName: "種別",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "originalShozai",
      headerName: "物件情報",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "連絡メールアドレス",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tel",
      headerName: "電話番号",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "postcode",
      headerName: "郵便番号",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ownerAddress",
      headerName: "所有者住所",
      width: 240,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ownerName",
      headerName: "所有者氏名",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "legalAffairsBureauRequestDate",
      headerName: "登記申請年月",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === undefined) return "";
        const date = new Date(value);
        return format(date, "yyyy/MM");
      },
    },
    {
      field: "mochibunStr",
      headerName: "持分",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "responsiblePersonName",
      headerName: "担当者名",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "receptionReason",
      headerName: "登記原因",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];
};

export const CustomerListFeedGrid: React.FC<{
  rows: CustomerStatusDataGridRow[];
  onSelectionModelChange?: (rows: CustomerStatusDataGridRow[]) => void;
  checkboxSelection?: boolean;
}> = (props) => {
  const { rows, onSelectionModelChange, checkboxSelection } = props;
  const colDef = makeGridColDef();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelectionModelChange = (
    newSelectionModel: GridSelectionModel
  ): void => {
    if (onSelectionModelChange) {
      setSelectionModel(newSelectionModel);
      const selectedRows = rows.filter((row) =>
        newSelectionModel.includes(row.realEstateOwnerCustomerManagementId)
      );
      onSelectionModelChange(selectedRows);
    }
  };

  return (
    <StyledDataGrid
      checkboxSelection={checkboxSelection}
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={false}
      rows={rows}
      columns={colDef}
      onSelectionModelChange={handleSelectionModelChange}
      pageSize={4}
      rowsPerPageOptions={[4]}
      getRowId={(row: CustomerStatusDataGridRow) =>
        row.realEstateOwnerCustomerManagementId
      }
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
      selectionModel={selectionModel}
    />
  );
};
