import { type AxiosInstance } from "axios";
import { type MonitoringDetailRow } from "../types";

interface IRequests {
  id: number; // モニタリングテーブル（RealEstateMonitoring）のID
}

interface IResponse {
  count: number; //  件数
  receptionReason: string; // 登記原因
  results: MonitoringDetailRow[]; // モニタリング詳細テーブル（RealEstateMonitoringDetail）の結果
}

/**
 * モニタリング詳細画面に表示するデータを取得する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 * @returns {Promise<MonitoringDetailRow[]>}
 */
export const GetMonitoringDetailAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<IResponse> => {
  // TODO: リストに表示するデータを取得するAPIを実行する。
  console.log("モニタリング詳細画面表示データ取得APIが実行されました。");
  console.log("id =", requests.id);

  return {
    count: 100,
    receptionReason: "相続・遺贈・売買",
    results: [
      {
        id: 1,
        monitoringId: 10,
        realEstateType: "LAND",
        location: "東京都千代田区丸の内一丁目",
        chibanKaokuNumber: "9-1",
        latestReceptionDate: new Date(2024, 9, 19),
        soto: null,
        remarks: "東京駅",
      },
      {
        id: 2,
        monitoringId: 10,
        realEstateType: "BUILDING",
        location: "大阪府大阪市淀川区西中島5",
        chibanKaokuNumber: "16-1",
        latestReceptionDate: new Date(2024, 9, 20),
        soto: 1,
        remarks: "新大阪駅",
      },
      {
        id: 3,
        monitoringId: 10,
        realEstateType: "CONDOMINIUM",
        location: "福岡県福岡市博多区博多駅中央街",
        chibanKaokuNumber: "1-1",
        latestReceptionDate: new Date(2024, 9, 21),
        soto: 3,
        remarks: "博多駅",
      },
    ],
  };
};
