import { styled } from "@mui/material";
import { type GridRenderCellParams, type GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "./DataGrid";
import { type MonitoringDetailReceptionBookRow } from "../types";
import { format } from "date-fns";

interface IProps {
  rows: MonitoringDetailReceptionBookRow[];
}

const StyledDataGrid = styled(DataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "legalAffairsBureauRequestDate",
      headerName: "法務局受付日",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value != null) return format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      field: "receptionReason",
      headerName: "登記原因",
      width: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "soto",
      headerName: "外",
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];
};

export const MonitoringDetailReceptionBookDataGrid: React.FC<IProps> = (
  props: IProps
) => {
  const { rows } = props;
  const colDef = makeGridColDef();

  return (
    <StyledDataGrid
      autoHeight
      columns={colDef}
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
    />
  );
};
