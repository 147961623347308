import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Button,
  ButtonVariantOption,
  ButtonColorOption,
} from "@/components/Button";

interface CustomerDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CustomerDeleteDialog: React.FC<CustomerDeleteDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"削除の確認"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          この顧客を削除してもよろしいですか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label="いいえ"
          onClick={onClose}
          variant={ButtonVariantOption.Outlined}
          color={ButtonColorOption.Primary}
        />
        <Button
          label="はい"
          onClick={onConfirm}
          variant={ButtonVariantOption.Outlined}
          color={ButtonColorOption.Error}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDeleteDialog;
