import { type RestorePropertyRow } from "../types";
import { type GridSelectionModel, type GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "./DataGrid";
import { useCallback } from "react";

interface IProps {
  rows: RestorePropertyRow[];
  setRows: React.Dispatch<React.SetStateAction<RestorePropertyRow[]>>;
  selectedRowIds: GridSelectionModel;
  setSelectedRowIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
}

export const RestorePropertyGrid: React.FC<IProps> = (props) => {
  const { rows, setRows, selectedRowIds, setSelectedRowIds } = props;

  /**
   * 選択した行のidをselectedRowIdsに保存しselectedを更新する。
   */
  const handleRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedRowIds(newSelectionModel);

      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          selected: newSelectionModel.includes(row.id),
        }))
      );
    },
    [setRows]
  );

  // カラム設定
  const columns: GridColDef[] = [
    {
      field: "id",
      width: 30,
      align: "center",
    },
    {
      field: "recordName",
      headerName: "記録名",
      width: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "count",
      headerName: "件数",
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "saveDatetime",
      headerName: "保存日時",
      width: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <StyledDataGrid
      disableColumnMenu
      rows={rows}
      columns={columns}
      selectionModel={selectedRowIds}
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      onSelectionModelChange={handleRowSelectionChange}
    />
  );
};
