import { TextField as MuiTextField, FormControl } from "@mui/material";
import {
  type ISizingWrapperProps,
  SizingWrapper,
  type SizingWrapperStyle,
} from "@/components/Wrapper/SizingWrapper";

interface IProps extends ISizingWrapperProps {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onKeyUp?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
  autoFocus?: boolean;
  type?: React.InputHTMLAttributes<unknown>["type"];
  size?: SizingWrapperStyle;
  disabled?: boolean;
}

const TextField: React.FC<IProps> = (props) => {
  const {
    label,
    value,
    onChange,
    onKeyUp,
    required,
    autoFocus,
    type,
    id,
    size,
    disabled,
  } = props;

  return (
    <SizingWrapper size={size}>
      <FormControl fullWidth>
        <MuiTextField
          id={id}
          label={label}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (onKeyUp) {
              onKeyUp(event);
            }
          }}
          required={required}
          autoFocus={autoFocus}
          type={type}
          disabled={disabled}
        />
      </FormControl>
    </SizingWrapper>
  );
};
export { TextField };
