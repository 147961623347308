import { useApiClient } from "../../../hooks/useApiClient";
import useSWR from "swr";
import {
  type IRealEstateReceptionBookResponse,
  type ISortCondition,
  type IUseFeedParams,
  type IFetchParams,
} from "../types";
import { useState } from "react";
import { toFetchParams } from "../utils/feedUtils";

// custom hookの戻り値の型
interface IUseFeedReturnType {
  feed: IRealEstateReceptionBookResponse | undefined;
  allCount: number;
  from: number;
  prev: () => void;
  next: () => void;
  sort: (condition: ISortCondition | undefined) => void;
}

// 不動産受付帳の一覧を取得するcustom hook
export const useFeed = (body: IUseFeedParams): IUseFeedReturnType => {
  const { apiClient } = useApiClient();
  // 何件目から取得するか。SQLでいうところのoffset
  const [from, setFrom] = useState<number>(0);
  // ソート条件
  const [sortCondition, setSortCondition] = useState<
    ISortCondition | undefined
  >();

  // swrに引き渡すfetcher
  const fetcher = async (
    args: [string, IFetchParams]
  ): Promise<IRealEstateReceptionBookResponse> => {
    const [url, body] = args;
    if (body.cities.length === 0) {
      return { list: [], count: 0 };
    }
    const { data } = await apiClient.post<IRealEstateReceptionBookResponse>(
      url,
      { body }
    );
    return data;
  };

  const { data } = useSWR<IRealEstateReceptionBookResponse>(
    ["/real-estate-book/feed", toFetchParams(body, from, sortCondition)],
    fetcher,
    { revalidateOnFocus: false }
  );

  const prev = (): void => {
    if (from === 0) return;
    setFrom(from - body.size);
  };

  const next = (): void => {
    if (data === undefined) return;

    const { size } = body;
    const { count } = data;
    if (from + size >= count) return;
    setFrom(from + size);
  };

  const sort = (params: ISortCondition | undefined): void => {
    setSortCondition(params);
  };

  return {
    feed: data,
    allCount: data?.count ?? 0,
    from,
    prev,
    next,
    sort,
  };
};
