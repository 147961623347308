import { styled } from "@mui/material/styles";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { CompanyDataGrid } from "../../../components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { CompanyListAPI } from "@/features/commercialBook/api";
import { Typography } from "@mui/material";
import {
  type FetchParams,
  type CheckedCompanyDetail,
} from "@/features/commercialBook/routes/CompanyList";

interface CompanyListDataGridRow {
  id: number;
  corporateNumber: string;
  companyName: string;
  location: string;
  closed: boolean;
}

interface FeedGridProps {
  checkedCompanyDetails: CheckedCompanyDetail[];
  setCheckedCompanyDetails: React.Dispatch<
    React.SetStateAction<CheckedCompanyDetail[]>
  >;
  fetchParams: FetchParams;
}

const StyledDataGrid = styled(CompanyDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (
  checkedCompanyDetails: CheckedCompanyDetail[],
  setCheckedCompanyDetails: React.Dispatch<
    React.SetStateAction<CheckedCompanyDetail[]>
  >,
  selectAllCompanyDetail: boolean,
  setSelectAllCompanyDetail: React.Dispatch<React.SetStateAction<boolean>>,
  rows: CompanyListDataGridRow[]
): GridColDef[] => {
  const handleCheckboxChange = (companyDetail: CheckedCompanyDetail): void => {
    const currentIndex = checkedCompanyDetails.findIndex(
      (item) => item.corporateNumber === companyDetail.corporateNumber
    );
    const newChecked = [...checkedCompanyDetails];

    if (currentIndex === -1) {
      newChecked.push(companyDetail);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectAllCompanyDetail(false);
    setCheckedCompanyDetails(newChecked);
  };

  const handleSelectAllCompanyInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      const allCompanyDetails = rows.map((row) => ({
        corporateNumber: row.corporateNumber,
        location: row.location,
        companyName: row.companyName,
        closed: row.closed,
      }));
      setCheckedCompanyDetails(allCompanyDetails);
    } else {
      setCheckedCompanyDetails([]);
    }
    setSelectAllCompanyDetail(event.target.checked);
  };

  return [
    {
      field: "checkbox",
      headerName: "",
      width: 70,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<CompanyListDataGridRow>) => (
        <Checkbox
          onChange={() => {
            const { corporateNumber, location, companyName, closed } =
              params.row as CompanyListDataGridRow;

            const companyDetail: CheckedCompanyDetail = {
              corporateNumber,
              location,
              companyName,
              closed,
            };
            handleCheckboxChange(companyDetail);
          }}
          checked={checkedCompanyDetails.some(
            (detail) =>
              detail.corporateNumber ===
              (params.row as CompanyListDataGridRow).corporateNumber
          )}
        />
      ),
      renderHeader: () => {
        return (
          <Checkbox
            checked={selectAllCompanyDetail}
            onChange={handleSelectAllCompanyInfoChange}
          />
        );
      },
    },
    {
      field: "id",
      headerName: "No",
      width: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams<CompanyListDataGridRow>) => {
        return Number(params.id) + 1;
      },
    },
    {
      field: "corporateNumber",
      headerName: "会社法人等番号",
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<CompanyListDataGridRow>) => {
        const { corporateNumber, closed } =
          params.row as CompanyListDataGridRow;
        return (
          <div>
            {corporateNumber}
            {closed && (
              <Typography variant="caption" display="block">
                (閉鎖登記簿)
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: "companyName",
      headerName: "商号・名称",
      width: 500,
      sortable: false,
    },
    {
      field: "location",
      headerName: "所在地",
      width: 500,
      sortable: false,
    },
  ];
};

export const FeedGrid: React.FC<FeedGridProps> = ({
  checkedCompanyDetails,
  setCheckedCompanyDetails,
  fetchParams,
}) => {
  // API側と変数名を揃える
  const convertedFetchParams = {
    ...fetchParams,
    todouhuken: fetchParams.prefectureName,
    shyozai: fetchParams.location,
  };
  const { data: rows, isLoading } = CompanyListAPI(convertedFetchParams);
  const [selectAllCompanyDetail, setSelectAllCompanyDetail] =
    useState<boolean>(false);

  const colDef = makeGridColDef(
    checkedCompanyDetails,
    setCheckedCompanyDetails,
    selectAllCompanyDetail,
    setSelectAllCompanyDetail,
    rows
  );

  return (
    <>
      <StyledDataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick={true}
        rows={rows}
        loading={isLoading}
        columns={colDef}
        sortModel={[
          {
            field: "id",
            sort: "asc",
          },
        ]}
      />
      {isLoading && (
        <Typography sx={{ textAlign: "center", mt: 3 }}>
          法人一覧の取得まで30秒以上かかりますので、しばらくお待ちください
        </Typography>
      )}
    </>
  );
};
