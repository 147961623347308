import applyCaseMiddleware from "axios-case-converter"; // TODO: かなりニッチなライブラリのため、自前で実装するか他のライブラリを探す
import axios, {
  type AxiosInstance,
  type AxiosError,
  type AxiosResponse,
} from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies, Cookies } from "react-cookie";
import { type IJwtResponse } from "../types/jwt";
import { type ICookiesType } from "../types/cookies";
import { baseURL } from "../configs";

interface IUseApiClientReturnType {
  apiClient: AxiosInstance;
}

const useApiClient = (): IUseApiClientReturnType => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();

  const apiClient: AxiosInstance = applyCaseMiddleware(
    axios.create({
      baseURL,
    })
  );

  apiClient.interceptors.request.use((config) => {
    const { accesstoken } = cookies as ICookiesType;
    const c = new Cookies();
    config.headers.Authorization = `JWT ${accesstoken ?? c.get("accesstoken")}`;
    return config;
  });

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      // API通信時にトークン切れになった場合、トークンを更新する
      if (error.response?.config != null && error.response.status === 401) {
        const { refreshtoken } = cookies as ICookiesType;
        await apiClient
          .post("auth/jwt/refresh", {
            refresh: refreshtoken,
          })
          .then((res: AxiosResponse<IJwtResponse>) => {
            setCookie("accesstoken", res.data.access, { path: "/" });
            setCookie("refreshtoken", res.data.refresh, { path: "/" });
          })
          .catch(() => {
            // リレッシュトークンが期限切れの場合はログイン画面に遷移
            setCookie("isLogin", "false", { path: "/" });
            navigate("/login");
          });
      }
      return await Promise.reject(error);
    }
  );

  return { apiClient };
};

export { useApiClient };
