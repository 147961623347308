import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import { type ISelectObject } from "@/types/select";
import { useState, Fragment, useEffect } from "react";
import { useApiClient } from "@/hooks/useApiClient";
import type { IStationResponse } from "@/features/realEstateReceptionBookFeed/types";
import useSWRMutation from "swr/mutation";
import Tooltip from "@mui/material/Tooltip";
import type { Key } from "swr";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  label?: string;
  placeholder: string;
  cityIds?: string[];
  lineId?: number;
  stationSelectedValues: ISelectObject[];
  setStationSelectedValues: React.Dispatch<
    React.SetStateAction<ISelectObject[]>
  >;
}

interface StationRequestParam {
  lineId?: string;
  cityIds?: string[];
}

/**
 * 駅名が重複する場合は最初の一つ目の駅を残し、それ以外は削除する。
 * 削除された駅のvalueの値を、残した駅のadditional_valuesに追加する。
 * @param data :ISelectObject[]
 * @return ISelectObject[]
 */
const preprocessOptionsData = (data: ISelectObject[]): ISelectObject[] => {
  const results: ISelectObject[] = [];

  data.forEach((item: ISelectObject) => {
    // 同じ名前の駅がresultsにあるか調べる
    const foundItem = results.find((value) => value.label === item.label);
    if (foundItem === undefined) {
      // resultsに同じ名前の駅が見つからない場合、resultsに新規追加
      results.push({ ...item, additional_values: new Array<string>() });
    } else {
      // 同じ名前の駅が見つかった場合は、additional_valuesにitem.valueの値を追加
      foundItem.additional_values?.push(item.value);
    }
  });

  return results;
};

export const AutocompleteMultiStationSelect: React.FC<IProps> = (
  props: IProps
) => {
  const {
    label,
    placeholder,
    cityIds,
    lineId,
    stationSelectedValues,
    setStationSelectedValues,
  } = props;

  const { apiClient } = useApiClient();

  // 選択項目の展開状態を保持するState
  const [open, setOpen] = useState(false);

  // 選択項目の中身のリストを保持するState
  const [options, setOptions] = useState<readonly ISelectObject[] | undefined>(
    undefined
  );

  // 手動入力した文字列を保持するstate
  const [inputValue, setInputValue] = useState<string>("");

  // 読み込み中と表示するかしないかを決める変数
  const loading = open && options === undefined;

  const fetcher = async (
    url: string,
    { arg }: { arg: StationRequestParam }
  ): Promise<IStationResponse> => {
    const { data } = await apiClient.get<IStationResponse>(url, {
      params: {
        city_ids: arg.cityIds,
        line_id: arg.lineId,
      },
    });
    return data;
  };

  // 駅一覧問い合わせ先のURLを構築する
  const key = "/option/station-names";
  const { trigger } = useSWRMutation<
    IStationResponse,
    unknown,
    Key,
    StationRequestParam
  >(key, fetcher);

  // メニューが開かれた時に実行し、駅一覧をバックエンドへ問い合わせる
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const arg: StationRequestParam = {
        lineId: lineId && lineId > 0 ? lineId.toString() : undefined,
        cityIds: cityIds && cityIds.length > 0 ? cityIds : undefined,
      };
      const data = await trigger(arg);

      if (active) {
        setOptions(preprocessOptionsData(data?.list ?? []));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions(undefined);
    }
  }, [open]);

  return (
    <Tooltip
      title="市町村もしくは路線名を選択した上で入力してください"
      placement="top"
    >
      <Autocomplete
        multiple
        id="station-select-box"
        inputValue={inputValue}
        onInputChange={(_event, value, _reason) => {
          setInputValue(value);
        }}
        value={stationSelectedValues}
        onChange={(_event, value, _reason, _details) => {
          setStationSelectedValues(value);
        }}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        options={options ?? []}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        loadingText={"駅一覧を読み込み中"}
        noOptionsText={"該当する駅がありません"}
        disabled={
          (lineId === undefined || lineId === -1) &&
          (cityIds === undefined || cityIds.length === 0)
        }
        disableCloseOnSelect
        // チェックボックスのチェック状態を保持するために必要
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        style={{ width: 360 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </Tooltip>
  );
};
