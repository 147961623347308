import { styled } from "@mui/material/styles";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  Link,
  FormControl,
  InputLabel,
  Select,
  type SelectChangeEvent,
  Typography,
  MenuItem,
  Snackbar,
  Alert,
  type AlertColor,
} from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import React, { useEffect, useState } from "react";
import { Label } from "@/components/Label";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { SizingWrapperStyle } from "@/components/Wrapper";
import {
  Button,
  ButtonColorOption,
  ButtonVariantOption,
  LinkButton,
} from "@/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  type CustomerDetailResponse,
  useCustomerDetail,
} from "../hooks/useCustomerDetail";
import { KeyboardArrowLeft } from "@mui/icons-material";
import TextArea from "@/components/TextArea/TextArea";
import { TextFieldEmail } from "@/components/TextField/TextFieldEmail";
import { TextFieldTell } from "@/components/TextField/TextFieldTell";
import { TextFieldReadOnly } from "@/components/TextField";
import CustomerDeleteDialog from "../components/CustomerDeleteDialog";
import { format } from "date-fns";
import { MailHistoriesDataGrid } from "@/components/DataGrid/MailHistoriesDataGrid";
import { RealEstateOwnerStatusLabels } from "@/types/realEstateOwner";

const StyledDataGrid = styled(MailHistoriesDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "title",
      headerName: "メールタイトル",
      width: 600,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "scheduledSendAt",
      headerName: "送信日",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === undefined) return "";
        return format(new Date(value), "yyyy/MM/dd HH:mm:ss");
      },
    },
  ];
};

export const CustomerDetail: React.FC = () => {
  const { id } = useParams();
  const customerId = Number(id);
  const navigate = useNavigate();
  const colDef = makeGridColDef();

  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (): void => {
    setSnackbar({ ...snackbar, open: false });
  };

  // 顧客情報, 保存, 削除の処理を行うカスタムフック
  const { data, handleSubmit, handleDelete } = useCustomerDetail(customerId);

  // 顧客情報を保持するstate
  const [customerData, setCustomerData] = useState<CustomerDetailResponse>({
    realEstateOwnerId: data?.realEstateOwnerId ?? customerId,
    realEstateOwnerCustomerManagementId:
      data?.realEstateOwnerCustomerManagementId ?? null,
    status: data?.status ?? null,
    realEstateType: data?.realEstateType ?? null,
    originalShozai: data?.originalShozai ?? null,
    postcode: data?.postcode ?? null,
    ownerAddress: data?.ownerAddress ?? "",
    ownerName: data?.ownerName ?? "",
    legalAffairsBureauRequestDate: data?.legalAffairsBureauRequestDate ?? null,
    mochibunStr: data?.mochibunStr ?? "",
    receptionReason: data?.receptionReason ?? null,
    email: data?.email ?? null,
    tel: data?.tel ?? null,
    memo: data?.memo ?? null,
    responsiblePersonName: data?.responsiblePersonName ?? "",
    isOutOfTarget: data?.isOutOfTarget ?? false,
    emailHistories: data?.emailHistories ?? [],
  });

  // 顧客情報の変更
  const onChangeCustomerData = (
    key: keyof CustomerDetailResponse,
    value: string
  ): void => {
    setCustomerData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setCustomerData({
      realEstateOwnerId: data?.realEstateOwnerId ?? customerId,
      realEstateOwnerCustomerManagementId:
        data?.realEstateOwnerCustomerManagementId ?? null,
      status: data?.status ?? null,
      realEstateType: data?.realEstateType ?? null,
      originalShozai: data?.originalShozai ?? null,
      postcode: data?.postcode ?? null,
      ownerAddress: data?.ownerAddress ?? "",
      ownerName: data?.ownerName ?? "",
      legalAffairsBureauRequestDate:
        data?.legalAffairsBureauRequestDate ?? null,
      mochibunStr: data?.mochibunStr ?? "",
      receptionReason: data?.receptionReason ?? null,
      email: data?.email ?? null,
      tel: data?.tel ?? null,
      memo: data?.memo ?? null,
      responsiblePersonName: data?.responsiblePersonName ?? "",
      isOutOfTarget: data?.isOutOfTarget ?? false,
      emailHistories: data?.emailHistories ?? [],
    });
  }, [data, customerId]);

  return (
    <PagePaper>
      <form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ManageSearchIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>顧客詳細</PageTitle>
          </Box>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ top: { sm: 80, xs: 65 } }} // ヘッダーの高さを考慮し調整
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>

        <SectionPaper>
          <Box sx={{ m: 4 }}>
            <Box sx={{ mx: 2, mt: 2, mb: 1 }}>
              <Label>物件情報</Label>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFieldReadOnly
                    label="所在+地番"
                    value={customerData?.originalShozai ?? ""}
                    size={SizingWrapperStyle.INHERIT}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldReadOnly
                    label="登記原因"
                    value={customerData?.receptionReason ?? ""}
                    size={SizingWrapperStyle.INHERIT}
                    disabled
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">ステータス</InputLabel>
                    <Select
                      labelId="select-label"
                      label="ステータス"
                      value={customerData?.status ?? ""}
                      onChange={(event: SelectChangeEvent<string>) => {
                        onChangeCustomerData("status", event.target.value);
                      }}
                    >
                      {Object.entries(RealEstateOwnerStatusLabels).map(
                        ([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mx: 2, mt: 2, mb: 1 }}>
              <Label>顧客情報</Label>
              <Grid container>
                <Box sx={{ mr: 1 }}>
                  <TextFieldEmail
                    id="email"
                    label="連絡先メールアドレス"
                    value={customerData?.email ?? ""}
                    onChange={(value) => {
                      onChangeCustomerData("email", value);
                    }}
                  />
                </Box>
                <TextFieldTell
                  id="tel"
                  label="電話番号"
                  value={customerData?.tel ?? ""}
                  onChange={(value) => {
                    onChangeCustomerData("tel", value);
                  }}
                />
              </Grid>
            </Box>

            <Box sx={{ mx: 2, mt: 2, mb: 1 }}>
              <Label>営業情報</Label>
              <Grid container>
                <TextArea
                  minRows={6}
                  maxRows={10}
                  style={{ width: "100%" }}
                  aria-label="営業メモ"
                  placeholder="営業メモをここに記載してください。"
                  defaultValue={customerData?.memo ?? ""}
                  onChange={(value) => {
                    onChangeCustomerData("memo", value);
                  }}
                />
              </Grid>
            </Box>
            {customerData?.emailHistories && (
              <Box sx={{ mx: 2, mt: 2, mb: 1 }}>
                <Box
                  sx={{
                    mr: 2,
                    mb: 1,
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Label
                    sx={{
                      my: "auto",
                      mx: 0,
                    }}
                  >
                    メール履歴
                  </Label>
                  <LinkButton
                    href={`/emailManagement/mail/createMail?customerId=${customerId}`}
                    variant={ButtonVariantOption.Outlined}
                    wrapperSize={SizingWrapperStyle.SMALL}
                  >
                    新規作成
                  </LinkButton>
                </Box>
                <SectionPaper>
                  <StyledDataGrid
                    autoHeight
                    disableColumnMenu
                    rows={customerData?.emailHistories}
                    columns={colDef}
                  />
                </SectionPaper>
              </Box>
            )}
            <Box
              sx={{
                mr: 2,
                my: 3,
                display: "flex",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                label="保存"
                disabled={!customerData.status}
                onClick={() => {
                  (async () => {
                    try {
                      await handleSubmit(customerId, customerData);
                      setSnackbar({
                        open: true,
                        message: "保存が完了しました。",
                        severity: "success",
                      });
                    } catch (error) {
                      console.error("保存に失敗しました。", error);
                      setSnackbar({
                        open: true,
                        message: "保存に失敗しました。",
                        severity: "error",
                      });
                    }
                  })();
                }}
                variant={ButtonVariantOption.Contained}
                size={SizingWrapperStyle.SMALL}
              />
              <Button
                label="削除"
                onClick={() => {
                  setOpenDialog(true);
                }}
                variant={ButtonVariantOption.Outlined}
                color={ButtonColorOption.Error}
                size={SizingWrapperStyle.SMALL}
              />
            </Box>
          </Box>
        </SectionPaper>

        <Box sx={{ mt: 2 }}>
          <Link
            href="/customermanagement/customerList"
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>顧客一覧に戻る</Typography>
            </Box>
          </Link>
        </Box>
      </form>

      {/* 削除確認ダイアログ */}
      <CustomerDeleteDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        onConfirm={() => {
          setOpenDialog(false);
          (async () => {
            try {
              await handleDelete(
                customerId,
                Number(customerData.realEstateOwnerCustomerManagementId)
              );
              navigate("/customermanagement/customerList");
            } catch (error) {
              console.error("削除に失敗しました。", error);
            }
          })();
        }}
      />
    </PagePaper>
  );
};
