import MenuItem from "@mui/material/MenuItem";
import {
  type GridExportMenuItemProps,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  type ExcelExportOptions,
  useExcelExport,
} from "@/hooks/useExcelExport";

// GridExportMenuItemPropsにhidemenu()の型定義が書かれている
declare type ExcelExportMenuItemProps =
  GridExportMenuItemProps<ExcelExportOptions>;

const ExcelExportMenuItem: React.FC<ExcelExportMenuItemProps> = (props) => {
  const { hideMenu, options } = props;

  const { downloadAsExcelFile, makeExcelUint8Array, getFeedGridData } =
    useExcelExport();

  // feedGridの内部データを読み書きするためのハンドルみたいなものを取得
  const apiRef = useGridApiContext();

  return (
    <>
      <MenuItem
        onClick={() => {
          // 親のReact componentでoptionの設定を忘れていた場合に使用されるデフォルトのファイル名とシート名
          const defaultSheetName = "不動産登記受付帳";
          const defaultFileName = "R.E.DATA_不動産データ";

          const arrayOfArray = getFeedGridData(apiRef, options);
          const data = makeExcelUint8Array(
            arrayOfArray,
            options?.sheetName ?? defaultSheetName
          );
          downloadAsExcelFile(data, options?.fileName ?? defaultFileName);

          // メニューを閉じる
          hideMenu?.();
        }}
      >
        エクセルダウンロード
      </MenuItem>
    </>
  );
};

export { ExcelExportMenuItem };
