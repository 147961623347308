import { type AxiosResponse, isAxiosError } from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { type ICookiesType } from "../types/cookies";
import { type IJwtResponse, type IJwtErrorResponse } from "../types/jwt";
import { useApiClient } from "./useApiClient";
import { useUserMe } from "@/features/login/hooks/useUserMe";
import { useLocalStorage } from "./useLocalStorage";
import { type User } from "@/types/localstorage";
import { useGeoSpaceApi } from "@/features/map/hooks/useGeospaceSearch";

interface ReturnTypes {
  isLoggedIn: boolean;
  login: (
    email: string,
    password: string,
    userIp: string,
    cancelLoading: () => void
  ) => Promise<void>;
  logout: () => void;
}
export const useLogin = (): ReturnTypes => {
  const [cookies, setCookie, removeCookie] = useCookies(); // eslint-disable-line
  const typedCookies = cookies as ICookiesType;
  const navigate = useNavigate();
  const { apiClient } = useApiClient();
  const { userMe } = useUserMe();
  const [, , removeValue] = useLocalStorage<User | undefined>(
    "user",
    undefined
  );
  const { geospaceGetMapServiceContractAppId } = useGeoSpaceApi();

  const login = async (
    email: string,
    password: string,
    userIp: string,
    cancelLoading: () => void
  ): Promise<void> => {
    await apiClient
      .post("auth/jwt/create", {
        email,
        password,
        userIp,
      })
      .then(async (response: AxiosResponse<IJwtResponse>) => {
        setCookie("accesstoken", response.data.access, { path: "/" });
        setCookie("refreshtoken", response.data.refresh, { path: "/" });
        userMe();
        const mapServiceAppId = await geospaceGetMapServiceContractAppId();
        if (mapServiceAppId) {
          localStorage.setItem("mapServiceAppId", mapServiceAppId);
        } else {
          localStorage.removeItem("mapServiceAppId");
        }
        setCookie("isLogin", "true", { path: "/" });
      })
      .catch((e) => {
        if (
          isAxiosError<IJwtErrorResponse>(e) &&
          e.response &&
          e.response.data
        ) {
          if (
            e.response.data.errorMessage &&
            e.response.data.errorMessage[0] ===
              "契約終了したためログインできません"
          ) {
            toast.error(e.response.data.errorMessage[0]);
            return;
          }
        }
        toast.error("ログインに失敗しました");
      })
      .finally(() => {
        cancelLoading();
      });
  };

  const logout = (): void => {
    removeCookie("accesstoken", { path: "/" });
    removeCookie("refreshtoken", { path: "/" });
    localStorage.removeItem("mapServiceAppId");
    removeValue();
    setCookie("isLogin", "false", { path: "/" });
    navigate("/login");
  };

  return {
    isLoggedIn: typedCookies.isLogin === "true",
    login,
    logout,
  };
};
