import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

export const InquiryButton: React.FC = () => {
  return (
    <LinkButton
      variant={ButtonVariantOption.Text}
      startIcon={<MailOutlineIcon />}
      href={
        "https://docs.google.com/forms/d/e/1FAIpQLSc55YOK76TfgQyXEYUoXQS6eZIC25V2qwKdILOMxV87fBCdIQ/viewform"
      }
      wrapperSize={SizingWrapperStyle.INHERIT}
      sx={{ color: "#FFF", fontWeight: "bold" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      お問い合わせ
    </LinkButton>
  );
};
