import {
  DataGrid as MuiDataGrid,
  jaJP,
  type DataGridProps,
} from "@mui/x-data-grid";

// 不要であったり、不変にしたり等で外から受け取らないプロパティ
type OmitProperties = "density" | "localeText";

interface IProps extends Omit<DataGridProps, OmitProperties> {}

const EmailManagementListDataGrid: React.FC<IProps> = (props) => {
  return (
    <MuiDataGrid
      density="standard"
      localeText={{
        ...jaJP.components.MuiDataGrid.defaultProps.localeText,
      }}
      {...props}
    />
  );
};

EmailManagementListDataGrid.defaultProps = {
  loading: false,
  disableSelectionOnClick: false,
};

export { EmailManagementListDataGrid };
