import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import BusinessIcon from "@mui/icons-material/Business";

export const CommercialBookListButton: React.FC = () => {
  return (
    <LinkButton
      startIcon={<BusinessIcon />}
      href={"/commercialbook/list"}
      variant={ButtonVariantOption.Text}
      wrapperSize={SizingWrapperStyle.INHERIT}
      sx={{ color: "#FFF", fontWeight: "bold" }}
    >
      商業登記取得一覧
    </LinkButton>
  );
};
