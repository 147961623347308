import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { PageTitle } from "@/components/Title";

import {
  type FilterCondition,
  type CustomerStatusDataGridRow,
} from "@/features/customerManagement/types";
import { useApiClient } from "@/hooks/useApiClient";
import { GetCustomerListAPI } from "@/features/emailManagement/api";
import { CustomerListFilteringCondition } from "./CustomerListFilteringCondition";
import { CustomerListFeedGrid } from "./CustomerListFeedGrid";
import { Divider } from "@/components/Divider";
import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

interface Props {
  onAddCustomer: (rows: CustomerStatusDataGridRow[]) => void;
}

export const SearchCustomerModal: React.FC<Props> = ({ onAddCustomer }) => {
  // 検索結果の顧客リスト管理
  const [searchRows, setSearchRows] = useState<CustomerStatusDataGridRow[]>([]);
  // 選択された顧客リスト管理
  const [selectedRows, setSelectedRows] = useState<CustomerStatusDataGridRow[]>(
    []
  );

  // 検索条件の状態管理
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({
    prefectures: [],
    cities: [],
    // 不動産種別
    realEstateTypeTochi: false,
    realEstateTypeKutate: false,
    realEstateTypeTatemono: false,
  });
  const { apiClient } = useApiClient();

  // 検索条件に基づいて顧客リストを取得
  const handleFiltering = async (): Promise<void> => {
    const realEstateTypes: string[] = [];
    if (filterCondition.realEstateTypeKutate)
      realEstateTypes.push("CONDOMINIUM");
    if (filterCondition.realEstateTypeTatemono)
      realEstateTypes.push("BUILDING");
    if (filterCondition.realEstateTypeTochi) realEstateTypes.push("LAND");

    const response = await GetCustomerListAPI(
      apiClient,
      "/real-estate-owner-customer-management/customer-list",
      filterCondition.cities,
      filterCondition.prefectures,
      realEstateTypes
    );
    setSearchRows(response);
  };

  // 選択された顧客リストを更新
  const handleRowSelection = (rows: CustomerStatusDataGridRow[]): void => {
    setSelectedRows(rows);
  };

  React.useEffect(() => {
    // 初回レンダリング時に顧客リストを取得
    void handleFiltering();
  }, []);

  return (
    <>
      <PageTitle>顧客追加</PageTitle>
      <Stack my={2} direction="column" spacing={1}>
        <CustomerListFilteringCondition
          filterCondition={filterCondition}
          handleFiltering={() => {
            (async () => {
              await handleFiltering();
            })();
          }}
          setFilterCondition={setFilterCondition}
        />

        <Divider />

        <Box
          sx={{
            mr: 2,
            my: 3,
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            label="顧客登録"
            onClick={() => {
              onAddCustomer(selectedRows);
            }}
            variant={ButtonVariantOption.Outlined}
            size={SizingWrapperStyle.SMALL}
          />
        </Box>
      </Stack>

      <CustomerListFeedGrid
        rows={searchRows}
        onSelectionModelChange={handleRowSelection}
        checkboxSelection
      />
    </>
  );
};
