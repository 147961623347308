import { format, isValid } from "date-fns";

/**
 * 検索の開始日および終了日を取得する
 * @param nowDate - 取得対象となる日付の基準日 デフォルトでは現在時刻が取得される
 * @returns - 検索開始日と終了日
 */
export const getStartAndEndDate = (
  nowDate: Date = new Date()
): [Date, Date] => {
  const year = nowDate.getFullYear();
  const startDate = new Date(year, nowDate.getMonth() - 3, 1);
  const endDate = new Date(year, nowDate.getMonth() - 1, 0);
  return [startDate, endDate];
};

/**
 * 検索可能な日付の一番前の日付を取得する
 * @returns - 検索可能な日付の一番前の日付
 */
export const getSearchMinDate = (): Date => {
  return new Date(2012, 0, 1);
};

/**
 * 検索可能な日付の一番後ろの日付を取得する
 * @returns - 検索可能な日付の一番後ろの日付 今月の末日
 */
export const getSearchMaxDate = (): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0);
};

/**
 * 二つの日付が1年以上離れているかどうかを判定する
 * @param startDate - 開始日
 * @param endDate - 終了日
 * @returns - 二つの日付が1年以上離れていればTrue、それ以外はFalse
 */
export const isDateDiffOverOneYear = (
  startDate: Date,
  endDate: Date
): boolean => {
  const diff = endDate.getTime() - startDate.getTime();
  return diff / (24 * 60 * 60 * 1000) > 365;
};

/**
 * Date型をyyyy-MM-ddの文字列に変換する関数
 * @param {Date | null} date
 * @returns {string | null}
 */
export const dateToString = (date: Date | null): string | null => {
  if (date === null || !isValid(date)) return null;

  return format(date, "yyyy-MM-dd");
};

/**
 * Dateオブジェクトから新たな年最終日のDateを作成する
 * @param {Date | null} date
 * @returns {Date | null}
 */
export const dateToYearEnd = (date: Date | null): Date | null => {
  if (date === null || !isValid(date)) return null;
  const d = new Date(date.getTime());
  d.setMonth(12);
  d.setDate(31);
  return d;
};
