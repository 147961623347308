import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "@/components/DataGrid";
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useApiClient } from "@/hooks/useApiClient";
import useSWR from "swr";
import { Stack } from "@mui/material";
import OutputMenu from "@/features/ownerInfo/components/OutputMenu";
import { format } from "date-fns";
import { isActionEnabled, statusToLabel } from "@/features/ownerInfo/utils";
import { type RequestDetailAndRealEstates } from "@/features/ownerInfo/types";
import CircularProgress from "@mui/material/CircularProgress";
import React, {
  forwardRef,
  type ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

interface OwnerInfoListGridRow extends RequestDetailAndRealEstates {
  action?: boolean;
}

interface OwnerInfoListAPIResponse {
  count: number;
  list: OwnerInfoListGridRow[];
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "taskId",
      headerName: "識別番号",
      width: 110,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "identificationName",
      headerName: "識別名",
      width: 220,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "succeedCount",
      headerName: "アップロード件数",
      width: 150,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "createdAt",
      type: "dateTime",
      headerName: "アップロード日時",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const value = params.value;
        if (value == null) return "";
        return format(new Date(value), "yyyy-MM-dd HH:mm");
      },
    },
    {
      field: "status",
      headerName: "解析状況",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        return statusToLabel(value);
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 240,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({
        id,
        row,
      }: GridRenderCellParams<string, OwnerInfoListGridRow>) => {
        return (
          <Stack direction="row" spacing={2}>
            <LinkButton
              disabled={!isActionEnabled(row.status)}
              variant={ButtonVariantOption.Contained}
              wrapperSize={SizingWrapperStyle.INHERIT}
              href={`/ownerinfo/detail/${id}/`}
            >
              詳細
            </LinkButton>
            <OutputMenu
              disabled={!isActionEnabled(row.status)}
              requestId={id.valueOf() as number}
            />
          </Stack>
        );
      },
    },
  ];
};

/**
 * _FeedGrid
 * reload関数を親コンポーネントに外部公開するために、関数名にはアンダースコアをつけている
 * @param _props
 * @param ref
 */
const _FeedGrid: ForwardRefRenderFunction<{ reload: () => void }> = (
  _props,
  ref
) => {
  let rows: OwnerInfoListGridRow[] = [];
  const { apiClient } = useApiClient();
  const colDef = makeGridColDef();

  const fetcher = async (url: string): Promise<OwnerInfoListAPIResponse> => {
    const { data } = await apiClient.get<OwnerInfoListAPIResponse>(url);
    return data;
  };

  const { data, isLoading, mutate } = useSWR<OwnerInfoListAPIResponse>(
    "/owner-analyze/",
    fetcher,
    { revalidateOnFocus: true, errorRetryInterval: 500 }
  );

  // refを使って外部公開する関数を定義する
  useImperativeHandle(
    ref,
    () => {
      return {
        // mutate()を使って再読み込みを行う
        async reload() {
          await mutate().then((r) => {
            if (r) rows = r.list;
          });
        },
      };
    },
    []
  );

  if (isLoading || data === undefined) {
    return (
      <Stack alignItems="center">
        <CircularProgress sx={{ my: "20px" }} />
      </Stack>
    );
  }

  rows = data.list;

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "id",
          sort: "asc",
        },
      ]}
    />
  );
};

/**
 * FeedGrid
 * forwardRefを使ってreload()を外部から呼び出せるようにしている
 */
export const FeedGrid = forwardRef<{ reload: () => void }>(_FeedGrid);
