import { useApiClient } from "@/hooks/useApiClient";
import {
  Alert,
  type AlertColor,
  Button as MuiButton,
  Stack,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import type { UpdateEmailAPIResponse, ButtonSectionProps } from "../types";
import { GetMailListAPI } from "../api";

// 削除ボタンセクション
export const DeleteButtonSection: React.FC<ButtonSectionProps> = ({
  status,
  keyword,
  sendDate,
  mailSendIds,
  setRows,
}) => {
  const { apiClient } = useApiClient();
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (): void => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDelete = async (): Promise<void> => {
    try {
      const { data } = await apiClient.delete<UpdateEmailAPIResponse>(
        "/mail/",
        {
          data: {
            mail_send_ids: mailSendIds,
          },
        }
      );

      const response = await GetMailListAPI(
        apiClient,
        status,
        keyword,
        sendDate
      );
      setRows(response);

      setSnackbar({
        open: true,
        message: `${data.updatedCount}件のメールを削除しました。`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "メールの削除に失敗しました。",
        severity: "error",
      });
    }
  };

  return (
    <Stack>
      <Stack direction={"row"} justifyContent={"end"} sx={{ my: 2 }}>
        <MuiButton
          variant={"contained"}
          disabled={mailSendIds.length === 0}
          onClick={() => {
            (async () => {
              await handleDelete();
            })();
          }}
        >
          削除
        </MuiButton>
      </Stack>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ top: { sm: 80, xs: 65 } }} // ヘッダーの高さを考慮し調整
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

// 送信取消ボタンセクション
export const ScheduleCancelledButtonSection: React.FC<ButtonSectionProps> = ({
  status,
  keyword,
  sendDate,
  mailSendIds,
  setRows,
}) => {
  const { apiClient } = useApiClient();
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (): void => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCancel = async (): Promise<void> => {
    try {
      const { data } = await apiClient.put<UpdateEmailAPIResponse>("/mail/", {
        mail_send_ids: mailSendIds,
      });

      const response = await GetMailListAPI(
        apiClient,
        status,
        keyword,
        sendDate
      );
      setRows(response);

      setSnackbar({
        open: true,
        message: `${data.updatedCount}件のメールを送信取消ししました。`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "メールの送信取消しに失敗しました。",
        severity: "error",
      });
    }
  };

  return (
    <Stack>
      <Stack direction={"row"} justifyContent={"end"} sx={{ my: 2 }}>
        <MuiButton
          variant={"contained"}
          disabled={mailSendIds.length === 0}
          onClick={() => {
            (async () => {
              await handleCancel();
            })();
          }}
        >
          送信取消
        </MuiButton>
      </Stack>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ top: { sm: 80, xs: 65 } }} // ヘッダーの高さを考慮し調整
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
