import { useApiClient } from "@/hooks/useApiClient";
import { type AxiosError } from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";

interface MailTemplateDetail {
  id: number; // ID
  name: string; // 名前
  body: string; // 本文
  updatedAt: string; // 更新日
}

export type MailTemplateDetailAPIResponse = MailTemplateDetail;

export const useMailTemplateDetailRequest = (
  templateId: number | null
): { data: MailTemplateDetailAPIResponse | undefined } => {
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  const fetcher = async (
    url: string
  ): Promise<MailTemplateDetailAPIResponse> => {
    return await apiClient
      .get<MailTemplateDetailAPIResponse>(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };

  const { data, error } = useSWR<MailTemplateDetailAPIResponse, AxiosError>(
    templateId ? `/mail/template/detail/${templateId}` : null, // templateIdがnullの場合はリクエストを行わない
    fetcher,
    {
      revalidateOnFocus: true,
    }
  );

  React.useEffect(() => {
    if (error && error.response?.status === 500) {
      toast.error("メールテンプレートの取得に失敗しました。");
      navigate(`/emailManagement/mailTemplate/list`);
    }
  }, [error, navigate]);

  return { data };
};
