import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";

enum VariantOption {
  Text = "text",
  Outlined = "outlined",
  Contained = "contained",
}

enum ColorOption {
  Inherit = "inherit",
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}

interface IProps {
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  variant: VariantOption;
  accept?: string;
  color?: ColorOption;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUploadButton: React.FC<IProps> = (props) => {
  const { label, onChange, variant, accept = "", color } = props;

  return (
    <MuiButton component="label" variant={variant} color={color}>
      {label}
      <VisuallyHiddenInput type="file" accept={accept} onChange={onChange} />
    </MuiButton>
  );
};

FileUploadButton.defaultProps = {
  color: ColorOption.Primary,
};

export {
  FileUploadButton,
  VariantOption as ButtonVariantOption,
  ColorOption as ButtonColorOption,
};
