import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "@/components/DataGrid";
import {
  type GridColDef,
  GridToolbarContainer,
  type GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Button as MuiButton, Stack } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { format } from "date-fns";
import { type AnalyzedRealEstate } from "@/features/ownerInfo/types";
import { useApiClient } from "@/hooks/useApiClient";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { getAttachedFileNameFromHeader } from "@/utils/utils";

interface IProps {
  row: AnalyzedRealEstate[] | undefined;
  requestId: number | undefined;
  disabledAction: boolean;
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "No",
      width: 90,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "realEstateType",
      headerName: "不動産種別",
      width: 220,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "location",
      headerName: "所在",
      width: 150,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "chibanKaokuNumber",
      headerName: "地番または家屋番号",
      width: 220,
      sortable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "ownerAnalyzedAt",
      type: "dateTime",
      headerName: "登記取得日",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const value = params.value;
        if (value == null) return "";
        return format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      field: "previousOwnerAnalyzedAt",
      type: "dateTime",
      headerName: "前回取得日",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const value = params.value;
        if (value == null) return "";
        return format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      field: "uploadedAt",
      type: "dateTime",
      headerName: "アップロード日時",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const value = params.value;
        if (value == null) return "";
        return format(new Date(value), "yyyy-MM-dd HH:mm");
      },
    },
  ];
};

const CustomToolbar: React.FC<IProps> = (props) => {
  const { apiClient } = useApiClient();
  const onDownloadFile = (
    requestId: number,
    resType: string,
    fallBackName: string
  ): void => {
    apiClient
      .get(`/owner-analyze/download-file/${requestId}/${resType}`, {
        responseType: "blob",
      })
      .then((res) => {
        const filename = getAttachedFileNameFromHeader(res, fallBackName);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        fileDownload(res.data, filename);
      })
      .catch((e) => {
        console.error(e);
        toast.error("ファイルのダウンロードに失敗しました");
      });
  };

  return (
    <>
      <GridToolbarContainer
        sx={{
          // https://mui.com/material-ui/react-button/#sizes と同様のpaddingを設定
          padding: "6px 16px",
        }}
      >
        <Stack direction={"row"} spacing={1}>
          <MuiButton
            variant={"outlined"}
            disabled={props.disabledAction}
            onClick={() => {
              const requestId = props.requestId;
              if (requestId != null) {
                onDownloadFile(
                  requestId,
                  "excel",
                  `所有者事項解析結果_${requestId}.xlsx`
                );
              }
            }}
          >
            エクセル出力
            <DownloadIcon />
          </MuiButton>
          <MuiButton
            variant={"outlined"}
            disabled={props.disabledAction}
            onClick={() => {
              const requestId = props.requestId;
              if (requestId != null) {
                onDownloadFile(
                  requestId,
                  "csv_owners",
                  `所有者事項解析結果_所有者ベース_${requestId}.csv`
                );
              }
            }}
          >
            所有者ベースCSV出力
            <DownloadIcon />
          </MuiButton>
          <MuiButton
            variant={"outlined"}
            disabled={props.disabledAction}
            onClick={() => {
              const requestId = props.requestId;
              if (requestId != null) {
                onDownloadFile(
                  requestId,
                  "csv_estates",
                  `所有者事項解析結果_登記件数ベース_${requestId}.csv`
                );
              }
            }}
          >
            登記件数ベースCSV出力
            <DownloadIcon />
          </MuiButton>
        </Stack>
      </GridToolbarContainer>
    </>
  );
};

export const FeedGrid: React.FC<IProps> = (props) => {
  const colDef = makeGridColDef();
  const rows = props.row ?? [];

  return (
    <StyledDataGrid
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: {
          requestId: props.requestId,
          disabledAction: props.disabledAction,
        },
      }}
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
