import { useApiClient } from "../../../hooks/useApiClient";
import useSWR from "swr";
import { type CommercialBookStatusResponse } from "../types";
import { getStatusString } from "../../../utils/utilsBookStatus";

interface UseCommercialBookStatus {
  data: CommercialBookStatusResponse | undefined;
  getStatusString: (data: CommercialBookStatusResponse | undefined) => string;
}

export const useCommercialBookStatus = (
  id: string | undefined
): UseCommercialBookStatus => {
  const { apiClient } = useApiClient();

  const fetcher = async (
    url: string
  ): Promise<CommercialBookStatusResponse> => {
    const { data } = await apiClient.get<CommercialBookStatusResponse>(url);
    return data;
  };
  const { data } = useSWR<CommercialBookStatusResponse>(
    id === undefined ? null : `/commercial-picture-request/${id}`,
    fetcher,
    {
      revalidateOnFocus: true,
      refreshInterval: 1000 * 30,
    }
  );

  return {
    data,
    getStatusString,
  };
};
