import { SectionPaper } from "@/components/Paper";
import { SectionTitle } from "@/components/Title";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect } from "react";
import { Label } from "@/components/Label";
import {
  UserConfigGetAPI,
  UserConfigUpdateAPI,
} from "@/features/accountSettings/api/UserConfigAPI";
import { ButtonVariantOption, SubmitButton } from "@/components/Button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { SizingWrapperStyle } from "@/components/Wrapper";
import {
  type UserConfigFormData,
  UserConfigFormDataSchema,
} from "@/features/accountSettings/types/schemas";

export const UserConfig: React.FC = (_props) => {
  const userConfig = UserConfigGetAPI();
  const { trigger } = UserConfigUpdateAPI();

  useEffect(() => {
    // テキストボックスに何か入力してしばらく放置すると、再びサーバーからデータを取りに行った時に入力中の値が上書きされてしまう
    // そこで、一度でも入力があったら更新しないようにする
    if (reactHookForm.formState.isDirty) {
      return;
    }
    reactHookForm.setValue(
      "pictureRequestPastDays",
      userConfig?.pictureRequestPastDays ?? 0
    );
  }, [userConfig]);

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const reactHookForm = useForm<UserConfigFormData>({
    mode: "all",
    defaultValues: {
      pictureRequestPastDays: userConfig?.pictureRequestPastDays ?? 0,
    },
    resolver: zodResolver(UserConfigFormDataSchema),
  });

  const onSubmit = reactHookForm.handleSubmit(
    async (data: UserConfigFormData) => {
      // バリデーションに成功したらサーバーへ送信する
      const result = await trigger(data);
      if (result) {
        toast.success("設定を変更しました");
        reactHookForm.setValue(
          "pictureRequestPastDays",
          result.pictureRequestPastDays
        );
      }
    }
  );

  return (
    <SectionPaper>
      <Box sx={{ display: "flex" }}>
        <SectionTitle>各種設定</SectionTitle>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{ m: 2 }}>
          <Label>不動産登記：経過日数</Label>
          <Box sx={{ mb: 2 }}>
            <FormControl
              variant="outlined"
              error={Boolean(
                reactHookForm.formState.errors.pictureRequestPastDays
              )}
            >
              <InputLabel htmlFor="outlined-adornment-pastdays">
                経過日数
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-pastdays"
                type={"text"}
                {...reactHookForm.register("pictureRequestPastDays", {
                  valueAsNumber: true,
                })}
                error={Boolean(
                  reactHookForm.formState.errors.pictureRequestPastDays
                )}
                fullWidth={true}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                endAdornment={
                  <InputAdornment position="end">日</InputAdornment>
                }
                label="経過日数"
              />
              <FormHelperText error>
                {reactHookForm.formState.errors.pictureRequestPastDays?.message}
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          mr: 2,
          my: 3,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SubmitButton
          onClick={(_event, cancelLoading) => {
            onSubmit()
              .then(() => {
                cancelLoading();
              })
              .catch((reason) => {
                cancelLoading();
                console.error(reason);
              });
          }}
          timeout={30 * 1000}
          label={"保存"}
          variant={ButtonVariantOption.Contained}
          size={SizingWrapperStyle.SMALL}
          loadingIndicator={"設定中..."}
        />
      </Box>
    </SectionPaper>
  );
};
