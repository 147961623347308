import React, { useEffect, useRef } from "react";
import { FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SizingWrapper } from "../Wrapper";
import { ButtonColorOption, type IProps as BaseButtonIProps } from "./Button";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/react";

type WaitMode = "infinite";

interface IProps extends Omit<BaseButtonIProps, "onClick"> {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ) => void;
  onTimeout?: () => void;
  timeout: WaitMode | number;
  loadingIndicator?: React.ReactNode;
  refToClickButton?: React.RefObject<HTMLButtonElement>;
}

// デフォルトの「読み込み中」表示
const DefaultIndicator: React.FC = () => {
  return <Typography>読み込み中...</Typography>;
};

/**
 * 汎用の送信ボタン
 *
 * @description 送信ボタンとして使えるコンポーネント
 * このボタンは一度クリックすると「読み込み中」と表示され、その後のクリックは無効になります。
 * これによって連続クリックすることができなくなります。
 *
 * timeoutで指定した時間が経過するとボタンが元の状態に復旧するようになっています。
 * timeoutを指定することで、サーバーが予期せぬ応答をした時に一生押せなくなってしまわないようにしている。
 *
 * ですがタイムアウトが発生するということは異常状態であると考えてください。
 * そのためタイムアウトとなった場合はsentryに通知されるようになっています。
 * タイムアウト値は十分に長くとってください。
 *
 * また、timeoutが'infinite'の場合はタイムアウト処理は実行せず、cancelLoading()が実行されるまで無限に「読み込み中」と表示し続けます。
 * サーバーが正しく応答することが保証されているならこれでもOKです。
 *
 * loadingIndicatorを指定すると「読み込み中」文字を任意のReactコンポーネントに変更することができます。
 *
 * onClickは通常のButtonと同様でクリック時に実行される関数を指定しますが、「読み込み中」状態を元に戻すためのcancelLoading()を
 * 受け取ることができる関数である必要があります。
 * 例）
 * onClick={(_event, cancelLoading) => {
 *   const result = バリデーション();
 *   if(result === 失敗) {
 *     cancelLoading(); // これで「読み込み中」を元に戻すことができる
 *   }
 *   const result2 = 送信処理();
 *   if(result2 === 成功or失敗) {
 *     cancelLoading();
 *   }
 * }
 *
 * onTimeoutはタイムアウトが起こった時に実行される関数を指定する
 *
 * そのほかのpropsはsrc/components/Button/Button.tsxの内容と同一です。
 *
 */
const SubmitButton: React.FC<IProps> = (props) => {
  const {
    label,
    onClick,
    onTimeout,
    variant,
    color,
    fullWidth,
    size,
    disabled,
    startIcon,
    type = "button",
    timeout,
    loadingIndicator,
    refToClickButton,
  } = props;

  // 読み込み中状態を管理するState
  const [loading, setLoading] = React.useState(false);

  // タイムアウトタイマーを保存するref
  const timer = useRef<NodeJS.Timeout | null>(null);

  // 読み込み中状態を元に戻す関数
  const cancelLoading = (): void => {
    setLoading(false);
    // タイマーを停止
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  // ユーザーが指定したonClick()を実行する前に挟み込む処理
  const interceptedClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setLoading(true);

    if (typeof timeout === "string") {
      if (timeout === "infinite") {
        onClick(event, cancelLoading);
      } else {
        // timeoutの値は"infinite"と数値意外は指定できないので、ここに来ることはほぼ無いはず
        console.error("SubmitButtonのtimeoutが正しく指定されていません");
      }
    } else {
      timer.current = setTimeout(() => {
        // タイムアウトするということは異常状態である
        setLoading(false);
        console.error("送信ボタンタイムアウト");
        Sentry.captureMessage("送信ボタンタイムアウト");
        if (onTimeout) {
          onTimeout();
        }
      }, timeout);

      onClick(event, cancelLoading);
    }
  };

  // ボタンが画面から消えたらタイムアウトタイマーを停止させる
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, []);

  return (
    <SizingWrapper size={size}>
      <FormControl fullWidth>
        <LoadingButton
          ref={refToClickButton}
          loading={loading}
          loadingIndicator={loadingIndicator ?? <DefaultIndicator />}
          variant={variant}
          onClick={interceptedClick}
          color={color}
          fullWidth={fullWidth}
          disabled={disabled}
          startIcon={startIcon}
          type={type}
        >
          {label}
        </LoadingButton>
      </FormControl>
    </SizingWrapper>
  );
};

SubmitButton.defaultProps = {
  color: ButtonColorOption.Primary,
  fullWidth: false,
  disabled: false,
};

export { SubmitButton, type WaitMode };
