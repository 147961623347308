import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";

import { useApiClient } from "@/hooks/useApiClient";
import { getAttachedFileNameFromHeader } from "@/utils/utils";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

interface OutputMenuProps {
  disabled: boolean;
  requestId: number;
}

export default function OutputMenu({
  disabled,
  requestId,
}: OutputMenuProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const { apiClient } = useApiClient();
  const onDownloadFile = (
    requestId: number,
    resType: string,
    fallBackName: string
  ): void => {
    apiClient
      .get(`/owner-analyze/download-file/${requestId}/${resType}`, {
        responseType: "blob",
      })
      .then((res) => {
        const filename = getAttachedFileNameFromHeader(res, fallBackName);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        fileDownload(res.data, filename);
      })
      .catch((e) => {
        console.error(e);
        toast.error("ファイルのダウンロードに失敗しました");
      });
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        variant="outlined"
        color="primary"
        id="output-button"
        aria-controls={open ? "output-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        出力
        <DownloadIcon />
      </Button>
      <Menu
        id="output-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "output-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onDownloadFile(
              requestId,
              "excel",
              `所有者事項解析結果_${requestId}.xlsx`
            );
            handleClose();
          }}
        >
          エクセルダウンロード
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDownloadFile(
              requestId,
              "csv_owners",
              `所有者事項解析結果_所有者ベース_${requestId}.csv`
            );
            handleClose();
          }}
        >
          所有者ベースCSVダウンロード
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDownloadFile(
              requestId,
              "csv_estates",
              `所有者事項解析結果_登記件数ベース_${requestId}.csv`
            );
            handleClose();
          }}
        >
          登記件数ベースCSVダウンロード
        </MenuItem>
      </Menu>
    </div>
  );
}
