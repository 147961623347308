import { useEffect, useState } from "react";
import { useMap, FeatureGroup } from "react-leaflet";
import { AreaUsePurposeTileLayer } from "./AreaUsePurposeTileLayer";

interface Props {
  defaultVisible: boolean;
  initialZoomLevel: number;
}

export const AREA_USE_PURPOSE_LAYER_NAME = "用途地域を表示する";

/*
AreaUsePurposeTileLayerをLeafletのLayersControlで制御するためのWrapperコンポーネント
*/
export const AreaUsePurposeLayerControlWrapper: React.FC<Props> = ({
  defaultVisible,
  initialZoomLevel,
}) => {
  const [visible, setVisible] = useState(defaultVisible);
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    map.on("overlayadd", (e) => {
      if (e.name === AREA_USE_PURPOSE_LAYER_NAME) {
        setVisible(true);
      }
    });

    map.on("overlayremove", (e) => {
      if (e.name === AREA_USE_PURPOSE_LAYER_NAME) {
        setVisible(false);
      }
    });
  }, []);

  return (
    <>
      {/* 空のFeatureGroupは、LayersControlに認識させるために必要 */}
      <FeatureGroup />
      <AreaUsePurposeTileLayer
        visible={visible}
        initialZoomLevel={initialZoomLevel}
      />
    </>
  );
};
