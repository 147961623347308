// 顧客管理のステータスのlabel
export enum RealEstateOwnerStatusLabels {
  NO_APPROACH = "未営業",
  APPROACH = "営業中",
  REJECTED = "お断り",
  NO_RESPONSE = "レスなし",
  COMPLAINT = "クレームありNG",
  SENT = "送付済み",
  REACTION = "反響あり",
}
