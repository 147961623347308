import { useMemo } from "react";

/**
 *
 * 現在の分が00の場合は1時間後、それ以外の場合は2時間後の整時を計算して返すhooks
 */
const useScheduledSendAt = (): Date => {
  return useMemo(() => {
    const now: Date = new Date();
    const currentMinutes = now.getMinutes();
    const additionalHours = currentMinutes === 0 ? 1 : 2; // 分が00の場合は1時間加算、それ以外は2時間加算

    // 現在の分が00の場合は1時間後、それ以外の場合は2時間後の整時を設定
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours() + additionalHours
    );
  }, []);
};

export default useScheduledSendAt;
