import { type AxiosInstance } from "axios";
import { type AcquireBookForm } from "../types/schemas";
import { type MonitoringDataGridRow } from "../types";

interface IRequests {
  params: AcquireBookForm;
  rows: MonitoringDataGridRow[];
}

/**
 * 個別で謄本取得するAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {IRequests} requests
 */
export const AcquireBookAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<void> => {
  // TODO: 謄本取得APIを実行する。

  console.log("個別謄本取得APIが実行されました。");
  console.log("params =", requests.params);
  console.log("rows =", requests.rows);
};
