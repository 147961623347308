import { styled } from "@mui/material/styles";
import { CustomerManagementListDataGrid } from "../../../../components/DataGrid";
import {
  type GridColumnHeaderParams,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { Link, Checkbox } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import { MenuItem, IconButtonMenu } from "../../../../components/Menu";
import {
  type MailTemplateListAPIResponse,
  useMailTemplateListRequest,
} from "../../hooks/useMailTemplateListRequest";
import { useDeleteMailTemplateRequest } from "../../hooks/useDeleteMailTemplateRequest";

const StyledDataGrid = styled(CustomerManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (
  data: MailTemplateListAPIResponse,
  selectedIds: number[],
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>,
  deleteMailTemplate: (ids: number[]) => void
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Checkbox
          checked={
            data.length > 0 && data.every((d) => selectedIds.includes(d.id))
          }
          onChange={() => {
            if (data.every((d) => selectedIds.includes(d.id))) {
              setSelectedIds([]);
            } else {
              setSelectedIds(data.map((d) => d.id));
            }
          }}
        />
      ),
      renderCell: ({ id }: GridRenderCellParams<number>) => {
        return (
          <Checkbox
            checked={selectedIds.some((selectedId) => id === selectedId)}
            onChange={() => {
              if (selectedIds.some((selectedId) => id === selectedId)) {
                setSelectedIds(
                  selectedIds.filter((selectedId) => id !== selectedId)
                );
              } else {
                setSelectedIds([...selectedIds, Number(id)]);
              }
            }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "テンプレート名",
      sortable: false,
      align: "left",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "更新日",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "",
      minWidth: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ id }: GridRenderCellParams<number>) => {
        return (
          <IconButtonMenu icon={<MoreVertIcon />}>
            <MenuItem>
              <Link
                href={`/emailManagement/mailTemplate/edit/${id}/`}
                color="inherit"
                underline="none"
              >
                編集
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                deleteMailTemplate([Number(id)]);
              }}
            >
              削除
            </MenuItem>
          </IconButtonMenu>
        );
      },
    },
  ];
};

interface Props {
  selectedIds: number[];
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const MailTemplateListTable: React.FC<Props> = ({
  selectedIds,
  setSelectedIds,
}) => {
  const deleteMailTemplate = useDeleteMailTemplateRequest();
  const { data } = useMailTemplateListRequest();
  const colDef = makeGridColDef(
    data,
    selectedIds,
    setSelectedIds,
    deleteMailTemplate
  );

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={data}
      columns={colDef}
      sortModel={[
        {
          field: "updatedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
