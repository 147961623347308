import { Button, Menu, MenuItem } from "@mui/material";
import { type GridSelectionModel } from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface IProps {
  anchorEl: HTMLElement | null;
  handleCancelMonitoringAPI: () => Promise<void>;
  openBulk: boolean;
  selectedIds: GridSelectionModel;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setOpenAcquireBooks: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenBulk: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BulkOperationButton: React.FC<IProps> = (props: IProps) => {
  const {
    anchorEl,
    handleCancelMonitoringAPI,
    openBulk,
    selectedIds,
    setAnchorEl,
    setOpenBulk,
    setOpenAcquireBooks,
  } = props;

  return (
    <>
      <Button
        endIcon={<ArrowDropDownIcon />}
        disabled={selectedIds.length === 0}
        id="test-button"
        onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
          setAnchorEl(event.currentTarget);
          setOpenAcquireBooks(false);
          setOpenBulk(true);
        }}
        variant="outlined"
      >
        一括操作
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="test-menu"
        onClose={() => {
          setAnchorEl(null);
          setOpenAcquireBooks(false);
          setOpenBulk(false);
        }}
        open={openBulk}
        MenuListProps={{ "aria-labelledby": "test-button" }}
      >
        <MenuItem
          onClick={() => {
            (async () => {
              await handleCancelMonitoringAPI();
            })();

            setAnchorEl(null);
            setOpenAcquireBooks(false);
            setOpenBulk(false);
          }}
        >
          モニタリング登録を解除
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenAcquireBooks(true);
          }}
        >
          謄本取得する
        </MenuItem>
      </Menu>
    </>
  );
};
