import {
  type GridColDef,
  type GridColumnHeaderParams,
  type GridRenderCellParams,
  type GridValueGetterParams,
  type GridValueFormatterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  type IRealEstateReceptionBookDataGridRow,
  type ISingleBookAcquireDialogData,
} from "../types";
import { numberFormat } from "../../../utils/utils";
import React from "react";
import { convertBookTypeToEng } from "../../../utils/utilsAcquireBook";
import { type BookTypeJpn } from "../../../types/acquirebook";
import { GridHeaderCheckbox } from "@mui/x-data-grid/components/columnSelection/GridHeaderCheckbox";

export const RealEstateReceptionBookDataGridColumns = {
  GridCheckBoxSelectionColDef: {
    // 登記を複数同時に取得するために使うチェックボックス
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    type: "string",
    width: 100,
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <Tooltip title="チェックを入れると下表の行をすべて選択します">
          <GridHeaderCheckbox field={params.field} colDef={params.colDef} />
        </Tooltip>
      );
    },
  },
  Id: {
    field: "id",
    headerName: "id",
    width: 200,
  },
  LegalAffairsBureauRequestDate: {
    field: "legalAffairsBureauRequestDate",
    headerName: "法務局受付日",
    width: 180,
  },
  HasTrade: (disableExport: boolean) => {
    return {
      field: "hasTrade",
      headerName: "売買登記あり",
      width: 120,
      disableExport,
    };
  },
  ReceptionReason: {
    field: "receptionReason",
    headerName: "登記原因",
    width: 200,
  },
  ReceptionReasonType: {
    field: "receptionReasonType",
    headerName: "登記原因種別",
    width: 200,
  },
  RealEstateType: {
    field: "realEstateType",
    headerName: "不動産種別",
    width: 150,
  },
  Prefectures: {
    field: "prefectures",
    headerName: "都道府県",
    width: 140,
  },
  Address: {
    field: "address",
    headerName: "所在",
    width: 200,
  },
  Chiban: {
    field: "chiban",
    headerName: "地番または家屋番号",
    valueGetter: ({
      row,
    }: GridValueGetterParams<string, IRealEstateReceptionBookDataGridRow>) => {
      return `${row.chiban || ""}${row.kaokuNumber || ""}`;
    },
    width: 160,
  },
  EstimatedChiseki: {
    field: "estimatedChiseki",
    headerName: "推計地積",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong>
        推計地積
        <br />
        (㎡)
      </strong>
    ),
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return params.value
        ? numberFormat(params.value, { maximumFractionDigits: 2 }, "floor")
        : "—";
    },
    width: 120,
  },
  PublishedPrice: {
    field: "publishedPrice",
    headerName: "公示価格",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong>
        公示価格
        <br />
        (円/㎡)
      </strong>
    ),
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return params.value
        ? numberFormat(params.value, { maximumFractionDigits: 0 }, "floor")
        : "—";
    },
    width: 120,
  },
  EstimatedPrice: {
    field: "estimatedPrice",
    headerName: "概算価格",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong>
        概算価格
        <br />
        (百万円)
      </strong>
    ),
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (!params.value) {
        return "—";
      }
      return numberFormat(
        params.value / 1_000_000,
        { maximumFractionDigits: 1 },
        "floor"
      );
    },
    width: 120,
  },
  AreaUsePurpose: {
    field: "areaUsePurpose",
    headerName: "用途地域",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong>
        用途
        <br />
        地域
      </strong>
    ),
    width: 60,
    renderCell: (param: GridRenderCellParams) => {
      if (!param.value) {
        return (
          <Tooltip title="自治体へ照会お願いします">
            <IconButton>
              <HorizontalRuleIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return <span>{param.value}</span>;
      }
    },
  },
  BuildingRate: {
    field: "buildingRate",
    headerName: "建ぺい率",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong>
        建ぺい率(%)
        <br />
        /容積率(%)
      </strong>
    ),
    renderCell: ({
      row,
    }: GridRenderCellParams<number, IRealEstateReceptionBookDataGridRow>) => {
      const buildingRate = row.buildingRate;
      if (!buildingRate) {
        return (
          <Tooltip title="自治体へ照会お願いします">
            <IconButton>
              <HorizontalRuleIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <span>
            {buildingRate}
            <br />
            {row.volumeRate}
          </span>
        );
      }
    },
    width: 120,
  },
  VolumeRate: {
    field: "volumeRate", // DataGrid initialState columnVisibilityModelで非表示にしている
    headerName: "容積率",
    width: 120,
  },
  IsTowerApartment: {
    field: "isTowerApartment",
    headerName: "タワマン",
    width: 100,
  },
  IsOneRoom: {
    field: "isOneRoom",
    headerName: "ワンルーム",
    width: 100,
  },
  IsPlant: {
    field: "isPlant",
    headerName: "工場",
    sortable: false,
    width: 100,
  },
  IsRenewableEnergy: {
    field: "isRenewableEnergy",
    headerName: "再エネ",
    width: 100,
  },
  Outside: {
    field: "outside",
    headerName: "外",
    width: 90,
  },
  RealEstateBookType: {
    field: "realEstateBookType",
    headerName: "申請種別",
    width: 140,
  },
  PropertyName: {
    field: "propertyName",
    headerName: "マンション名",
    width: 200,
  },
  BuildDate: {
    field: "buildDate",
    headerName: "築年月",
    width: 140,
  },
  Station: {
    field: "station",
    headerName: "最寄駅",
    width: 180,
  },

  Walk: {
    field: "walk",
    headerName: "最寄駅からの徒歩時間",
    width: 180,
    renderCell: (param: GridRenderCellParams) => {
      if (param.value === null) return "";
      return String(param.value) + "分";
    },
  },
  FarmlandArea: {
    field: "farmlandArea",
    headerName: "農地面積",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong>
        農地面積
        <br />
        (㎡)
      </strong>
    ),
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return params.value
        ? numberFormat(params.value, { maximumFractionDigits: 2 }, "floor")
        : "—";
    },
    width: 120,
  },
  FarmlandCategory: {
    field: "farmlandCategory",
    headerName: "農地地目",
    width: 120,
  },
  SingleBookAcquire: (
    setSingleBookAcquireDialogStatus: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setSingleBookAcquireDialogData: React.Dispatch<
      React.SetStateAction<ISingleBookAcquireDialogData>
    >,
    isFreeUser: boolean
  ) => {
    return {
      field: "singleBookAcquire",
      headerName: "個別取得",
      width: 90,
      disableExport: true,
      renderCell: ({
        row,
      }: GridRenderCellParams<string, IRealEstateReceptionBookDataGridRow>) => {
        return (
          <Tooltip
            title={isFreeUser ? "有料プランでご利用いただける機能です" : ""}
            placement={"left"}
            arrow
          >
            <div style={{ display: "inherit" }}>
              <IconButton
                onClick={() => {
                  // 登記取得ダイアログの動作に必要な情報をStateに格納する
                  const data = {
                    prefecturesId: row.prefecturesId,
                    prefectures: row.prefectures,
                    address: row.address,
                    chiban: row.chiban,
                    kaokuNumber: row.kaokuNumber,
                    realEstateTypeEng: convertBookTypeToEng(
                      row.realEstateType as BookTypeJpn
                    ),
                  };
                  setSingleBookAcquireDialogData(data);

                  // 登記取得ダイアログを表示
                  setSingleBookAcquireDialogStatus(true);
                }}
                disabled={isFreeUser}
              >
                <DownloadIcon />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
    };
  },
} as const;

export const makeRealEstateReceptionBookDataGridColumns = (
  setSingleBookAcquireDialogStatus: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setSingleBookAcquireDialogData: React.Dispatch<
    React.SetStateAction<ISingleBookAcquireDialogData>
  >,
  hasTradeDisableExport: boolean
): GridColDef[] => {
  return [
    RealEstateReceptionBookDataGridColumns.GridCheckBoxSelectionColDef,
    RealEstateReceptionBookDataGridColumns.LegalAffairsBureauRequestDate,
    RealEstateReceptionBookDataGridColumns.HasTrade(hasTradeDisableExport),
    RealEstateReceptionBookDataGridColumns.ReceptionReason,
    RealEstateReceptionBookDataGridColumns.ReceptionReasonType,
    RealEstateReceptionBookDataGridColumns.RealEstateType,
    RealEstateReceptionBookDataGridColumns.Prefectures,
    RealEstateReceptionBookDataGridColumns.Address,
    RealEstateReceptionBookDataGridColumns.Chiban,
    RealEstateReceptionBookDataGridColumns.EstimatedChiseki,
    RealEstateReceptionBookDataGridColumns.PublishedPrice,
    RealEstateReceptionBookDataGridColumns.EstimatedPrice,
    RealEstateReceptionBookDataGridColumns.AreaUsePurpose,
    RealEstateReceptionBookDataGridColumns.BuildingRate,
    RealEstateReceptionBookDataGridColumns.VolumeRate,
    RealEstateReceptionBookDataGridColumns.IsTowerApartment,
    RealEstateReceptionBookDataGridColumns.IsOneRoom,
    RealEstateReceptionBookDataGridColumns.IsPlant,
    RealEstateReceptionBookDataGridColumns.IsRenewableEnergy,
    RealEstateReceptionBookDataGridColumns.Outside,
    RealEstateReceptionBookDataGridColumns.RealEstateBookType,
    RealEstateReceptionBookDataGridColumns.PropertyName,
    RealEstateReceptionBookDataGridColumns.BuildDate,
    RealEstateReceptionBookDataGridColumns.Station,
    RealEstateReceptionBookDataGridColumns.Walk,
    RealEstateReceptionBookDataGridColumns.FarmlandArea,
    RealEstateReceptionBookDataGridColumns.FarmlandCategory,
    RealEstateReceptionBookDataGridColumns.SingleBookAcquire(
      setSingleBookAcquireDialogStatus,
      setSingleBookAcquireDialogData,
      false
    ),
  ];
};

export const freeUserRealEstateReceptionBookDataGridColumns = (
  setSingleBookAcquireDialogStatus: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setSingleBookAcquireDialogData: React.Dispatch<
    React.SetStateAction<ISingleBookAcquireDialogData>
  >,
  hasTradeDisableExport: boolean
): GridColDef[] => {
  return [
    RealEstateReceptionBookDataGridColumns.GridCheckBoxSelectionColDef,
    RealEstateReceptionBookDataGridColumns.Id,
    RealEstateReceptionBookDataGridColumns.LegalAffairsBureauRequestDate,
    RealEstateReceptionBookDataGridColumns.HasTrade(hasTradeDisableExport),
    RealEstateReceptionBookDataGridColumns.ReceptionReason,
    RealEstateReceptionBookDataGridColumns.RealEstateType,
    RealEstateReceptionBookDataGridColumns.Prefectures,
    RealEstateReceptionBookDataGridColumns.Outside,
    RealEstateReceptionBookDataGridColumns.RealEstateBookType,
    RealEstateReceptionBookDataGridColumns.SingleBookAcquire(
      setSingleBookAcquireDialogStatus,
      setSingleBookAcquireDialogData,
      true
    ),
  ];
};
