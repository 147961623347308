import React from "react";
import { TextField, Stack, FormLabel, Box } from "@mui/material";
import {
  ButtonVariantOption,
  Button,
  ButtonColorOption,
} from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { useMailTemplateValidator } from "../../hooks/useMailTemplateValidator";
import { useUpdateMailTemplateRequest } from "../../hooks/useUpdateMailTemplateRequest";
import { useMailTemplateDetailRequest } from "../../hooks/useMailTemplateDetailRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteMailTemplateRequest } from "../../hooks/useDeleteMailTemplateRequest";

export const MailTemplateEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const templateId = parseInt(id as string);
  const { data } = useMailTemplateDetailRequest(templateId);
  const [name, setName] = React.useState<string>(data?.name ?? "");
  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setName(event.target.value);
  };
  const [body, setBody] = React.useState<string>("");
  const handleBodyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setBody(event.target.value);
  };
  React.useEffect(() => {
    if (data) {
      setName(data.name);
      setBody(data.body);
    }
  }, [data]);
  const updateMailTemplate = useUpdateMailTemplateRequest(templateId);
  const deleteMailTemplate = useDeleteMailTemplateRequest();
  const validator = useMailTemplateValidator({
    valueStateName: name,
    valueStateBody: body,
  });
  const handleSubmit = React.useCallback((): void => {
    if (validator.isValid()) {
      updateMailTemplate({ name, body });
    }
  }, [name, body]);

  const handleDelete = (): void => {
    if (confirm("メールテンプレートを削除してもよろしいですか？")) {
      deleteMailTemplate([templateId]);
      navigate(`/emailManagement/mailTemplate/list`);
    }
  };

  return (
    <>
      <Stack my={2} direction="column" spacing={1}>
        <FormLabel component="legend">テンプレート名</FormLabel>
        <TextField
          error={validator.name.state.error}
          helperText={validator.name.state.helperTextWhenError}
          value={name}
          onChange={handleNameChange}
          sx={{ width: "100%" }}
        />
        <FormLabel component="legend">メールテンプレート</FormLabel>
        <TextField
          error={validator.body.state.error}
          helperText={validator.body.state.helperTextWhenError}
          value={body}
          onChange={handleBodyChange}
          sx={{ width: "100%" }}
          multiline
          rows={10}
        />
      </Stack>
      <Box
        sx={{
          mr: 2,
          my: 3,
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Button
          label="削除"
          onClick={handleDelete}
          color={ButtonColorOption.Error}
          variant={ButtonVariantOption.Outlined}
          size={SizingWrapperStyle.SMALL}
        />
        <Button
          label="保存"
          disabled={name === "" || body === ""}
          onClick={handleSubmit}
          variant={ButtonVariantOption.Contained}
          size={SizingWrapperStyle.SMALL}
        />
      </Box>
    </>
  );
};
