import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { useExcelExport } from "@/hooks/useExcelExport";
import { Divider, Stack, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { convertTypeEnglishToJapanese, formatArea } from "../utils";
import { useEffect, useState } from "react";
import type { PropertySelectionRow } from "../types";

export const CustomFooter: React.FC<{ rows: PropertySelectionRow[] }> = ({
  rows,
}) => {
  const [sumArea, setSumArea] = useState<number>(0);

  // エクセルエクスポートで必要な関数を取得。
  const { downloadAsExcelFile, getFeedGridData, makeExcelUint8Array } =
    useExcelExport();

  // feedGridの内部データを読み書きするためのハンドルみたいなものを取得
  const apiRef = useGridApiContext();

  /**
   * ピンのデータをエクセルでエクスポートする。
   */
  const handleExportExcel = (): void => {
    const arrayOfArray = getFeedGridData(apiRef);
    const formatData = arrayOfArray.map((element, index) => {
      // Action列は削除。
      element.pop();

      // ヘッダーの種別は日本語変換しない。
      if (index !== 0) element[0] = convertTypeEnglishToJapanese(element[0]);

      return element;
    });

    const data = makeExcelUint8Array(formatData, "地図検索");

    downloadAsExcelFile(data, "R.E.DATA_地図検索_ピンデータ");
  };

  useEffect(() => {
    const sum = rows.reduce((acc: number, row: PropertySelectionRow) => {
      if (typeof row.area === "number" && row.selected) {
        return acc + row.area;
      }
      return acc;
    }, 0);
    setSumArea(sum);
  }, [rows]);

  return (
    <>
      <Divider />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        m={1}
        alignItems="center"
      >
        <Typography>合計推計地積(㎡): {formatArea(sumArea)}</Typography>
        <Button
          label="エクセルエクスポート"
          onClick={handleExportExcel}
          size={SizingWrapperStyle.INHERIT}
          variant={ButtonVariantOption.Contained}
        />
      </Stack>
    </>
  );
};
